import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setLoginData, isLoginAuth } from "utils/login";
import { axAppSRCM } from "services/base";
import { lStorage } from "../../../utils/storage";

function Launch() {
  const navigate = useNavigate();

  useEffect(() => {
    // navigate("/mobile/events");
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "REQUEST_TOKEN" })
      );
    }

    window.addEventListener("message", async (message) => {
      if (message.data.token) {
        lStorage.set("authInfo", { srcmToken: message.data.token });
        await axAppSRCM.get("/api/v2/me/").then((res) => {
          const user = {};
          if (res?.data?.results.length > 0) {
            const myInfo = res?.data?.results[0];
            user.srcmToken = message.data.token;
            user.name = myInfo.name;
            user.email = myInfo.user_email;
            user.loginUser = myInfo;
            lStorage.set("authInfo", user);
            setLoginData(user);
            setTimeout(() => {
              navigate("/mobile/events");
            }, 2000);
          }
        });
      } else if (isLoginAuth()) {
        navigate("/mobile/events");
      }
    });
  }, [navigate]);

  return (
    <div className="lds-roller-wrapper">
      <div className="lds-roller">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default Launch;
