import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import config from "assets/config";

const DownloadQr = () => {
  const { eventId, sessionId, blockId, source, type } = useParams();
  const qrLink = `${config.qrCodeURI}/qr?qr_type=gen&qr_content=${config.baseUrl}/self-registration/${eventId}/${sessionId}`;
  const navigate = useNavigate();
  const onClickBackBtn = () => {
    let url = "";
    if (source !== "mobile")
      url = `/event-session/${eventId}/${blockId}?type=${type}`;
    else url = `/mobile/sessions/${eventId}/${blockId}`;
    navigate(url);
  };
  window.onload = () => {
    window.print();
  };
  // useEffect(() => {
  //   window.print();
  // }, []);

  return (
    <div style={{ textAlign: "center", marginTop: "100px" }}>
      <input
        type="image"
        alt="qrcode"
        className="qrCode"
        size={100}
        style={{
          height: "auto",
          maxWidth: "300px",
          width: "300px",
        }}
        src={qrLink}
      />
      <div style={{ width: "100%", marginTop: "100px" }}>
        <Button
          className="p-button p-button-primary p-mr-2 action-button"
          type="button"
          label="Back"
          onClick={onClickBackBtn}
        />
      </div>
    </div>
  );
};

export default DownloadQr;
