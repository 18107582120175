import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PropTypes from "prop-types";
import CarouselRightArrow from "./carousel-right-arrow";
import CarouselLeftArrow from "./carousel-left-arrow";
import { responsiveCardForUsers } from "./responsive";
import { dateFormat } from "utils/common";
import { dateFieldsOnCard } from "utils/constants";

const CarouselCardMultiField = ({
  data,
  onRemove,
  mode,
  onEdit,
  isPopupModal,
}) => {
  const [cardData, setCardData] = useState([]);
  useEffect(() => {
    setCardData(data);
  }, [data]);
  const screenWidth = screen.width;
  if (screenWidth > 450 && screenWidth < 1400 && isPopupModal)
    responsiveCardForUsers.desktop.items = 2;

  const processData = (k, value) => {
    const fType = typeof value;
    let newValue = "";
    switch (fType) {
      case "string":
        if (dateFieldsOnCard.includes(k)) {
          newValue = value ? dateFormat(value, "MMM DD, YYYY") : "-";
        } else {
          newValue = value?.replace("_ref_", "_abhyasi_id_");
          newValue = newValue && newValue?.replaceAll("_", " ");
        }
        break;
      case "object":
        newValue = value?.label || dateFormat(value, "MMM DD, YYYY");
        break;
      case "boolean":
        newValue = value === true ? "Yes" : "No";
        break;
      case "number":
        newValue = String(value);
        break;
      default:
        newValue = "-";
        break;
    }

    return newValue;
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="carousel-inner user-detail-card">
          <Carousel
            responsive={responsiveCardForUsers}
            autoPlay={false}
            shouldResetAutoplay={false}
            customRightArrow={<CarouselRightArrow />}
            customLeftArrow={<CarouselLeftArrow />}
          >
            {cardData.map((iData, idx) => {
              const len = iData && Object.keys(iData).length;
              let cardHeight = 0;
              let dynamicStyle = {};
              if (len > 3) {
                cardHeight = len * 30;
                dynamicStyle = { height: `${cardHeight}px` };
              }
              const mapK = `${JSON.stringify(iData)} ${idx}`;
              return (
                <div className="card-deck carousel-card-wrapper" key={mapK}>
                  <div className="card" style={dynamicStyle}>
                    <div className="card-remove">
                      {mode === "edit" ? (
                        <button
                          type="button"
                          className="edit"
                          onClick={() => onEdit(iData, idx)}
                        >
                          <i className="pi pi-pencil edit-icon" />
                        </button>
                      ) : (
                        ""
                      )}
                      <button
                        type="button"
                        className="close"
                        onClick={() => onRemove(cardData, idx)}
                      >
                        <i className="pi pi-times-circle" />
                      </button>
                    </div>
                    <div className="card-body carousel-content-wrapper">
                      {iData &&
                        Object.entries(iData).map(([k, v]) => (
                          <div
                            key={`${k} ${typeof v === "object" ? v?.value : v}`}
                            className="font-regular carousel-content mb-1 ellipsis-class"
                          >
                            {/* <label>{upperFirst(k)}:</label> */}
                            <p title={processData(k, v)}>{processData(k, v)}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
export default React.memo(CarouselCardMultiField);

CarouselCardMultiField.defaultProps = {
  mode: "normal",
  data: [],
  onRemove: () => {},
  onEdit: () => {},
  isPopupModal: false,
};

CarouselCardMultiField.propTypes = {
  mode: PropTypes.string,
  data: PropTypes.any,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  isPopupModal: PropTypes.bool,
};
