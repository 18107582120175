import React, { useCallback, useState } from "react";
import { Button } from "primereact/button";
import HFNLoader from "sharedComponents/lazyLoading/Loading";
import toaster from "utils/toaster";
import {
  validateAbhyasiId,
  validateEmailId,
  validatePhoneNumber,
} from "utils/abhyasiValidations";
import { searchAbhyasi } from "services/partners/partners.service";
import UserCheckboxes from "./UserCheckboxes";

const AbhyasiSearch = ({
  inputKey,
  identifier,
  cb,
  fieldSet,
  isChkBoxAvail,
}) => {
  const [loader, setLoader] = useState(false);
  const [abhyasisUser, setAbhyasisUser] = useState([]);
  const [hfnCo, setHfnCo] = useState([]);
  const [abhyasisUserId, setAbhyasisUserId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [user] = useState(identifier.replaceAll("_", " "));

  const formOnsubmit = useCallback(async () => {
    let data = {};
    if (isChkBoxAvail && hfnCo.length < 1) {
      toaster.error("Select atleast one role");
      return false;
    }
    if (isChkBoxAvail) data["hfnCo"] = hfnCo;
    fieldSet.map((key) => {
      data[key] = abhyasisUser[0]?.[key.split(/[-]+/).pop()] || "";
    });
    data["id"] = abhyasisUserId;
    clearAbhyasis();
    cb(inputKey, data);
  }, [hfnCo, abhyasisUser, abhyasisUserId]);

  const getAbhyasis = useCallback(async (searchVal) => {
    setInputValue(searchVal);
    setAbhyasisUserId("");
    setAbhyasisUser({});
    if (searchVal && searchVal.length > 3) {
      let apiResponse = null;
      setLoader(true);
      if (validateAbhyasiId(searchVal)) {
        apiResponse = await searchAbhyasi(searchVal, "id");
      } else if (validatePhoneNumber(searchVal)) {
        apiResponse = await searchAbhyasi(searchVal, "mobile");
      } else if (validateEmailId(searchVal)) {
        apiResponse = await searchAbhyasi(searchVal, "email");
      }

      if (apiResponse) {
        if (apiResponse?.data?.count > 0) {
          setAbhyasisUserId(apiResponse.data.results[0].id);
          setAbhyasisUser(apiResponse.data.results);
          setLoader(false);
        } else {
          toaster.info(`Searched ${user} is not available in our system.`);
          setAbhyasisUserId("");
          setAbhyasisUser({});
          setLoader(false);
        }
      } else {
        toaster.error("Invalid HFN ID / Phone / Email");
        setLoader(false);
      }
    }
  }, []);

  const clearAbhyasis = () => {
    setAbhyasisUserId("");
    setAbhyasisUser({});
    setInputValue("");
    setHfnCo([]);
  };

  const checkboxCallback = (res) => {
    setHfnCo(res);
  };

  return (
    <>
      <div>
        {loader === true ? <HFNLoader /> : null}

        <div className="AbhyasiSearchWrapper">
          <div
            className={
              isChkBoxAvail
                ? "p-col-12 p-md-6 p-lg-6"
                : "p-col-12 p-md-6 p-lg-4"
            }
          >
            <div className="input-search">
              <input
                type="text"
                className="p-inputtext input-search-control-small p-mb-2"
                placeholder="Search By HFN ID/Mobile/Email"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />

              <button
                type="button"
                className="search-apply-btn search-apply-btn-small"
                onClick={() => getAbhyasis(inputValue)}
              >
                <span>
                  <div className="search-icon-div">
                    <i className="pi pi-search btn-search-icon" />
                  </div>
                </span>
              </button>
            </div>
            <p className="hint">
              <i>Mobile format (Eg): +918888888888</i>
            </p>
          </div>

          {abhyasisUserId && (
            <div
              className={
                isChkBoxAvail
                  ? "card p-col-12 p-md-6 p-lg-6"
                  : "card p-col-12 p-md-6 p-lg-4"
              }
            >
              <div className="card-body">
                {abhyasisUser[0]?.name && (
                  <p>
                    <i className="uil uil-user" />{" "}
                    <span>{abhyasisUser[0]?.name}</span>
                  </p>
                )}
                {abhyasisUser[0]?.mobile && (
                  <p>
                    <i className="uil uil-phone-alt" />{" "}
                    <span>{abhyasisUser[0]?.mobile}</span>
                  </p>
                )}
                {abhyasisUser[0]?.email && (
                  <p>
                    <i className="uil uil-at" />{" "}
                    <span>{abhyasisUser[0]?.email}</span>
                  </p>
                )}
                {isChkBoxAvail && (
                  <div className="card-checkbox">
                    <UserCheckboxes
                      callBackFun={checkboxCallback}
                      loc="search"
                    />
                  </div>
                )}
                <div className="more-menu-buttons">
                  <Button
                    onClick={clearAbhyasis}
                    className="p-button p-button-secondary"
                  >
                    Discard
                  </Button>
                  &nbsp;
                  <Button
                    type="button"
                    label="Add"
                    onClick={formOnsubmit}
                    className="p-button p-button-primary p-mr-2"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <p className="hint-search">
        <i>
          In case, you are unable to add using the above search option, you may
          add manually by filling in below details.
        </i>
      </p>
    </>
  );
};

export default AbhyasiSearch;
