import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PropTypes from "prop-types";
import { upperFirst } from "lodash";
import CarouselRightArrow from "./carousel-right-arrow";
import CarouselLeftArrow from "./carousel-left-arrow";
import { responsiveCardForUsers } from "./responsive";
import confirmDialog from "utils/confirmDialog";
// import { SessionSearchUser } from "utils/constants";

const CoordinatorCard = ({
  data,
  onRemove,
  mode,
  onEdit,
  isSession = false,
}) => {
  const [cardData, setCardData] = useState([]);

  if (responsiveCardForUsers?.desktop?.items) {
    responsiveCardForUsers.desktop.items = isSession ? 2 : 3;
  }

  let uniqueKey = Date.now();

  useEffect(() => {
    data.sort((a, b) => (a.qty > b.qty ? 1 : -1));
    setCardData(data);
  }, [data]);

  const onRemoveItem = (data, id) => {
    confirmDialog.custom({
      icon: "",
      message: `Are you sure? You are about to delete the record`,
      accept: () => onRemove(data, id),
      acceptLabel: "Proceed",
      rejectLabel: "Cancel",
      visible: true,
    });
  };

  const getVal = (v) => {
    if (typeof v === "object") {
      let res = [];
      v.map((v) => {
        let val = upperFirst(v.replaceAll("_", " "));
        res.push(val);
      });
      return res.toString();
    } else return v;
  };

  const moveData = (data, key) => {
    if (!data[key]) return data;
    let lData = data[key];
    delete data[key];
    data[key] = lData;
    return data;
  };
  let cardClassName = isSession ? "session-card card mx-4" : "card mx-4";
  return (
    <div className="row">
      <div className="col-12">
        <div className="carousel-inner user-detail-card">
          <Carousel
            key={uniqueKey}
            responsive={responsiveCardForUsers}
            autoPlay={false}
            shouldResetAutoplay={false}
            customRightArrow={<CarouselRightArrow />}
            customLeftArrow={<CarouselLeftArrow />}
          >
            {cardData.map((iData, idx) => {
              iData = moveData(iData, "hfnCo");
              const mapK = `${JSON.stringify(iData)} ${idx}`;
              return (
                <div className="card-deck carousel-card-wrapper" key={mapK}>
                  <div className={cardClassName}>
                    <div className="card-remove">
                      {mode === "edit" ? (
                        <button
                          type="button"
                          className="edit"
                          onClick={() => onEdit(iData, idx)}
                        >
                          <i className="pi pi-pencil edit-icon" />
                        </button>
                      ) : (
                        ""
                      )}
                      <button
                        type="button"
                        className="close"
                        onClick={() => onRemoveItem(cardData, idx)}
                      >
                        <i className="uil uil-trash-alt icon-danger" />
                      </button>
                    </div>
                    <div className="card-body carousel-content-wrapper coordinator-card">
                      {Object.entries(iData).map(([k, v]) =>
                        k !== "id" && k !== "order" && v ? (
                          <div
                            key={`${k} ${typeof v === "object" ? v?.value : v}`}
                            className={
                              "font-regular carousel-content mb-1 uil-" + k
                            }
                            title={getVal(v)}
                          >
                            {getVal(v)}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
export default React.memo(CoordinatorCard);

CoordinatorCard.defaultProps = {
  mode: "normal",
  data: [],
  onRemove: () => {},
  onEdit: () => {},
};

CoordinatorCard.propTypes = {
  mode: PropTypes.string,
  data: PropTypes.any,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
};
