import { axAppSRCM } from "../base";

const PartnerService = {
  searchAbhyasi: (param, paramType) => {
    let mobile = "";
    if (paramType === "mobile") {
      mobile = param.replace("+", "%2B");
    }
    let email = "";
    if (paramType === "email") {
      email = param.toLowerCase();
    }
    let id = "";
    if (paramType === "id") {
      id = param.toUpperCase();
    }
    switch (paramType) {
      case "id":
        return axAppSRCM.get(`/api/v2/abhyasis/search/?ref=${id}`);
      case "mobile":
        return axAppSRCM.get(`/api/v2/abhyasis/search/?mobile=${mobile}`);
      case "email":
        return axAppSRCM.get(`/api/v2/abhyasis/search/?email=${email}`);
      default:
        return axAppSRCM.get(`/api/v2/abhyasis/search/?page_size=200`);
    }
  },

  getLanguages: () => {
    return axAppSRCM.get(`/api/v2/languages/`);
  },

  addAbhyasi: (payload) => {
    return axAppSRCM.post(`/api/v2/seekers/`, payload);
  },

  readAbhyasi: (partner_id) => {
    return axAppSRCM.get(`/api/v2/abhyasis/${partner_id}/`);
  },

  readAbhyasiByRef: (refId) => {
    return axAppSRCM.get(`/api/v2/abhyasis/?ref=${refId}`);
  },

  getPartnerList: (eventId) => {
    return axAppSRCM.get(
      `/connect/v3/events/${eventId}/partners/?page_size=200`
    );
  },

  getPartnerDetail: (eventId, partnerId) => {
    return axAppSRCM.get(
      `/connect/v3/events/${eventId}/partners/${partnerId}/`
    );
  },

  addPartner: (eventId, payload) => {
    return axAppSRCM.post(`/connect/v3/events/${eventId}/partners/`, payload);
  },

  updatePartner: (eventId, partnerId, payload) => {
    return axAppSRCM.patch(
      `/connect/v3/events/${eventId}/partners/${partnerId}/`,
      payload
    );
  },
};

const {
  searchAbhyasi,
  getLanguages,
  addAbhyasi,
  readAbhyasi,
  readAbhyasiByRef,
  getPartnerList,
  getPartnerDetail,
  addPartner,
  updatePartner,
} = PartnerService;

export {
  searchAbhyasi,
  getLanguages,
  addAbhyasi,
  readAbhyasi,
  readAbhyasiByRef,
  getPartnerList,
  getPartnerDetail,
  addPartner,
  updatePartner,
};
