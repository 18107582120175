import React from "react";

export const lazy = (url, delay = "300") => {
  return React.lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import(`components/${url}`)), delay);
    });
  });
};

export const lazyMobile = (url, delay = "300") => {
  return React.lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import(`components/mobile/${url}`)), delay);
    });
  });
};

export const lazyUep = (url, delay = "300") => {
  return React.lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import(`components/users/${url}`)), delay);
    });
  });
};

export const lazyHfnApp = (url, delay = "300") => {
  return React.lazy(() => {
    return new Promise((resolve) => {
      setTimeout(
        () => resolve(import(`components/heartfulness-app/${url}`)),
        delay
      );
    });
  });
};
