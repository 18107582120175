import { useState, useEffect } from "react";
import { lStorage } from "utils/storage";
import { getFirebaseUser } from "utils/login";
import Barcode from "react-barcode";
import PropTypes from "prop-types";
import { axAppPreceptor } from "services/base";
import config from "assets/config";

function IdCard(props) {
  const { cardNumber, staticData, printDisable } = props;
  const [cardDetails, setCardDetails] = useState();
  const [qrLink, setQRLink] = useState(
    `${config.qrCodeURI}/qr?qr_type=gen&qr_content=${cardNumber}`
  );
  const [loading, setLoading] = useState(true);
  const { loginUser, srcmToken } = lStorage.get("authInfo");
  const { city, first_name, last_name } = loginUser;
  const sahajMargLogo = "/assets/sahaj-marg-logo.svg";
  const issuedBy = `${first_name}${last_name ? ` ${last_name}` : ""}`;
  const issuedByPlace = `${city}`;

  useEffect(() => {
    printDisable(loading);
  }, [loading]);

  const generateId = async () => {
    setLoading(true);
    const firebaseUser = getFirebaseUser();
    let firebaseToken = null;
    try {
      firebaseToken = await firebaseUser?.getIdToken?.();
    } catch {
      // console.log("Something went wrong");
    }
    const configData = {
      headers: {
        "x-client-id": config.xClientIdPreceptor,
        Authorization: `Bearer ${firebaseToken || srcmToken}`,
      },
    };
    axAppPreceptor
      .get(`/api/v2/abhyasis/?ref=${cardNumber}`, configData)
      .then((res) => {
        const userResponse = res?.data?.results[0];
        setCardDetails(userResponse);
        const name = `${userResponse?.first_name}${
          userResponse?.last_name ? ` ${userResponse?.last_name}` : ""
        }`;
        setQRLink(
          `${config.qrCodeURI}/qr?qr_type=gen&qr_content=${cardNumber} | ${name} | ${userResponse?.city}`
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    generateId();
  }, []);

  return (
    <div
      style={{
        pageBreakBefore: "always",
      }}
    >
      {loading ? (
        <i
          className="pi pi-spin pi-spinner"
          style={{
            fontSize: "100px",
            color: "gray",
            margin: "230px 300px",
          }}
        />
      ) : (
        <div className="card-container">
          <div className="card-dimensions">
            <div className="gradient top-border" />
            <div className="card-details">
              <img
                src="/assets/blue-logo.svg"
                alt="heartfulness"
                className="logo-styles"
              />
              <div className="role-styles">
                {cardDetails?.abhyas_stage.replace("_", " ")}
              </div>
              {cardDetails?.photo_url ? (
                <img
                  className="pic-styles"
                  src={cardDetails?.photo_url}
                  alt="Pic"
                />
              ) : (
                <i
                  className="pi pi-user"
                  style={{
                    fontSize: "40px",
                    marginTop: "20px",
                    color: "#6BB9C4",
                    padding: "25px",
                    border: "4px solid #6BB9C4",
                    borderRadius: "50%",
                  }}
                />
              )}
              <div className="name-styles">{`${cardDetails?.first_name}${
                cardDetails?.last_name ? ` ${cardDetails?.last_name}` : ""
              }, ${cardDetails?.city}`}</div>
              <div className="abhyasi-id-styles">{cardNumber}</div>
              <div className="code-styles">
                <div style={{ height: "auto", margin: "0 auto" }}>
                  <input
                    type="image"
                    alt="qrcode"
                    className="qrCode"
                    size={100}
                    style={{
                      height: "90px",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                    src={qrLink}
                  />
                </div>
                <div>
                  <Barcode
                    value={cardNumber}
                    displayValue={false}
                    height={35}
                    width={1.5}
                  />
                </div>
              </div>
              <div className="code-styles issued-by-parent">
                <div style={{ color: "rgba(180, 180, 180, 255)" }}>
                  Issued by: &nbsp;
                </div>
                <div
                  style={{
                    color: "rgba(90, 90, 90, 255)",
                    fontWeight: "500",
                  }}
                >{`${
                  cardDetails?.resp_prefect
                    ? cardDetails?.resp_prefect?.name
                    : issuedBy
                }, ${issuedByPlace}`}</div>
              </div>
            </div>
            <div className="gradient bottom-border" />
          </div>
          <div className="card-dimensions">
            <div className="gradient top-border" />
            <div className="card-details id-card-back">
              <img
                className="pic-styles"
                src={sahajMargLogo}
                alt="Sahai Marg Logo"
              />

              <div className="kanha-address return-text">
                {staticData.ifFoundText}
              </div>
              <div className="sahaj-marg-text">{staticData.sahajMargText}</div>
              <div className="kanha-address">{staticData.kanhaAddress}</div>
            </div>
            <div className="gradient bottom-border" />
          </div>
        </div>
      )}
    </div>
  );
}

IdCard.propTypes = {
  cardNumber: PropTypes.string.isRequired,
};

export default IdCard;
