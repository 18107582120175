import config from "assets/config";
import axios from "axios";
import CryptoJS from "crypto-js";

class SelfRegistrationService {
  postSelfRegistration = (payload, event) => {
    const srcmConfig = JSON.parse(config.mysrcmConfig);
    const hmacToken = CryptoJS.HmacSHA256(
      JSON.stringify(payload),
      srcmConfig?.xClientId
    );

    return axios
      .post(`${config.apiURI}/events/${event}/attendance/submit/`, payload, {
        headers: {
          "x-client-id": srcmConfig?.xClientId,
          "hmac-token": hmacToken,
        },
      })
      .then((res) => ({
        data: res.data,
      }));
  };

  getSessionDetails = (payload) => {
    const srcmConfig = JSON.parse(config.mysrcmConfig);
    const hmacToken = CryptoJS.HmacSHA256(
      JSON.stringify(payload),
      srcmConfig?.xClientId
    );

    return axios
      .post(`${config.apiURI}/events/unique-code/`, payload, {
        headers: {
          "x-client-id": srcmConfig?.xClientId,
          "hmac-token": hmacToken,
        },
      })
      .then((res) => ({
        data: res.data,
      }));
  };

  getEventDetail = (eventId) => {
    return axios
      .get(`${config.apiURI}/events/event-details/`, {
        params: { event: eventId },
      })
      .then((res) => ({
        data: res.data,
      }));
  };
}

export default SelfRegistrationService;
