import React, {
  useRef,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import HFNLoader from "sharedComponents/lazyLoading/Loading";
import Service from "services/selfRegistration/selfReg.service";
import { useParams } from "react-router-dom";
import {
  HfnFirebaseAuth,
  signOut,
} from "@heartfulnessinstitute/react-hfn-profile";
import { setLoginData, isLoginAuth, getLoginLocaluserData } from "utils/login";
import { Menu } from "primereact/menu";
import { lStorage } from "utils/storage";
import { Card } from "primereact/card";
import toaster from "utils/toaster";
import SelfAttendance from "sharedComponents/uep/SelfAttendance";
import { axAppSRCM } from "services/base";

function SelfRegistration() {
  const { event, session } = useParams();
  const service = useMemo(() => new Service(), []);
  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [sessionDetails, setSessionDetails] = useState({});
  const [activeSession, setActiveSession] = useState({});
  const [isActiveSession, setIsActiveSession] = useState(false);
  const [wrapperCls, setWrapperCls] = useState("p-col-12 p-md-12 p-lg-6 ");
  const [user, setUser] = useState({});
  const [initValue, setInitValue] = useState({});
  const firebaseRef = useRef(null);
  const menuRef = useRef(null);
  const formKey = useRef(false);
  const genderData = {
    M: { label: "Male", value: "M" },
    F: { label: "Female", value: "F" },
  };

  const setActiveUser = (myInfo, srcmToken) => {
    const userData = {};
    userData.id = myInfo.id;
    userData.name = myInfo.name;
    userData.user_email = myInfo.user_email;
    userData.phone = myInfo.phone;
    userData.age = myInfo?.age;
    userData.gender = genderData[myInfo?.gender];
    userData.city = myInfo?.city_id;
    userData.srcmToken = srcmToken;
    lStorage.set("selfRegAuthInfo", userData);
    setLoginData(userData);
    setUser(userData);
    setLoader(false);
    return userData;
  };

  const processLogin = useCallback(({ myInfo }) => {
    setLoader(true);
    let clearIns;
    if (firebaseRef.current) {
      clearIns = firebaseRef.current.authFn.onAuthStateChanged(
        (firebaseUser) => {
          firebaseUser
            ?.getIdToken()
            .then((srcmToken) => {
              if (myInfo && Object.keys(myInfo).length > 0)
                setActiveUser(myInfo, srcmToken);
              else {
                lStorage.set("authInfo", { srcmToken });
                axAppSRCM.get("/api/v2/me/").then((res) => {
                  if (res?.data?.results.length > 0) {
                    const myInfoData = res?.data?.results[0];
                    setActiveUser(myInfoData, srcmToken);
                  }
                });
              }
            })
            .catch(() => {
              setLoader(false);
            });
        }
      );
    }
    return clearIns ? clearIns() : "";
  }, []);

  useEffect(() => {
    setLoader(true);
    service
      .getEventDetail(event)
      .then((res) => {
        setSessionDetails(res?.data);
        const activeSessionData = res?.data?.sessions.find(
          ({ id }) => id === session
        );
        let isActive = false;
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const sessionDate = new Date(activeSessionData?.start_datetime);
        sessionDate.setHours(0, 0, 0, 0);
        if (currentDate.toString() === sessionDate.toString()) isActive = true;
        else if (sessionDate > currentDate)
          setErrorMsg("Session not yet started");
        else if (sessionDate < currentDate)
          setErrorMsg("Session has been ended");

        setIsActiveSession(isActive);
        if (isActive === false)
          setWrapperCls("p-col-12 p-md-12 p-lg-6 closedEvent");
        setActiveSession(activeSessionData);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, [event, session]);

  useEffect(() => {
    if (isLoginAuth("selfRegAuthInfo") && !user?.id) {
      const myInfo = getLoginLocaluserData("selfRegAuthInfo");
      setActiveUser(myInfo);
    }
  }, [isLoginAuth]);

  useEffect(() => {
    if (user?.id && activeSession) {
      setInitValue({
        regName: user?.name,
        regPhone: user?.phone ? `+91 ${user?.phone}` : ``,
        regEmail: user?.user_email,
        regCity: {
          name: activeSession?.city__name,
          id: activeSession?.city,
        },
        regAge: user?.age,
        regGender: user?.gender,
        regDate: new Date(),
      });
    } else
      setInitValue({
        regCity: {
          name: activeSession?.city__name,
          id: activeSession?.city,
        },
      });

    formKey.current = !formKey.current;
  }, [user, activeSession]);

  const logOut = () => {
    formKey.current = !formKey.current;
    setInitValue({});
    lStorage.clear();
    setUser({});
    signOut();
  };

  const menu = [
    {
      label: "Logout",
      icon: "uil uil-sign-out-alt",
      command: async () => {
        logOut();
      },
    },
  ];

  const callbackFunction = (r) => {
    if (r.status) toaster.success("Attendance has been marked successfully");
    else toaster.error(r.msg || "Attendance already marked");
  };

  return (
    <div className="app-wrapper open">
      <div className="layout">
        <div className="header">
          <div className="header-selreg">
            <div className="header-section self-reg">
              <img src="/assets/hfn-logo.svg" alt="heartfulness" />
            </div>
            {isLoginAuth("selfRegAuthInfo") && (
              <div className="signOut">
                <div
                  role="presentation"
                  className="user-info"
                  onClick={(e) => menuRef.current && menuRef.current.toggle(e)}
                  onKeyDown={() => {}}
                >
                  <span className="avator">
                    <img src="/assets/avatar.png" alt="profile" />
                  </span>
                  <span className="user-name">
                    <span className="name"> {user.name || ""} </span>
                  </span>
                </div>
                <Menu className="user-menu" model={menu} popup ref={menuRef} />
              </div>
            )}
          </div>
        </div>
        {loader === true ? <HFNLoader /> : null}

        <Card>
          <div className="p-d-flex p-flex-wrap selfRegForm selfRegWeb">
            <div className={wrapperCls}>
              <h3>{sessionDetails?.name} - Event Attendance</h3>
              <h3>{activeSession?.name}</h3>
              {!isActiveSession && <p>{errorMsg}</p>}
              {!isLoginAuth("selfRegAuthInfo") && isActiveSession && (
                <div className="signIn">
                  <p>Sign in to Autofill</p>
                  <HfnFirebaseAuth
                    ref={firebaseRef}
                    titleText=""
                    doLogin={processLogin}
                  />
                </div>
              )}

              {isActiveSession && activeSession?.id && (
                <div className="regForm">
                  <SelfAttendance
                    key={formKey.current}
                    event={event}
                    session={session}
                    initValues={initValue}
                    callbackFun={callbackFunction}
                  />
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default SelfRegistration;
