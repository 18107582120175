import React from "react";
import PropTypes from "prop-types";
import ArrowRight from "assets/icons/icon-arrow-right.svg";

const CarouselRightArrow = ({ onClick }) => (
  <div className="carousel-arrow">
    <button type="button" className="arrow right bg-white" onClick={onClick}>
      <img src={ArrowRight} alt="Right Arrow" />
    </button>
  </div>
);

export default React.memo(CarouselRightArrow);

CarouselRightArrow.defaultProps = {
  onClick: () => {},
};

CarouselRightArrow.propTypes = {
  onClick: PropTypes.func,
};
