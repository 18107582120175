import config from "assets/config";
import axios from "axios";

class SelfAttendanceService {
  getPnr = (pnr, eventId, session) => {
    return axios
      .get(`${config.apiURI}events/${eventId}/get-pnr-participants/`, {
        params: { session, pnr },
      })
      .then((res) => ({
        data: res.data,
      }));
  };

  postAttendance = (eventId, payload) => {
    return axios
      .post(`${config.apiURI}events/${eventId}/submit-pnr-attendance/`, payload)
      .then((res) => ({
        data: res.data,
      }));
  };

  getEventDetail = (eventId) => {
    return axios
      .get(`${config.apiURI}/events/event-details/`, {
        params: { event: eventId },
      })
      .then((res) => ({
        data: res.data,
      }));
  };
}

export default SelfAttendanceService;
