import React, { useEffect } from "react";

import { Routes, Route } from "react-router-dom";

// firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import AuthGuard from "auth-guard";

// components
import Login from "components/login/Login";
import SelfRegistration from "components/SelfRegistration/SelfRegistration";
import GlobalRegistration from "components/GlobalRegistration/GlobalRegistration";
import PrintIdCard from "components/events/eventSessions/menuItems/PrintIdCard";
import Launch from "components/mobile/Launch/index";
import Refresh from "components/mobile/Launch/refresh";
import DownloadQr from "sharedComponents/downloadQr";
import LaunchDev from "components/mobile/Launch/LaunchDev";
import HFNErrorBoundary from "components/errorBoundary";

// shared components
import HFNLoader from "sharedComponents/lazyLoading";
import HFNToaster from "sharedComponents/toasters";
import HFNConfirmDialog from "sharedComponents/confirmDialog";

// utils
import { lazy } from "utils/lazy";

import {
  getLoginLocaluserData,
  setFirebaseToken,
  setFirebaseUser,
  setLoginData,
} from "utils/login";

// config
import config from "assets/config";
import SelfAttendance from "components/SelfAttendance";
import Maintenance from "components/maintenance/Maintenance";

// lazy components
const LayoutContainer = lazy("layouts/Template");
const MobileLayoutContainer = lazy("layouts/PlainTemplate");
const UepLayoutTemplate = lazy("layouts/UepTemplate");
const HfnAppContainer = lazy("layouts/HfnAppTemplate");
const IS_MAINTENANCE = false;

function App() {
  useEffect(() => {
    // let refreshTokenFunc = null;
    if (config?.firebaseAuthConfig) {
      if (!firebase.apps.length)
        firebase.initializeApp(JSON.parse(config.firebaseAuthConfig), "auth");
      firebase
        .app("auth")
        ?.auth()
        ?.onAuthStateChanged((firebaseUser) => {
          if (firebaseUser) {
            setFirebaseUser(firebaseUser);
          }
        });
      firebase
        .app("auth")
        ?.auth()
        ?.onIdTokenChanged((firebaseUser) => {
          firebaseUser?.getIdToken().then((srcmToken) => {
            setFirebaseToken(srcmToken);
          });
        });
    }
    const lsData = getLoginLocaluserData();
    if (
      lsData &&
      typeof lsData === "object" &&
      Object.keys(lsData).length > 0
    ) {
      setLoginData(lsData);
    }
  }, []);

  return (
    <HFNErrorBoundary>
      <HFNLoader>
        <div className="hfn">
          {IS_MAINTENANCE ? (
            <Routes>
              <Route path="/login" element={<Maintenance />} />
              <Route
                path="/self-registration/:event/:session"
                element={<Maintenance />}
              />
              <Route
                path="/print-qr/:eventId/:sessionId/:blockId/:source/:type"
                element={<Maintenance />}
              />
              <Route
                path="/global-event-registration"
                element={<Maintenance />}
              />
              <Route
                path="/global-event-registration-non-connect"
                element={<Maintenance />}
              />
              <Route
                path="/non-connect-self-attendance/:eventId/:sessionId"
                element={<Maintenance />}
              />
              <Route
                path="/idcard-print/:cardNumber"
                element={<Maintenance />}
              />
              <Route
                path="/idcard-print/:event/:cardNumber"
                element={<Maintenance />}
              />
              <Route path="mobile/launch" element={<Launch />} />
              <Route path="mobile/launch/refresh" element={<Refresh />} />
              <Route path="mobile/launch-dev" element={<LaunchDev />} />
              <Route path="/mobile/*" element={<Maintenance />} />
              <Route path="/heartfulness-app/*" element={<Maintenance />} />
              <Route path="/users/*" element={<Maintenance />} />
              <Route
                path="*"
                element={<AuthGuard path="/" component={Maintenance} />}
              />
            </Routes>
          ) : (
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/self-registration/:event/:session"
                element={<SelfRegistration />}
              />
              <Route
                path="/print-qr/:eventId/:sessionId/:blockId/:source/:type"
                element={<DownloadQr />}
              />
              <Route
                path="/global-event-registration"
                element={<GlobalRegistration />}
              />
              <Route
                path="/global-event-registration-non-connect"
                element={<GlobalRegistration />}
              />
              <Route
                path="/non-connect-self-attendance/:eventId/:sessionId"
                element={<SelfAttendance />}
              />
              <Route
                path="/idcard-print/:cardNumber"
                element={<PrintIdCard />}
              />
              <Route
                path="/idcard-print/:event/:cardNumber"
                element={<PrintIdCard />}
              />
              <Route path="mobile/launch" element={<Launch />} />
              <Route path="mobile/launch/refresh" element={<Refresh />} />
              <Route path="mobile/launch-dev" element={<LaunchDev />} />
              <Route path="/mobile/*" element={<MobileLayoutContainer />} />
              <Route path="/heartfulness-app/*" element={<HfnAppContainer />} />
              <Route path="/users/*" element={<UepLayoutTemplate />} />
              <Route
                path="*"
                element={<AuthGuard path="/" component={LayoutContainer} />}
              />
            </Routes>
          )}
        </div>

        <div>
          <HFNToaster />
          <HFNConfirmDialog />
        </div>
      </HFNLoader>
    </HFNErrorBoundary>
  );
}

export default App;
