import React, { useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";

import { getHomePath } from "utils/common";
import PropTypes from "prop-types";

function HomeButton({ clearError }) {
  const navigate = useNavigate();

  const goToDashboard = useCallback(() => {
    clearError();
    navigate(getHomePath());
  }, []);

  return (
    <Button
      label="Go To Home"
      className="goto-home-button p-mt-2 p-mr-3"
      onClick={goToDashboard}
    />
  );
}

HomeButton.propTypes = {
  clearError: PropTypes.func.isRequired,
};

export default HomeButton;
