import { RESET, CLEAR, LOGOUT, UPDATE_INFO } from "store/actionTypes/login";

// constants
const initialState = {
  expired: false,
};

// reducer
const loginDetails = (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return { ...initialState };
    case CLEAR:
      return { ...initialState };
    case LOGOUT:
      return { expired: true };
    case UPDATE_INFO:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default loginDetails;
