import React from "react";

// router
import { useNavigate } from "react-router-dom";

// prime components
import { Button } from "primereact/button";

// utils
import { logout } from "utils/login";
import { getHomePath } from "utils/common";

// services

function Unauthorized() {
  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate(getHomePath());
  };

  const goToLogin = async () => {
    logout(false);
    navigate("/login");
  };

  return (
    <div>
      <div className="unauthorized-page-access">Unauthorized Access!</div>
      <p className="unauthorized-page-message p-text-center p-m-2">
        You don&apos;t have access to the screen. Please contact administrator
        for more details.
      </p>
      <div className="p-field p-text-center p-mx-6 p-mb-6 p-mt-5">
        <Button
          label="Go To Home"
          className="p-mt-4 p-mb-2 p-mr-3"
          onClick={goToDashboard}
        />
        <Button
          type="button"
          label="Logout"
          className="p-button p-button-danger p-mt-4 p-mb-2"
          onClick={goToLogin}
        />
      </div>
    </div>
  );
}

export default Unauthorized;
