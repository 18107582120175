import React, { useMemo } from "react";
import { useSearchParams, Navigate, useLocation } from "react-router-dom";
import Unauthorized from "components/logout/Unauthorized";
import hasRouteAccess from "utils/accessControl";
import { isLoginAuth } from "utils/login";
import { lStorage } from "utils/storage";

import PropTypes from "prop-types";

function AuthGuard({ component: Component, path }) {
  const [qParam] = useSearchParams();
  const minimalParam = lStorage.get("minimalMode");
  const location = useLocation();
  if (
    !minimalParam?.minimalmode &&
    qParam.get("minimalmode") &&
    qParam.get("template") &&
    qParam.get("sector") &&
    qParam.get("category")
  ) {
    const minimalMode = {
      minimalmode: qParam.get("minimalmode"),
      authredirect: qParam.get("authredirect"),
      template: qParam.get("template"),
      sector: qParam.get("sector"),
      category: qParam.get("category"),
      city: qParam.get("city"),
      trainer: qParam.get("trainer"),
    };
    lStorage.set("minimalMode", minimalMode);
  }
  const isLoggedIn = useMemo(() => isLoginAuth(), []);
  const hasAccess = useMemo(() => hasRouteAccess(path), [path]);
  if (!isLoggedIn) lStorage.set("landingPage", { param: location });
  else lStorage.set("landingPage", {});
  return (
    <div>
      {isLoggedIn ? (
        <> {hasAccess ? <Component /> : <Unauthorized />} </>
      ) : (
        <Navigate to={{ pathname: "/login" }} />
      )}
    </div>
  );
}

AuthGuard.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
};
export default AuthGuard;
