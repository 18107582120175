import { InputMask } from "primereact/inputmask";
import React, { useState, useEffect } from "react";
import PropType from "prop-types";

const InputTimeField = ({
  keyVal,
  setError,
  error,
  getValue,
  setValue,
  clearError,
  initalVal,
  idDisabled,
  isTimeOnly,
}) => {
  const [timeDate, setTimeDate] = useState("");
  const fKey = isTimeOnly ? keyVal : `${keyVal}-time`;
  useEffect(() => {
    if (timeDate) {
      const [hour, minutes] = timeDate.split(":");
      if (hour > 23 || minutes > 59) {
        return setError(keyVal, {
          type: "custom",
          message: "Enter a valid time format",
        });
      } else {
        clearError(keyVal);
        setValue(fKey, timeDate);
      }
    } else {
      clearError(keyVal);
      setValue(fKey, "00:00");
    }
    return () => {};
  }, [timeDate, keyVal, getValue]);

  useEffect(() => {
    if (initalVal) {
      setTimeDate(initalVal);
    }
  }, [initalVal]);

  return (
    <InputMask
      id="basic"
      mask="99:99"
      placeholder="HH:MM"
      disabled={idDisabled}
      defaultValue={initalVal}
      value={timeDate}
      style={error?.message ? { borderColor: "#f1416c" } : {}}
      onChange={(e) => setTimeDate(e.target.value)}
    />
  );
};

InputTimeField.defaultProps = {
  error: {},
  idDisabled: false,
};

InputTimeField.propTypes = {
  keyVal: PropType.string.isRequired,
  setError: PropType.func.isRequired,
  error: PropType.shape({
    message: PropType.string,
  }),
  getValue: PropType.oneOfType([PropType.instanceOf(Date), PropType.string]),
  setValue: PropType.func.isRequired,
  clearError: PropType.func.isRequired,
  initalVal: PropType.string.isRequired,
  idDisabled: PropType.bool,
};

export default InputTimeField;
