// axios
import axios from "axios";

// request and response interceptor
import interceptor from "services/baseInterceptor";

// config
import config from "assets/config";

export const ax = axios.create({
  baseURL: config.apiURI,
});

export const axApp = axios.create({
  baseURL: config.appURI,
});

export const axAppSRCM = axios.create({
  baseURL: config.apiSRCMURI,
});

export const axAppLoc = axios.create({
  baseURL: config.locationAPIURI,
});

export const axAppPreceptor = axios.create({
  baseURL: config.apiSRCMURI,
});

export const axPlacesAutoComplete = axios.create({
  baseURL: config.placesAutoCompleteURI,
});

export const axSRCMStaticData = axios.create({
  baseURL: config.srcmStaticDataURI,
});

export const axStaticFileData = axios.create({
  baseURL: config.staticFileDataURI,
});

export const axOms = axios.create({
  baseURL: config.apiOmsURI,
});

interceptor(ax);
interceptor(axAppSRCM, config.xClientIdSRCM);
interceptor(axOms, "", true);
