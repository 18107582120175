import React from "react";
import PropTypes from "prop-types";
import ArrowRight from "assets/icons/icon-arrow-right.svg";

const CarouselLeftArrow = ({ onClick }) => (
  <div className="carousel-arrow">
    <button type="button" className="arrow left bg-white" onClick={onClick}>
      <img src={ArrowRight} alt="Left Arrow" />
    </button>
  </div>
);

export default React.memo(CarouselLeftArrow);

CarouselLeftArrow.defaultProps = {
  onClick: () => {},
};

CarouselLeftArrow.propTypes = {
  onClick: PropTypes.func,
};
