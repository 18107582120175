// utils
import { getLoginRole } from "utils/login";

// access
import userPageAccess from "routes/pageAccess";

const hasRouteAccess = (routeURL) => {
  let url;
  const roleInfo = getLoginRole();

  if (typeof routeURL === "string" && routeURL !== "/")
    url = routeURL.replace(/^\/|\/$/g, "");
  else url = routeURL;

  const pathRoutes = userPageAccess.route.find((item) => item.path === url);
  const roleRoutes =
    pathRoutes?.roles && pathRoutes.roles?.length > 0
      ? pathRoutes.roles.find((role) => roleInfo?.includes(role))
      : true;
  return roleRoutes || false;
};

export default hasRouteAccess;
