import { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import PropTypes from "prop-types";
import { InputText } from "primereact/inputtext";

const DEFAULT_NAME = "Selected image";

function ImageCrop({
  onChange,
  initialCrop,
  validations,
  register,
  keyVal,
  error,
}) {
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState(initialCrop);
  const [completedCrop, setCompletedCrop] = useState(null);
  const fileRef = useRef();

  const onSelectFile = (e) => {
    if (e.target.files[0]?.type.split("/")[0] !== "image") {
      fileRef.current = null;
      return null;
    }

    if (e.target.files && e.target.files.length > 0) {
      const imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.addEventListener("load", (e) => {
        setUpImg(reader.result);
      });
      reader.readAsDataURL(imageFile);
    }
    setCrop(null);
    setCompletedCrop(null);
  };

  const onLoad = useCallback((e) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    imgRef.current = e.currentTarget;

    const cropped = centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        initialCrop?.aspect,
        width,
        height
      ),
      width,
      height
    );

    setCrop(cropped);
    setCompletedCrop(cropped);
    return false;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop1 = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = 400;
    canvas.height = 450;

    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop1.x * scaleX,
      crop1.y * scaleY,
      crop1.width * scaleX,
      crop1.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    );
    onChange(canvas.toDataURL("image/jpeg"), DEFAULT_NAME);
  }, [completedCrop, DEFAULT_NAME, onChange]);

  return (
    <div>
      <div>
        <InputText
          ref={fileRef}
          type="file"
          accept="image/*"
          className={error ? "p-invalid" : ""}
          {...register(keyVal, {
            ...validations,
            onChange: onSelectFile,
          })}
        />
      </div>

      {upImg && (
        <div
          style={
            upImg
              ? {
                  textAlign: "center",
                  border: "1px solid #919191",
                  borderColor: "muted",
                  marginTop: "0.75rem",
                }
              : {}
          }
        >
          <div>
            <div
              style={{
                marginTop: "0.75rem",
                marginBottom: "0.75rem",
                color: "rgb(89 89 89)",
                fontSize: "0.75rem",
              }}
            >
              Adjust the crop area to select the front view full face in the
              picture
            </div>
          </div>
          <ReactCrop
            style={{ m: 1 }}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
            minWidth={100}
            aspect={initialCrop?.aspect}
            ruleOfThirds
          >
            <img src={upImg} alt={"selected image"} onLoad={onLoad} />
          </ReactCrop>
          <div style={{ mt: 2, color: "text" }}>
            <h4 variant="h4">Selected Photo</h4>
          </div>
          <canvas
            ref={previewCanvasRef}
            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
            style={{
              border: "solid 1px #777",
              width: 200,
              height: 225,
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          />
        </div>
      )}
    </div>
  );
}

ImageCrop.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialCrop: PropTypes.shape({}),
  register: PropTypes.func.isRequired,
  validations: PropTypes.shape({}).isRequired,
  error: PropTypes.string,
  keyVal: PropTypes.string.isRequired,
};

ImageCrop.defaultProps = {
  initialCrop: { aspect: 8 / 9 },
  error: "",
};

const ImageUpload = ({ keyVal, setData, validations, register, error }) => {
  return (
    <div>
      <ImageCrop
        register={register}
        error={error}
        validations={validations}
        keyVal={keyVal}
        onChange={(a) => setData(`${keyVal}_cropped`, a)}
      />
    </div>
  );
};

ImageUpload.propTypes = {
  register: PropTypes.func.isRequired,
  validations: PropTypes.shape({}).isRequired,
  error: PropTypes.string,
  keyVal: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired,
};

ImageUpload.defaultProps = {
  initialCrop: { aspect: 8 / 9 },
  error: "",
};

export default ImageUpload;
