import {
  ax,
  axAppSRCM,
  axPlacesAutoComplete,
  axSRCMStaticData,
  axStaticFileData,
} from "services/base";

// utils
import { getLoginUserRole } from "utils/login";
import { generalStatuses } from "utils/constants";

// constants
import config from "assets/config";

class DropdownService {
  getCountryList = () => {
    const params = {
      pageNumber: 0,
      pageSize: 250,
      countryStatus: "ACTIVE",
    };

    return ax.get("countries", { params }).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.country || [],
      },
    }));
  };

  getOrganizationList = () => {
    const params = {
      pageNumber: 0,
      pageSize: 20000,
    };

    return ax.get("organizations", { params }).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.organization || [],
      },
    }));
  };

  getCountryOrganizationList = (payload) => {
    return ax.post("organizations-by-countryids", payload).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.organization || [],
      },
    }));
  };

  getCenterList = (payload) => {
    const params = {
      pageNumber: 0,
      pageSize: 20000,
      centerStatus: "ACTIVE",
      ...payload,
    };

    return ax.get("centers", { params }).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.center || [],
      },
    }));
  };

  getDonationCampaignList = () => {
    const params = {
      pageNumber: 0,
      pageSize: 20000,
      status: generalStatuses.active.value,
    };

    return ax.get("donation-campaign", { params }).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.donationCampaign || [],
      },
    }));
  };

  getOrgCampaignList = (payload) => {
    const params = {
      pageNumber: 0,
      pageSize: 20000,
      orgId: getLoginUserRole().organization?.id,
      status: generalStatuses.active.value,
      ...payload,
    };

    return ax.get("donation-campaign", { params }).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.donationCampaign || [],
      },
    }));
  };

  getRoleList = () => {
    const params = {
      pageNumber: 0,
      pageSize: 200,
      roleStatus: "ACTIVE",
    };

    return ax.get("roles", { params }).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.roles || [],
      },
    }));
  };

  getAccounts = (params) => {
    return ax.get("bank/account/details", { params }).then((res) => ({
      data: {
        count: res.data?.bankAccountDetails?.length || 0,
        results: res.data?.bankAccountDetails || [],
      },
    }));
  };

  getUserList = (payload) => {
    const params = {
      pageSize: 200,
      ...payload,
      pageNumber: 0,
    };

    return ax.get("admin-users", { params }).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.adminUser || [],
      },
    }));
  };

  getCurrencyConversions = (payload = "INR") => {
    return axSRCMStaticData.get(`rates/${payload}.json`);
  };

  getCachedCitySuggestions = (payload) => {
    return axSRCMStaticData.get(`srcmapi/cities/${payload}.json`);
  };

  getCachedCenterSuggestions = (payload) => {
    return axAppSRCM.get(
      `/api/v2/meditation-centers/my/?state=approved&name__icontains=${payload}`
    );
  };

  getCachedVillageSuggestions = (payload) => {
    return ax
      .get("locations/", {
        params: payload,
      })
      .then((res) => ({
        data: {
          count: res.data.count || 0,
          results: res.data.results || [],
        },
      }));
  };

  getCachedStateSuggestions = (payload) => {
    return axSRCMStaticData.get(`srcmapi/states/${payload}.json`);
  };

  getCachedCountrySuggestions = () => {
    return axSRCMStaticData.get(`srcmapi/countries/all.json`);
  };

  getStaticFileData = () => {
    return axStaticFileData.get(`conf/kanha-shantivanam/config.json`);
  };

  getGPlaceSuggestions = (payload) => {
    return axPlacesAutoComplete.get("gplaces", {
      params: {
        session: config.gPlacesSession,
        input: payload,
      },
    });
  };

  getAshramIdSuggestions = (payload) => {
    return axAppSRCM.get(
      `api/v2/meditation-centers/?name__icontains=${payload}&active=true`
    );
  };

  getZoneSuggestions = (payload) => {
    return axAppSRCM.get(
      `/api/v2/groups/?group_type=zone&search=${payload}&active=true`
    );
  };
}

export default DropdownService;
