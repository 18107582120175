export const CLEAR = "CLEAR_ALL_DROPDOWNS";
export const COUNTRY = "SET_COUNTRIES";
export const ORGANIZATION = "SET_ORGANIZATIONS";
export const COUNTRYORG = "SET_COUNTRY_ORGANIZATIONS";
export const CENTER = "SET_CENTERS";
export const ORGCENTER = "SET_ORGANIZATION_CENTERS";
export const CAMPAIGN = "SET_DONATION_CAMPAIGNS";
export const ORGCAMPAIGN = "SET_ORGANIZATION_CAMPAIGNS";
export const ROLE = "SET_ROLES";
export const USER = "SET_USERS";
export const STATICCOUNTRY = "SET_STATIC_COUNTRIES";
export const STATICCURRENCY = "SET_STATIC_CURRENCIES";
