// utils
import { getFirebaseUser, logout } from "utils/login";
import { lStorage } from "utils/storage";
import toaster from "utils/toaster";
import config from "assets/config";
import { upperFirst } from "lodash";

const interceptor = (ax, xClientIdSRCM = "", ignoreXClientId = false) => {
  const srcmConfig = JSON.parse(config.mysrcmConfig);
  const getErrorFromObj = (objData) => {
    const objKeys = Object.keys(objData);
    return `${upperFirst(objKeys[0].replaceAll("_", " "))}: ${
      objData[objKeys[0]]
    }`;
  };
  ax.interceptors.request.use(
    async (configData) => {
      const authInfo = lStorage.get("authInfo");

      if (authInfo?.srcmToken) {
        const firebaseUser = getFirebaseUser();
        let firebaseToken = null;
        try {
          firebaseToken = await firebaseUser?.getIdToken?.();
        } catch {
          // console.log("Something went wrong");
        }
        const configDataNew = configData;
        if (!ignoreXClientId) {
          configDataNew.headers["x-client-id"] =
            xClientIdSRCM || srcmConfig.xClientId;
        }
        configDataNew.headers.Authorization = `Bearer ${
          firebaseToken || authInfo.srcmToken
        }`;
      }

      return configData;
    },
    (error) => Promise.reject(error)
  );

  ax.interceptors.response.use(
    (next) => Promise.resolve(next),
    (error) => {
      if (error.response && error.response.status === 401) {
        logout();
      } else if (error.response && error.response.status === 400) {
        const err = Array.isArray(error?.response?.data)
          ? {
              non_field_errors:
                error?.response?.data[0]?.non_field_errors[0] ||
                "Invalid input data",
            }
          : error?.response?.data;

        const errorObj = typeof err === "object" ? getErrorFromObj(err) : "";
        const errorMessage = errorObj || "Invalid input data";
        toaster.custom({
          severity: "error",
          summary: "Error:",
          detail: Array.isArray(errorMessage) ? errorMessage[0] : errorMessage,
          life: 5000,
        });
      } else {
        toaster.custom({
          severity: "error",
          summary: "Error:",
          detail: error?.response?.data?.message || error.message,
          life: 5000,
        });
      }
      return Promise.reject(error);
    }
  );
};

export default interceptor;
