import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { axAppSRCM } from "services/base";
import { isLoginAuth } from "../../../utils/login";
import { lStorage } from "../../../utils/storage";

function LaunchDev() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const setInitAuthInfo = () => {
    if (searchParams.get("token")) {
      lStorage.set("authInfo", { srcmToken: searchParams.get("token") });
      axAppSRCM.get("/api/v2/me/").then((res) => {
        const user = {};
        if (res?.data?.results.length > 0) {
          const myInfo = res?.data?.results[0];
          user.srcmToken = searchParams.get("token");
          user.name = myInfo.name;
          user.email = myInfo.user_email;
          user.loginUser = myInfo;
          lStorage.set("authInfo", user);
        }
      });
      navigate("/mobile/events");
    } else if (isLoginAuth()) {
      navigate("/mobile/events");
    }
  };

  useEffect(() => {
    setInitAuthInfo();
  }, []);

  return (
    <div className="lds-roller-wrapper">
      <div className="lds-roller">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default LaunchDev;
