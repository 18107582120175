import CryptoJS from "crypto-js";

// constants
const $secretKey = "$AuthInfoLock$";
const $cookieSecretKey = "$CookieLock$";

// local storage
const lStorage = {
  set: (key, val) => {
    const stringfyVal = JSON.stringify(val);
    const encriptData = CryptoJS.AES.encrypt(
      stringfyVal,
      $secretKey
    ).toString();
    localStorage.setItem(key, encriptData);
  },

  get: (key) => {
    let bytes;
    let originalText;
    const val = localStorage.getItem(key);
    if (val) {
      bytes = CryptoJS.AES.decrypt(val, $secretKey);
      originalText = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(originalText);
    }
    return true;
  },

  remove: (key) => {
    localStorage.removeItem(key);
  },

  clear: (key) => {
    localStorage.clear(key);
  },
};

// session storage
const sStorage = {
  set: (key, val) => {
    sessionStorage.setItem(key, val);
  },

  get: (key) => {
    const val = sessionStorage.getItem(key);
    return JSON.parse(val);
  },

  remove: (key) => {
    sessionStorage.removeItem(key);
  },

  clear: (key) => {
    sessionStorage.clear(key);
  },
};

// cookie storage
const cookieStorage = {
  set: (key, val = "", maxAge = 3 * 365 * 24 * 60 * 60) => {
    if (key) {
      const stringfyVal = JSON.stringify(val);
      const encriptData = CryptoJS.AES.encrypt(
        stringfyVal,
        $cookieSecretKey
      ).toString();
      const date = new Date();
      date.setTime(date.getTime() + maxAge * 1000);
      const expireDate = date.toUTCString();
      window.document.cookie = `${key}=${encriptData};expires=${expireDate};path=/;samesite=strict;secure`;
    }
  },

  get: (key) => {
    if (key) {
      const cookieNamePrefix = `${key}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieStrings = decodedCookie.split(";");
      const cookieString = cookieStrings.find(
        (item) => item?.trim?.()?.indexOf?.(cookieNamePrefix) === 0
      );
      const cookieValue =
        cookieString?.trim?.()?.slice?.(cookieNamePrefix.length) ?? "";
      if (cookieValue) {
        const bytes = CryptoJS.AES.decrypt(cookieValue, $cookieSecretKey);
        const originalText = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(originalText) ?? "";
      }
    }

    return "";
  },

  check: (key) => {
    if (key) {
      const cookieNamePrefix = `${key}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieStrings = decodedCookie.split(";");
      const cookieString = cookieStrings.find(
        (item) => item?.trim?.()?.indexOf?.(cookieNamePrefix) === 0
      );
      if (cookieString) return true;
    }

    return false;
  },

  remove: (key) => {
    if (key)
      window.document.cookie = `${key}=;max-age=0;path=/;samesite=strict;secure`;
  },

  clear: () => {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieStrings = decodedCookie.split(";");
    cookieStrings.forEach((item) => {
      const cookieName = item.slice(0, item.indexOf("="))?.trim();
      if (cookieName)
        window.document.cookie = `${cookieName}=;max-age=0;path=/;samesite=strict;secure`;
    });
  },
};

export { lStorage, sStorage, cookieStorage };
