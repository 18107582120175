import React, { useState, useEffect } from "react";
import HFNLoader from "sharedComponents/lazyLoading/Loading";
import { useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { isValidPnr } from "utils/constants";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import Service from "services/selfAttendance/selfAttendance.service";
import toaster from "utils/toaster";
import { capitalize } from "lodash";

function SelfAttendance() {
  const { eventId, sessionId } = useParams();
  const service = new Service();

  const [loader, setLoader] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [sessionDetails, setSessionDetails] = useState({});
  const [activeSession, setActiveSession] = useState({});
  const [isActiveSession, setIsActiveSession] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [wrapperCls, setWrapperCls] = useState("p-col-12 p-md-12 p-lg-6 ");
  const [isInputTouched, setIsInputTouched] = useState(false);
  const [pnrData, setPnrData] = useState([]);
  const [pnrValue, setPnrValue] = useState("");
  const [selectedCards, setSelectedCards] = useState([]);

  const fetchData = async () => {
    try {
      const res = await service.getEventDetail(eventId);
      const activeSessionData = res?.data?.sessions.find(
        ({ id }) => id === sessionId
      );
      const currentDate = new Date();
      const eventStartDate = new Date(res?.data?.start_datetime);
      const eventEndDate = new Date(res?.data?.end_datetime);
      const isActive =
        currentDate >= eventStartDate && currentDate <= eventEndDate;

      setSessionDetails(res?.data);
      setIsActiveSession(isActive);
      if (isActive === false) setWrapperCls("closedEvent");
      setActiveSession(activeSessionData);
      setLoader(false);
      if (isActive) {
        setErrorMsg("");
      } else if (currentDate > eventEndDate) {
        setErrorMsg("Event has ended");
      } else if (currentDate < eventStartDate) {
        setErrorMsg("Event is not started yet");
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [eventId, sessionId]);

  useEffect(() => {
    setPnrData([]);
    setSelectedCards([]);
    setSelectAll(false);
  }, [pnrValue]);

  const handleSelectAllChange = () => {
    let checked = false;
    if (!selectAll) checked = true;

    const filteredData = pnrData?.filter((item) => !item.has_attended);

    setSelectAll(checked);
    setSelectedCards(checked ? filteredData : []);
  };

  const updateSelectAll = (checked) => {
    if (checked) {
      const allSelected = pnrData
        ?.filter((item) => !item.has_attended)
        ?.every((item) => selectedCards?.some((card) => card.id === item.id));
      setSelectAll(allSelected);
    } else {
      setSelectAll(false);
    }
  };

  const handleCheckboxChange = (item, e) => {
    setSelectedCards((prevSelected) =>
      e.checked
        ? [...prevSelected, item]
        : prevSelected.filter((card) => card.id !== item.id)
    );

    updateSelectAll(e.checked);
  };

  const formSave = async () => {
    setLoader(true);
    if (!isValidPnr(pnrValue)) {
      setLoader(false);
      toaster.error("Not a valid PNR.");
      return;
    }
    if (pnrData?.length > 0 && pnrValue) {
      const selectedCardIds = selectedCards.map((card) => card.id);

      if (selectedCardIds?.length === 0) {
        setLoader(false);
        toaster.error("Select at least one registrant");
        return;
      }

      try {
        const res = await service.postAttendance(eventId, {
          pnr: pnrValue,
          regs: selectedCardIds,
          session: sessionId,
        });

        setLoader(false);
        if (res?.data) {
          setPnrData([]);
          setPnrValue("");
          setSelectedCards([]);
          setSelectAll(false);
          toaster.success(res.data);
        } else {
          toaster.error("Unable to save attendance. Please try again.");
        }
      } catch (error) {
        setLoader(false);
        toaster.error("Unable to save attendance. Please try again.");
      }
    } else {
      setLoader(false);
      toaster.error("Not a valid PNR.");
    }
  };

  const handlePnrInputChange = (e) => {
    const inputValue = e.target.value;
    setPnrValue(inputValue);
    setIsInputTouched(true);
    setSelectedCards([]);

    if (inputValue?.trim() === "") {
      setIsInputTouched(false);
    }
  };

  const onSearchClick = async () => {
    if (!pnrValue) {
      toaster.error("Please enter a PNR");
      return;
    }

    if (!isValidPnr(pnrValue)) {
      toaster.error("Please enter a valid PNR");
      return;
    }

    setLoader(true);
    setIsInputTouched(false);

    try {
      const res = await service.getPnr(pnrValue, eventId, sessionId);
      if (res?.data) {
        setPnrData(res.data);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toaster.error(
        error?.response?.data?.detail || "Unable to fetch PNR participants"
      );
    }
  };

  const renderSessionDetails = () => (
    <>
      <h3>{capitalize(sessionDetails?.name)} - Event Attendance</h3>
      <h3>{capitalize(activeSession?.name)}</h3>
    </>
  );
  const allAttended =
    pnrData?.length > 0 && pnrData?.every((item) => item?.has_attended);
  const renderAttendanceForm = () => (
    <>
      <div
        className="p-grid p-ai-center"
        style={{
          margin: "30px 0",
        }}
      >
        <div className="p-col-12 p-md-10">
          <InputText
            value={pnrValue}
            onChange={handlePnrInputChange}
            placeholder="Enter PNR"
            style={{ width: "100%" }}
          />
        </div>

        <div className="p-col-12 p-md-2">
          <Button
            type="button"
            label="Search"
            className="p-button-primary"
            onClick={onSearchClick}
          />
        </div>
        {isInputTouched && !isValidPnr(pnrValue) && (
          <small className="p-error" style={{ paddingLeft: "10px" }}>
            Please enter a valid PNR
          </small>
        )}
      </div>
      {pnrData?.length > 0 && (
        <>
          <div className="p-grid">
            {pnrData?.map((item) => (
              <div key={item?.id} className="p-col-12 p-md-4 mt-4">
                <Card className="mt-5 p-card name_card">
                  <div
                    className="p-d-flex p-ai-center"
                    style={{ height: "100%" }}
                  >
                    <span>Name: {item?.name}</span>
                    {!item?.has_attended ? (
                      <Checkbox
                        className="p-ml-auto p-mr-2"
                        checked={selectedCards.some(
                          (card) => card.id === item.id
                        )}
                        onChange={(e) => handleCheckboxChange(item, e)}
                        disabled={item?.has_attended}
                      />
                    ) : (
                      <i
                        className="pi pi-check-square p-ml-auto p-mr-2 attendance-tooltip"
                        title="Attendance marked!"
                        style={{ color: "green" }}
                      />
                    )}
                  </div>
                </Card>
              </div>
            ))}
          </div>
          {!allAttended && (
            <div
              className="p-grid p-ai-center"
              style={{
                margin: "30px 0",
              }}
            >
              <div className="p-col-12 p-md-9 p-d-flex p-ai-center">
                <Checkbox
                  label="Select All"
                  checked={selectAll}
                  className="p-mr-2"
                  inputId="selectall"
                  onChange={handleSelectAllChange}
                />
                <div
                  onClick={handleSelectAllChange}
                  onKeyDown={() => {}}
                  style={{ cursor: "pointer" }}
                  role="presentation"
                  className="ml-2"
                >
                  Select all participants
                </div>
              </div>
              <div className="p-col-12 p-md-3 p-lg-3">
                <Button
                  type="button"
                  label="Mark Attendance"
                  className="p-button p-button-secondary"
                  onClick={formSave}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );

  return (
    <div className="app-wrapper open">
      <div className="layout">
        <div className="header">
          <div className="header-selreg">
            <div className="header-section self-reg">
              <img src="/assets/hfn-logo.svg" alt="heartfulness" />
            </div>
          </div>
        </div>
        {loader && <HFNLoader />}
        <Card style={{ height: "100%" }}>
          <div className="p-d-flex p-flex-wrap selfRegForm selfRegWeb">
            <div className={`p-col-12 p-md-12 p-lg-6 ${wrapperCls}`}>
              {sessionDetails?.name &&
                activeSession?.name &&
                renderSessionDetails()}
              {!isActiveSession && <p>{errorMsg}</p>}
              {isActiveSession && activeSession?.id && renderAttendanceForm()}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default SelfAttendance;
