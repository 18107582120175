import moment from "moment";
import { validatePhoneNumber } from "utils/abhyasiValidations";
// state
import appStore from "store";
import { APP_OPENSIDEBAR } from "store/actionTypes/app";

// utils
import { getLoginRole } from "utils/login";
import validations from "utils/validations";
import {
  MAX_FILE_SIZE,
  locationScope,
  eventSessionType,
  recurringFrequency,
  DATETIME_REG,
  GOVT_FLAG_ENABLED_SECTOR,
  sessionCoordinators,
  BOOLEAN_FORM_FIELDS,
  SECTOR_DEPENDANT_FIELDS,
  CONNECT_EVENT,
  NON_CONNECT_EVENT,
} from "utils/constants";
import i18n from "i18n";
import { upperFirst } from "lodash";
import EventService from "services/event/event.service";
// import { multiRowFormData } from "assets/data/multiRows";
// import { eventFormData } from "assets/data/eventFormData";
// import { sessionMultiRow } from "assets/data/sessionMultiRow";

export const getDateString = (
  inputDate = null,
  dateFormat = "MMM DD, YYYY"
) => {
  let date = inputDate;
  if (!(inputDate instanceof Date)) date = new Date(inputDate);
  if (!Number.isNaN(date)) {
    return moment(date).format(dateFormat);
  }
  return null;
};

export const getHomePath = () => {
  const roleName = getLoginRole();
  if (roleName.length > 0) return "/events-dashboard";
  return "/login";
};

export const toggleSideBar = () => {
  const { isSidebarOpen } = appStore.getState().appDetails;
  appStore.dispatch({ type: APP_OPENSIDEBAR, payload: !isSidebarOpen });
};

export const offSideBar = (sidebar = true) => {
  appStore.dispatch({ type: APP_OPENSIDEBAR, payload: sidebar });
};

export const lookup = (obj, key) => {
  return key.split(".").reduce((o, k) => o && o[k], obj);
};

export const getDate = (inputDate) => {
  if (inputDate instanceof Date) return inputDate;
  if (inputDate && typeof inputDate === "string") {
    const date = new Date(inputDate);
    if (!Number.isNaN(date)) return date;
  }

  return null;
};

export const getOrganizers = (rowData) => {
  const coordinatorData = rowData?.event_coordinators
    ? rowData.event_coordinators
    : rowData?.session_coordinators ?? rowData.session_coordinators;
  const nameAll = [];
  let cellValue = "-";
  if (coordinatorData && coordinatorData.length >= 1) {
    coordinatorData.map((val) => {
      const role = val.role || "";
      const active = val.active || false;
      if (role && role === "hfn_coordinator" && active)
        nameAll.push(val?.name ? val.name : "");
      return true;
    });
    cellValue = nameAll.toString();
  }
  return cellValue;
};

const getFieldProps = (fieldType, optionKeys = {}) => {
  let fType = "";
  let fProps = "";
  switch (fieldType) {
    case "Calendar":
    case "CalendarWithTime":
      fType = fieldType;
      fProps = {
        dateFormat: "d M yy",
        readOnlyInput: true,
        showIcon: true,
        showButtonBar: true,
        showOnFocus: false,
        todayButtonClassName: "btn-display-none",
      };
      break;
    case "AutoComplete":
      fType = "AutoComplete";
      fProps = {
        optionLabel: optionKeys.name ? optionKeys.name : "",
        optionValue: optionKeys.name ? optionKeys.id : "",
      };
      break;
    case "FileUpload":
      fType = "FileUpload";
      fProps = {
        multiple: true,
      };
      break;
    case "Text":
      fType = "InputText";
      fProps = {};
      break;
    default:
      fType = fieldType;
      fProps = "";
  }
  return { type: fType, primeFieldProps: fProps };
};

export const formatPhonenumber = (value) => {
  if (value) {
    const formatted = value.replace(/-|\s|\)\(/g, "");
    return formatted.replace(/[()]/g, "");
  }
  return true;
};

const getValidations = (extra) => {
  const validationObj = {};
  if (extra && extra?.others && extra?.others.length) {
    extra.others.map((item) => {
      if (item?.validation) {
        // for (const key in item?.validation) {
        Object.keys(item?.validation).map((key) => {
          if (key === "pattern") {
            const pattern = Array.isArray(item?.validation[key])
              ? item?.validation[key][0]
              : item?.validation[key];
            switch (pattern) {
              case "email":
                validationObj.pattern = validations.email;
                break;
              case "phone":
                validationObj.validate = (value, isRequired) => {
                  const phoneNumber = value?.split(" ")?.[1] || "";
                  if (isRequired || phoneNumber?.length > 0) {
                    const formattedValue = formatPhonenumber(value);
                    return validatePhoneNumber(formattedValue)
                      ? true
                      : "Invalid phone number";
                  }
                  return true;
                };
                break;
              case "name":
                validationObj.pattern = validations.name;
                break;
              case "numberText":
                validationObj.pattern = validations.numberText;
                break;
              case "pinCode":
                validationObj.pattern = validations.pinCode;
                break;
              case "whiteSpace":
                validationObj.pattern = validations.whiteSpace;
                break;
              case "freeText":
                validationObj.pattern = validations.freeText;
                break;
              case "url":
                validationObj.pattern = validations.url;
                break;
              default:
                validationObj.pattern = null;
            }
            validationObj.patternString = pattern;
          } else {
            validationObj[key] = item?.validation[key];
          }
          return true;
        });
      }
      return true;
    });
  }
  return validationObj;
};

const getFieldWrapperClass = (field) => {
  let wrapperCls = "";
  switch (field) {
    case "multifield":
      wrapperCls = "";
      break;
    case "CalendarWithTime":
      wrapperCls =
        "p-md-6 p-py-0 p-mt-1 p-mt-md-0 p-mb-1 p-col-12 p-lg-4 datetime-wrapper";
      break;
    default:
      wrapperCls = "p-md-6 p-py-0 p-mt-1 p-mt-md-0 p-mb-1 p-col-12 p-lg-4";
      break;
  }
  return wrapperCls;
};

const setValidation = (value, section, field) => {
  let isRequired = false;
  if (
    Array.isArray(section) &&
    section?.[0] &&
    section?.[0] === "client_coordinator" &&
    field
  ) {
    switch (field) {
      case "email":
        isRequired = false;
        break;
      default:
        isRequired = true;
        break;
    }
  } else if (value) isRequired = true;

  return isRequired;
};

const getSectionFields = (
  blockFields,
  sectionKey,
  isGovtSectors = [],
  hideFields = [],
  eventType = ""
) => {
  const fieldSet = [];
  if (blockFields && sectionKey)
    blockFields.map((val) => {
      const fieldName = val.identifier
        ? val.identifier
        : val?.name?.replace(/\s/g, "") || "";
      let field_type = val?.field_type || "";
      const dependentValue = val?.dependent_value || {};
      if (eventType === NON_CONNECT_EVENT) {
        if (field_type === "Calendar") field_type = "CalendarWithTime";
        if (sectionKey === "session_block" && dependentValue?.disable)
          dependentValue?.disable.map((k) => {
            return Object.keys(k).map((key, idx) => {
              if (key === "event_block-start_datetime")
                delete dependentValue?.disable[idx];
              return key;
            });
          });
      }

      const optionKeys = val?.option_keys || {};
      const availability = val?.availability || false;
      const fieldProps = getFieldProps(field_type, optionKeys);
      const validationRequired = setValidation(
        val?.mandatory,
        sectionKey,
        val?.identifier
      );
      const validationsExtra = getValidations(val);
      const formField = `${sectionKey}-${fieldName}`;
      const optionApi = val?.option_api || "";
      const noOptionsMessage = "No data found";

      if (
        availability &&
        field_type !== "config" &&
        !hideFields.includes(formField)
      ) {
        const mTop = field_type === "Checkbox" ? " mt10" : "";
        fieldSet.push({
          [formField]: {
            properties: {
              fieldWrapperClassNames: `${getFieldWrapperClass(
                field_type
              )} ${mTop}`,
              label: val.custom_label ? val.custom_label : val.name || "",
              dropdownOptions: val.selectvalues ? val.selectvalues : {},
              ...fieldProps,
              validations: {
                ...validationsExtra,
                ...{
                  required: {
                    value: validationRequired,
                    message: validationRequired
                      ? "This field is required*"
                      : "",
                  },
                },
              },
              option_api: optionApi,
              option_keys: optionKeys,
              method: optionApi,
              name: val.name ? val.name : "",
              dependent_value: dependentValue || {},
              isMultiCoordinator: val.isMultiCoordinator || false,
              abhyasiSearch: val.abhyasiSearch || false,
              checkBoxFields: val.checkBoxFields || false,
              others: val.others || [],
              formField,
              section: sectionKey,
              noOptionsMessage,
              help_text: val?.help_text || "",
              fields:
                field_type === "multifield" && val?.multi_fields?.length > 0
                  ? getSectionFields(val?.multi_fields, [val?.identifier])
                  : [],
              toggleOptions:
                isGovtSectors.length > 0 &&
                formField === "event_block-is_govt_related"
                  ? {
                      key: "event_block-sector",
                      options: isGovtSectors,
                    }
                  : "",
            },
          },
        });
      }
      return true;
    });
  return fieldSet;
};

export const formBuilder = (
  allBlocks,
  requiredBlocks,
  isGovtSectors = [],
  hideFields = [],
  eventType = CONNECT_EVENT
) => {
  const formSections = [];
  // allBlocks = eventFormData;
  if (allBlocks)
    allBlocks.map((block) => {
      const isExist = requiredBlocks
        ? requiredBlocks.find((reqBlock) =>
            reqBlock?.includes(block.identifier)
          )
        : "";
      if (isExist) {
        // if (block.identifier === "session_hfn_volunteer" && isMulti == true) {
        //   block = sessionMultiRow;
        // }

        const secOrder = requiredBlocks.indexOf(block.identifier);
        const sectionKey = block.identifier;
        formSections.push({
          section: block.name ? block.name : "",
          sectionOrder: secOrder,
          sectionFields: getSectionFields(
            block.ordered_template_block_fields,
            sectionKey,
            isGovtSectors,
            hideFields,
            eventType
          ),
        });
      }
      return true;
    });

  formSections.sort(function (a, b) {
    return a.sectionOrder - b.sectionOrder;
  });
  return formSections;
};

export const getMenuListItems = (tb, i, cb, path, navigateCallback) => {
  const menuList = [];
  if (tb && tb.length > 0 && i && i?.length > 0) {
    tb.map((item) => {
      if (i.includes(item?.identifier)) {
        menuList.push({
          label: i18n.t(
            `main.${item?.name.replaceAll(" ", "_")}`.toLowerCase()
          ),
          icon: "uil uil-plus",
          command: () => {
            if (path?.includes(item?.identifier))
              navigateCallback(item?.identifier);
            else cb(item?.identifier);
          },
        });
      }
      return true;
    });
  }
  return menuList;
};

const setCurrentTime = (date) => {
  date.setHours(new Date().getHours());
  date.setMinutes(new Date().getMinutes());
  date.setSeconds(new Date().getSeconds());
  const cData = `${date.toISOString().split(".")[0]}Z`;
  return cData;
};

export const getFormBlock = (frmData = {}, block = "", eventType = "") => {
  const data = {};
  if (!frmData || !block) return false;
  Object.keys(frmData).forEach(function (key) {
    if (key.toLowerCase().includes(block.toLowerCase())) {
      let formFields = key.split(/[-]+/).pop();
      let formValue = null;
      if (frmData[key] && typeof frmData[key] === "object") {
        if (frmData[key] instanceof Date && !frmData[key].isNaN) {
          if (eventType === CONNECT_EVENT)
            formValue = setCurrentTime(frmData[key]);
          else formValue = `${frmData[key].toISOString().split(".")[0]}Z`;
        } else if (Array.isArray(frmData[key])) {
          const parsedArray = [];
          frmData[key].map((v) => {
            parsedArray.push(v?.value || v);
            return true;
          });
          formValue = parsedArray;
        } else if (formFields === "location_name") {
          const obj = {
            name: frmData[key].name,
            id: frmData[key].id,
            latitude: frmData[key]?.latitude,
            longitude: frmData[key]?.longitude,
          };
          formValue = JSON.stringify(obj);
          data.latitude = frmData[key]?.latitude || null;
          data.longitude = frmData[key]?.longitude || null;
        } else formValue = frmData[key].value;
      } else if (typeof frmData[key] === "boolean") formValue = frmData[key];
      else formValue = frmData[key] || null;

      if (formFields === "city") {
        formFields = "city_list";
        formValue = formValue ? [formValue] : null;
      }
      if (formFields === "recurring_frequency" && !formValue)
        formValue = "one-time";
      data[formFields] = formValue;
    }
  });
  return data;
};

export const renameKeys = (obj, newKeys) => {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
};

export const getInitValue = (data, block) => {
  const initVal = {};
  if (!data) return false;
  Object.keys(data).forEach(function (key) {
    const formField = `${block}-${key}`;
    let formVal = "";
    let altKey = {};
    if (key === "city") altKey = { id: "value" };
    else altKey = { id: "value", name: "label" };

    if (data[key] && typeof data[key] === "object")
      formVal = renameKeys(data[key], altKey);
    else if (DATETIME_REG.test(data[key])) formVal = new Date(data[key]);
    else formVal = data[key];

    initVal[formField] = formVal;
  });
  return initVal;
};

export const setFormData = (initData = {}, tempBlocks = {}, block = "") => {
  const isStaticData = [
    "location_scope",
    "is_venue_online",
    "recurring_frequency",
  ];
  const staticData = {
    location_scope: locationScope,
    is_venue_online: eventSessionType,
    recurring_frequency: recurringFrequency,
  };

  let tempBlock = {};
  tempBlocks.map((val) => {
    const blockIdentifiers = val?.identifier ? val.identifier : {};
    if (blockIdentifiers === block) {
      tempBlock = val.ordered_template_block_fields;
    }
    return true;
  });

  const initVal = {};
  if (initData) {
    Object.keys(initData).forEach(function (key) {
      if (
        tempBlock.find(
          (element) =>
            element.identifier === key && element.availability === true
        )
      ) {
        const formField = `${block}-${key}`;
        let formVal = "";

        if (initData[key] && typeof initData[key] === "object")
          formVal = renameKeys(initData[key], { id: "value", name: "label" });
        else if (DATETIME_REG.test(initData[key]))
          formVal = new Date(initData[key]);
        else formVal = initData[key];

        if (
          key === "approval_websites" ||
          key === "websites" ||
          key === "types"
        ) {
          const multiObj = [];
          initData?.[key]?.map((v) => {
            return multiObj.push({
              value: v,
              label: upperFirst(v).replaceAll("_", " "),
            });
          });
          formVal = multiObj;
        }

        if (key === "location_name") {
          const obj = JSON.parse(initData[key]);
          formVal = obj;
        }

        if (key === "languages") {
          const multiObj = [];
          const langOptions = initData?.[key];
          Object.keys(langOptions).map((v) => {
            multiObj.push(
              renameKeys(langOptions[v], { id: "value", name: "label" })
            );
            return true;
          });
          formVal = multiObj;
        }

        if (key === "sender_email" && initData[key])
          formVal = { label: initData[key], value: initData[key] };

        if (isStaticData.includes(key) && initData[key] !== null) {
          const sData = staticData[key] || {};
          formVal = {
            label: sData[initData[key]] || "",
            value: initData[key].toString(),
          };
        }

        initVal[formField] = formVal;
      }
    });
  }

  return initVal;
};

export const formatIdentifier = (identifier) => {
  if (identifier) {
    return identifier.replace("session_", "");
  }
  return true;
};

export const removeUnderscore = (not_applicable) => {
  return not_applicable.replace("_", " ");
};

export const formatInVaild = (identifier) => {
  if (identifier) {
    return identifier.replace("session_", "");
  }
  return true;
};

export const checkFiles = (files, acceptedFileTypes) => {
  const returnValue = Object.keys(files).map((key) => {
    const file = files[key];
    if (file.size > MAX_FILE_SIZE || !acceptedFileTypes.includes(file.type)) {
      return true;
    }
    return false;
  });

  if (returnValue.includes(true)) {
    return true;
  }
  return false;
};

export const checkFilesType = (files, acceptedFileTypes) => {
  const returnValue = Object.keys(files).map((key) => {
    const file = files[key];
    if (!acceptedFileTypes.includes(file.type)) {
      return true;
    }
    return false;
  });

  if (returnValue.includes(true)) {
    return true;
  }
  return false;
};

export const checkFilesSize = (files) => {
  const returnValue = Object.keys(files).map((key) => {
    const file = files[key];
    if (file.size > MAX_FILE_SIZE) {
      return true;
    }
    return false;
  });

  if (returnValue.includes(true)) {
    return true;
  }
  return false;
};

const dateCheck = (fromDate, toDate, checkDate) => {
  const chkDate = DATETIME_REG.test(checkDate)
    ? new Date(checkDate)
    : checkDate;
  if (chkDate >= fromDate && chkDate <= toDate) return true;
  return false;
};

export const checkValidDate = (eventDate, sesionDate) => {
  const eventStartDate = DATETIME_REG.test(eventDate.start_datetime)
    ? new Date(eventDate.start_datetime)
    : eventDate.start_datetime || "";
  const eventEndDate = DATETIME_REG.test(eventDate.end_datetime)
    ? new Date(eventDate.end_datetime)
    : eventDate.end_datetime || "";

  if (sesionDate && eventStartDate && eventEndDate) {
    let avilableFlag = true;
    Object.keys(sesionDate).forEach((key) => {
      if (sesionDate[key]) {
        const res = dateCheck(eventStartDate, eventEndDate, sesionDate[key]);
        if (!res) avilableFlag = false;
      }
    });
    return avilableFlag;
  }
  return true;
};

export const tzToDateTime = (tzVal) => {
  return DATETIME_REG.test(tzVal) ? new Date(tzVal) : tzVal;
};

export const getCoFrmData = (data, role) => {
  const users = [];
  if (!data) return false;
  data.map((val) => {
    if (val.id)
      users.push({
        user_id: val.id,
        role,
        active: true,
      });
    return true;
  });
  return users;
};

export const getEventCoordinator = (data) => {
  const hfn_coordinator = [];
  const client_coordinator = [];
  const hfn_attendance_coordinator = [];
  if (!data) return false;
  Object.keys(data).forEach(function (key) {
    if (key.includes("event_coordinators")) {
      const coordinators = data[key];
      if (!coordinators) return false;
      coordinators.map((res, k) => {
        const role = res.role ? res.role : "";
        if (role && res.active && res.user_id) {
          const cardData = {
            name: res.name || "",
            email: res.email || "",
            mobile: res.mobile || "",
            id: res.user_id || "",
            order: k,
          };
          if (res.role === "hfn_coordinator") hfn_coordinator.push(cardData);
          if (res.role === "hfn_attendance_coordinator")
            hfn_attendance_coordinator.push(cardData);
          if (res.role === "client_coordinator")
            client_coordinator.push(cardData);
        }
        return false;
      });
    }
    return true;
  });
  const coordinators = {
    hfn_coordinator: hfn_coordinator.sort((a, b) => b.order - a.order),
    client_coordinator: client_coordinator.sort((a, b) => b.order - a.order),
    hfn_attendance_coordinator: hfn_attendance_coordinator.sort(
      (a, b) => b.order - a.order
    ),
  };
  return coordinators;
};

const getSessionUserRoles = (data, uniqRec) => {
  const roles = [];
  const resData = uniqRec;
  if (!data) return false;
  Object.keys(data).forEach(function (key) {
    const coordinators = data[key];
    if (
      coordinators &&
      coordinators.user_id === uniqRec.id &&
      coordinators?.active &&
      sessionCoordinators.includes(coordinators.role)
    ) {
      roles.push(coordinators.role);
    }
  });
  resData.hfnCo = roles;
  return resData;
};

export const getSessionCoordinator = (data) => {
  const uniqIds = [];
  const uniqRec = [];
  const uniqCo = [];
  if (!data) return false;
  Object.keys(data).forEach(function (key) {
    const coordinators = data[key];
    const role = coordinators?.role ? coordinators.role : "";

    if (coordinators && sessionCoordinators.includes(role)) {
      if (role && coordinators?.active && coordinators?.user_id) {
        const cardData = {
          name: coordinators?.name || "",
          email: coordinators?.email || "",
          mobile: coordinators?.mobile || "",
          id: coordinators?.user_id || "",
        };
        if (!uniqIds.includes(coordinators?.user_id)) {
          uniqIds.push(coordinators?.user_id);
          uniqRec.push(cardData);
        }
      }
    }
  });

  uniqRec.map((d) => {
    return uniqCo.push(getSessionUserRoles(data, d));
  });
  return uniqCo;
};

export const getActiveCo = (data) => {
  const x = [];
  data.map((res) => {
    if (res.active) x.push(res);
    return true;
  });
  return x;
};

export const getGovtSectors = (res) => {
  const isGovt = [];
  if (res) {
    res.map((val) => {
      if (GOVT_FLAG_ENABLED_SECTOR.includes(val.name)) isGovt.push(val.id);
      return true;
    });
  }
  return isGovt;
};

export const getCoordinatorId = (data, role, userId) => {
  const curUser = [];
  if (!data) return false;
  Object.keys(data).forEach(function (key) {
    const coordinators = data[key];
    if (
      coordinators &&
      coordinators.user_id === userId &&
      coordinators.role === role
    ) {
      curUser.push(coordinators.id);
    }
  });
  return curUser[0] || "";
};

export const isExistUser = (data, role, userId) => {
  const curUser = [];
  if (!data) return false;
  Object.keys(data).forEach(function (key) {
    const coordinators = data[key];
    if (
      coordinators &&
      coordinators.user_id === userId &&
      coordinators.role === role
    ) {
      curUser.push(coordinators.user_id);
    }
  });
  return curUser[0] || "";
};

export const downloadFile = async (qrLink, fileName) => {
  if (qrLink !== null && fileName !== null) {
    fetch(qrLink)
      .then((response) => response.blob())
      .then((blob) => {
        const fileURL = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }
};

export const getDataByValue = (data, val) => {
  return data.find(({ value }) => value === val) || null;
};

export const decodeParam = (sessionName) => {
  return sessionName ? sessionName.replaceAll("-", " ") : "";
};

export const encodeParam = (sessionName) => {
  return sessionName ? sessionName.replaceAll(" ", "-") : "";
};

export const dateFormat = (inputDate, format) => {
  let date = "";
  if (inputDate && typeof inputDate === "string") {
    const inputDateTZ = inputDate.includes(" ")
      ? inputDate.replace(" ", "T")
      : inputDate;
    date = new Date(Date.parse(inputDateTZ));
  } else date = inputDate || "";
  let fomattedDate = "";
  if (date) {
    // Only IF date is valid
    const month = date.getMonth() + 1;
    const d = date.getDate().toString().padStart(2, "0");
    const m = month.toString().padStart(2, "0");
    const y = date.getFullYear();

    switch (format) {
      case "YYYY-MM-DD":
        fomattedDate = `${y}-${m}-${d}`;
        break;
      case "MMM DD, YYYY":
        fomattedDate = `${date.toLocaleString("en-us", {
          month: "short",
        })} ${d}, ${y}`;
        break;
      default:
        fomattedDate = `${y}-${m}-${d}`;
    }
  } else {
    fomattedDate = "";
  }
  return fomattedDate;
};

export const convertDateToUTCString = (inputDate) => {
  return `${inputDate.toISOString().split(".")[0]}Z`;
};

export const removeFields = (fieldSet, removeFieldsArray, temp_block) => {
  const finalData = fieldSet.filter((data) => {
    const nData = data;
    let tempBlocks = [];
    if (nData?.identifier === temp_block) {
      tempBlocks = nData?.ordered_template_block_fields.filter((data1) => {
        if (removeFieldsArray.includes(data1.identifier)) {
          return null;
        }
        return data1;
      });
    } else tempBlocks = nData?.ordered_template_block_fields;
    nData.ordered_template_block_fields = tempBlocks;
    return nData;
  });
  return finalData;
};

export const setBooleanForNull = (formFields) => {
  const data = {};
  Object.keys(formFields).map((key) => {
    const fieldsName = key.split(/[-]+/).pop();
    if (BOOLEAN_FORM_FIELDS.includes(fieldsName) && formFields[key] === null)
      data[key] = false;
    else if (
      (SECTOR_DEPENDANT_FIELDS.includes(fieldsName) &&
        typeof formFields[key] !== "object") ||
      formFields[key] === "00:00:00" ||
      formFields[key] === "00:00"
    )
      data[key] = null;
    else data[key] = formFields[key];
    return data;
  });
  return data;
};

export const setDateWithHour = (formFields, dateFieldsSet = []) => {
  const data = {};
  Object.keys(formFields).map((key) => {
    if (dateFieldsSet.includes(key)) {
      if (formFields?.[key] && formFields?.[`${key}-time`]) {
        const hourmin = formFields?.[`${key}-time`].split(":");
        const datetime = new Date(formFields?.[key]).setHours(
          hourmin[0],
          hourmin[1],
          0,
          0
        );
        data[key] = new Date(datetime);
      } else {
        const datetime = new Date(formFields?.[key]);
        data[key] = formFields?.[key] ? new Date(datetime) : null;
      }
    } else data[key] = formFields[key];
    return data;
  });
  return data;
};

export const isValidDate = (startDate, endDate) => {
  if (endDate <= startDate) return false;
  return true;
};

export const processFormData = (frmData = {}) => {
  const data = {};
  Object.keys(frmData).map((key) => {
    let formValue = null;
    if (frmData[key] && typeof frmData[key] === "object") {
      if (frmData[key] instanceof Date && !frmData[key].isNaN) {
        formValue = `${frmData[key].toISOString().split(".")[0]}Z`;
      } else formValue = frmData[key].value;
    } else if (typeof frmData[key] === "boolean") formValue = frmData[key];
    else formValue = frmData[key] || null;

    data[key] = formValue;
    return data;
  });
  return data;
};

export const dateToTZString = (date) => {
  return date ? `${date.toISOString().split(".")[0]}Z` : ``;
};

export const getIds = (selectedData, type) => {
  const ids = selectedData?.map((data) => data[type]) || [];
  return ids;
};

export const getRangeDates = (rangeDates) => {
  let dates = [];
  if (rangeDates?.length > 0) {
    dates = rangeDates?.map((d) => dateFormat(d, "YYYY-MM-DD"));
  }
  return dates;
};

export const clearCaches = async () => {
  const service = new EventService();
  service.clearCacheEvent();
  service.clearCacheSession();
};
