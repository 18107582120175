import { signOut } from "@heartfulnessinstitute/react-hfn-profile";
import appStore from "store";
import { APP_FIREBASEUSER } from "store/actionTypes/app";
import { RESET, LOGOUT, UPDATE_INFO } from "store/actionTypes/login";
import { lStorage } from "utils/storage";

export const getLoginID = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails?.loginUser?.id || null;
};

export const getLoginName = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails?.loginUser?.name || "";
};

export const getLoginRole = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails?.loginUser?.user_roles || [];
};

export const getLoginUserPositionCode = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails?.loginUser?.positionCode || "";
};

export const getLoginUserRole = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails?.loginUser || {};
};

export const getLoginUser = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails?.loginUser || {};
};

export const setLoginUser = (payload) => {
  lStorage.set("authInfo", payload);
};

export const getLoginLocaluserData = (authKey = "authInfo") => {
  return lStorage.get(authKey);
};

export const isLoginAuth = (authKey = "authInfo") => {
  const loginDetails = lStorage.get(authKey);
  return !!loginDetails?.srcmToken;
};

export const resetState = () => {
  appStore.dispatch({ type: RESET });
};

export const logout = (showExpired = true) => {
  lStorage.clear();
  signOut();
  resetState();
  if (showExpired) appStore.dispatch({ type: LOGOUT });
};

export const setFirebaseUser = (payload) => {
  appStore.dispatch({ type: APP_FIREBASEUSER, payload });
};

export const getFirebaseUser = () => {
  return appStore.getState().appDetails.firebaseUser;
};

export const setLoginData = (payload) =>
  appStore.dispatch({ type: UPDATE_INFO, payload });

export const setFirebaseToken = (srcmToken) => {
  const loginDetails = lStorage.get("authInfo");
  if (loginDetails?.srcmToken && loginDetails.srcmToken !== srcmToken) {
    lStorage.set("authInfo", { ...loginDetails, srcmToken });
    setLoginData({ ...loginDetails, srcmToken });
  }
};

export const checkUserDataAdmin = () => {
  const userRoles = getLoginRole();
  if (userRoles.includes("uep_data_admin")) {
    return true;
  }
  return false;
};
