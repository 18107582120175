const userPageAccess = {
  route: [
    {
      path: "/",
      roles: [],
    },
    {
      path: "events-dashboard",
      roles: [],
    },
    {
      path: "satsang-event",
      roles: ["abhyasi", "prefect"],
    },
    {
      path: "events",
      roles: [],
    },

    {
      path: "report-events",
      roles: [],
    },
    {
      path: "pre-reg-event",
      roles: [
        "abhyasi",
        "preceptor",
        "prefect",
        "uep_super_admin",
        "uep_data_admin",
      ],
    },
    {
      path: "report",
      roles: [],
    },
    {
      path: "email-templates",
      roles: [],
    },

    {
      path: "events-template",
      roles: ["uep_templates_admin", "uep_super_admin"],
    },

    {
      path: "events-approvals",
      roles: [],
    },
    {
      path: "master-data",
      roles: ["connect_admin", "uep_super_admin", "uep_admin"],
    },
    {
      path: "roles",
      roles: ["uep_admin", "uep_super_admin"],
    },
    {
      path: "assign-role",
      roles: ["uep_admin", "uep_super_admin"],
    },
  ],
};
export default userPageAccess;
