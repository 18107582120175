import React, { useState, useMemo, useCallback } from "react";
import HFNLoader from "sharedComponents/lazyLoading/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import validations from "utils/validations";
import HFNDynamicForm from "sharedComponents/hfnForm";
import Service from "services/selfRegistration/selfReg.service";
import toaster from "utils/toaster";

function SelfRegistration() {
  const service = useMemo(() => new Service(), []);
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const selfRegFormFields = {
    regularParticipantsFormList: [
      {
        section: "",
        sectionDesc: "",
        sectionFields: [
          {
            unique_code: {
              properties: {
                fieldWrapperClassNames:
                  "p-md-6 p-py-0 p-mt-3 p-mt-md-3 p-mb-1 p-lg-12",
                type: "InputText",
                label: "Unique Code",
                primeFieldProps: {},
                validations: {
                  required: validations.required,
                  pattern: validations.uniqueCode,
                },
              },
            },
          },
        ],
      },
    ],
  };

  const formButtonGroup = useCallback(
    () => (
      <div className="p-text-right">
        <Button
          type="submit"
          label="Search"
          className="p-button p-button-primary p-mr-2"
        />
      </div>
    ),
    []
  );

  const formOnsubmit = async (data) => {
    setLoader(true);
    if (data?.unique_code) {
      const res = await service
        .getSessionDetails({
          unique_code: data.unique_code,
        })
        .catch(() => {
          setLoader(false);
          toaster.error("Enter valid unique code");
        });

      if (res?.data?.event && res?.data?.session) {
        setLoader(false);

        const eventId = res.data.event;
        const sessionId = res.data.session;

        if (location.pathname === "/global-event-registration-non-connect") {
          navigate(`/non-connect-self-attendance/${eventId}/${sessionId}`);
        } else {
          navigate(`/self-registration/${eventId}/${sessionId}`);
        }
      }
    }
  };

  return (
    <div className="app-wrapper open">
      <div className="layout">
        <div className="header">
          <div className="header-selreg">
            <div className="header-section self-reg">
              <img src="/assets/hfn-logo.svg" alt="heartfulness" />
            </div>
          </div>
        </div>
        {loader === true ? <HFNLoader /> : null}

        <Card>
          <div className="p-d-flex p-flex-wrap selfRegForm">
            <div className="p-col-12 p-md-12 p-lg-6">
              <h3>Event Attendance</h3>
              <div className="regForm">
                <HFNDynamicForm
                  sections={selfRegFormFields}
                  submitButtonGroup={formButtonGroup}
                  onFormSubmit={formOnsubmit}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
export default SelfRegistration;
