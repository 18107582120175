import React from "react";

function Maintenance() {
  return (
    <div className="maintenance-mode p-d-flex p-flex-wrap">
      <div className="p-field-wrapper p-md-6 p-lg-6 p-sm-12 left-content">
        <img src="/assets/hfn-logo.svg" width="240px" alt="heartfulness" />
        <h1>Site is temporarily unavailable.</h1>
        <p>
          Scheduled maintenance is currently in progress. Please check back in
          sometime.
        </p>
        <p>We apologize for the inconvenience caused.</p>
      </div>
      <div className="p-md-6 p-lg-6 p-sm-12 right-content">
        <img src="/assets/maintanance.png" alt="maintanance" />
      </div>
    </div>
  );
}

export default Maintenance;
