import { axApp, ax, axOms, axAppSRCM, axAppLoc } from "services/base";
import { dateFormat } from "utils/common";
import i18n from "i18n";
import {
  FREE_ACCOMMODATION_IN_KANHA,
  PAYMENT_INTL,
  WAITINGLIST_STATUS,
} from "utils/constants";

class EventService {
  getEventList = () => {
    return ax.get("/events/").then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.results,
      },
    }));
  };

  getMyEventApprovalRequest = (payload, params) => {
    const { sortField, sortOrder } = payload.lazyEvent;
    const params1 = params.replace("name=", "search=");

    const ordering =
      sortField && sortOrder
        ? `${sortOrder === -1 ? "-" : ""}${sortField}`
        : "-start_datetime";

    return ax
      .get(
        `my-approval-events/${params1}&reporting_only=false${
          ordering ? `&ordering=${ordering}` : ""
        }`
      )
      .then((res) => ({
        data: {
          count: res.data.count || 0,
          results: res.data.results,
        },
      }));
  };

  getMyEventListingApproval = (payload, params) => {
    const params1 = params.replace("name=", "search=");
    const reporting_only = payload?.lazyEvent?.reporting_only;
    const { sortField, sortOrder } = payload.lazyEvent;
    const ordering =
      sortField && sortOrder
        ? `${sortOrder === -1 ? "-" : ""}${sortField}`
        : "-start_datetime";

    return ax
      .get(
        `my-listing-approval-events/${params1}&reporting_only=${reporting_only}${
          ordering ? `&ordering=${ordering}` : ""
        }`
      )
      .then((res) => ({
        data: {
          count: res.data.count || 0,
          results: res.data.results,
        },
      }));
  };

  getMobileEventList = (selectedOrg, searchValue, pageSize = 5, page = 1) => {
    let params = "";
    if (searchValue) {
      params += `&search=${searchValue}`;
    }
    if (selectedOrg) {
      params += `&organization=${selectedOrg}`;
    }
    return ax
      .get(
        `/events/?reporting_only=true${params}&page_size=${pageSize}&page=${page}`
      )
      .then((res) => ({
        data: {
          count: res.data.count || 0,
          results: res.data.results,
        },
      }));
  };

  getOrganizationList = () => {
    return ax.get("/events/organizations/").then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data,
      },
    }));
  };

  getStats = () => {
    return ax.get("/events/stats/?reporting_only=true").then((res) => ({
      data: res.data,
    }));
  };

  getRegistrantStats = (eventId, sessionID) => {
    return ax
      .get(`/events/${eventId}/sessions/${sessionID}/stats/`)
      .then((res) => ({
        data: res.data,
      }));
  };

  getRecentActivityList = () => {
    return ax.get("/recent-activity/?page_size=10").then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.results,
      },
    }));
  };

  getConnectEventList = (payload, params) => {
    const params1 = params.replace("name=", "search=");
    return ax.get(`/events/${params1}&reporting_only=true`).then((res) => ({
      data: res.data,
    }));
  };

  getPreRegisterEventList = (payload, params) => {
    const params1 = params.replace("name=", "search=");
    return ax.get(`/events/${params1}&reporting_only=false`).then((res) => ({
      data: res.data,
    }));
  };

  getMobileConnectEventList = (payload, params) => {
    const search = payload?.lazyEvent?.search
      ? `&search=${payload?.lazyEvent?.search}`
      : "&search=";
    return ax
      .get(`/events/${params}&reporting_only=true${search}`)
      .then((res) => ({
        data: res.data,
      }));
  };

  getEventTempBlock = (tempId) => {
    return ax.get(`template-blocks/?template=${tempId}`).then((res) => ({
      data: {
        count: res.data.length || 0,
        results: res.data.results,
      },
    }));
  };

  addEvent = (payload) => {
    return ax.post("events/", payload).then((res) => ({
      data: res.data,
    }));
  };

  approveEvent = (eventId, payload) => {
    return axAppSRCM
      .post(`/api/v2/me/approvals/${eventId}/approve/`, payload)
      .then((res) => ({
        data: res.data,
      }));
  };

  rejectEvent = (eventId, payload) => {
    return axAppSRCM
      .post(`/api/v2/me/approvals/${eventId}/reject/`, payload)
      .then((res) => ({
        data: res.data,
      }));
  };

  approveAndRejectListingEvent = (payload) => {
    return axAppSRCM
      .post(`/uep/events/review-listing-approval/`, payload)
      .then((res) => ({
        data: res.data,
      }));
  };

  getEventDetail = (eventId, isPreRegEventApproval = false) => {
    const apiUrl = isPreRegEventApproval
      ? `my-approval-events/${eventId}/`
      : `events/${eventId}/`;
    return ax.get(apiUrl).then((res) => ({
      data: res.data,
    }));
  };

  getEventDetailsWithSession = (eventId) => {
    return ax.get(`my-approval-events/${eventId}/`).then((res) => ({
      data: res.data,
    }));
  };

  getEventDetailsWithEventListingSession = (eventId) => {
    return ax.get(`my-listing-approval-events/${eventId}/`).then((res) => ({
      data: res.data,
    }));
  };

  getEventCreate1 = () => {
    return axApp.get("/data/dynamicFormFields.json").then((res) => res.data);
  };

  getSessionList = ({
    lazyEvent: {
      eventId,
      page,
      rows,
      filters: { name, city, start_datetime },
    },
  }) => {
    const payloadData = {
      page,
      page_size: rows,
    };
    if (name?.value) payloadData.search = name?.value;
    if (city?.value) payloadData.city = city?.value?.id;
    if (start_datetime?.value)
      payloadData.start_datetime__date = dateFormat(
        start_datetime?.value,
        "YYYY-MM-DD"
      );

    return ax
      .get(`/events/${eventId}/sessions/?ordering=session_no`, {
        params: payloadData,
      })
      .then((res) => {
        return {
          data: {
            count: res.data.count || 0,
            results: res.data.results,
          },
        };
      });
  };

  getSessions = (eventId) => {
    return ax.get(`/events/${eventId}/sessions/`).then((res) => {
      return {
        data: {
          count: res.data.count || 0,
          results: res.data.results,
        },
      };
    });
  };

  getHfnCoordinatorsList = (payload, params) => {
    let qParam = "";
    qParam = payload?.lazyEvent?.session
      ? `&session=${payload?.lazyEvent?.session}`
      : "";
    const role = payload?.lazyEvent?.role
      ? `&role = ${payload?.lazyEvent?.role}`
      : "";
    return ax
      .get(
        `/events/${payload?.lazyEvent?.event}/coordinators/${params}${qParam}${role}&active=true`
      )
      .then((res) => {
        return {
          data: {
            count: res.data.count || 0,
            results: res.data.results,
          },
        };
      });
  };

  getSessionTestimonialsList = (payload, params) => {
    return ax
      .get(
        `/events/${payload?.lazyEvent?.event}/testimonials/${params}&active=true&session=${payload?.lazyEvent?.session}`
      )
      .then((res) => {
        return {
          data: {
            count: res.data.count || 0,
            results: res.data.results,
          },
        };
      });
  };

  getEventLevelSessionAttachments = (payload) => {
    return ax
      .get(
        `/events/${payload}/session-attachments/?active=true&page=1&page_size=200`
      )
      .then((res) => ({
        data: {
          count: res.data.count || 0,
          results: res.data.results,
        },
      }));
  };

  getEventLevelSessionTestimonial = (payload) => {
    return ax
      .get(`/events/${payload}/testimonials/?active=true&page=1&page_size=200`)
      .then((res) => {
        return {
          data: {
            count: res.data.count || 0,
            results: res.data.results,
          },
        };
      });
  };

  getSessionAttachment = (payload, params) => {
    if (payload?.lazyEvent?.event && payload?.lazyEvent?.session)
      return ax
        .get(
          `/events/${payload.lazyEvent.event}/session-attachments/${
            params ? `${params}&` : "?"
          }active=true&session=${payload?.lazyEvent?.session}`
        )
        .then((res) => {
          return {
            data: {
              count: res.data.count || 0,
              results: res.data.results,
            },
          };
        });
    return {};
  };

  addSessionAttachment = (payload, eventId) => {
    if (eventId) {
      return ax
        .post(`/events/${eventId}/session-attachments/`, payload)
        .then((res) => ({
          data: res.data,
        }));
    }
    return null;
  };

  uploadEventThumbnail = (payload) => {
    return ax.post(`/event-attachments/`, payload).then((res) => ({
      data: res.data,
    }));
  };

  updateEventThumbnail = (payload, id) => {
    return ax.put(`/event-attachments/${id}/`, payload).then((res) => ({
      data: res.data,
    }));
  };

  patchSessionAttachment = (id, payload, eventId) => {
    return ax.patch(`/events/${eventId}/session-attachments/${id}/`, payload);
  };

  patchSessionTestimonialAttachment = (id, payload, eventId) => {
    return ax.patch(
      `/events/${eventId}/testimonial-attachments/${id}/`,
      payload
    );
  };

  addSessionTestimonial = (payload) => {
    return ax
      .post(`/events/${payload.event}/testimonials/`, payload)
      .then((res) => ({
        data: res.data,
      }));
  };

  getSessionHFNList = () => {
    return axApp.get("/data/sessionHFNData.json").then((res) => ({
      data: {
        count: res.data.sessionHfnData.length || 0,
        results: res.data.sessionHfnData,
      },
    }));
  };

  createSession = (payload, eventId, cb = () => {}) => {
    return ax.post(`events/${eventId}/sessions/`, payload).then((res) => {
      if (res?.data?.id) {
        cb({
          status: true,
          message: "Session Created Successfully",
          id: res?.data?.id,
          eventId,
        });
      }
    });
  };

  updateSession = (payload, eventId, sessionId, cb = () => {}) => {
    return ax
      .patch(`events/${eventId}/sessions/${sessionId}/`, payload)
      .then((res) => {
        if (res?.data?.id) {
          cb({
            status: true,
            message: "Session Updated Successfully",
            id: sessionId,
            eventId,
          });
        } else {
          cb({ status: false, message: "Unable to updated Session" });
        }
      });
  };

  updateSessionInfo = (payload, eventId, sessionId) => {
    return ax
      .patch(`events/${eventId}/sessions/${sessionId}/`, payload)
      .then((res) => {
        return { data: res?.data };
      });
  };

  updateEvent = (payload, eventId, isPreRegEventApproval = false) => {
    const apiUrl = isPreRegEventApproval
      ? `my-approval-events/${eventId}/`
      : `events/${eventId}/`;
    return ax.patch(apiUrl, payload).then((res) => ({
      data: res.data,
    }));
  };

  getSessionTemplateBlock = (blockId) => {
    return ax.get(`template-blocks/${blockId}/`).then((res) => ({
      data: {
        results: res.data,
      },
    }));
  };

  getSessionTestimonialList = () => {
    return axApp.get("/data/sessionTestimonial.json").then((res) => ({
      data: {
        count: res.data.sessionTestimonialData.length || 0,
        results: res.data.sessionTestimonialData,
      },
    }));
  };

  getAccommodationsList = () => {
    return axApp.get("/data/accommodationData.json").then((res) => ({
      data: {
        count: res.data.accommodationData.length || 0,
        results: res.data.accommodationData,
      },
    }));
  };

  getRegistrationList = ({ lazyEvent: { eventId, page, rows } }, params) => {
    const payloadData = {
      page,
      page_size: rows,
    };
    return ax
      .get(`events/${eventId}/registrations/${params}`, {
        params: payloadData,
      })
      .then((res) => ({
        data: {
          count: res.data.count || 0,
          results: res.data.results,
        },
      }));
  };

  postAllUep = (payload, eventId) => {
    return ax.post(`events/${eventId}/coordinators/`, payload).then((res) => ({
      data: res.data,
    }));
  };

  addTestimonialAttachment = (eventId, payload) => {
    return ax.post(`/events/${eventId}/testimonial-attachments/`, payload);
  };

  patchCoordinators = (id, payload, eventId, sessionId) => {
    if (id && eventId) {
      let patchUrl = "";
      patchUrl = `events/${eventId}/coordinators/${id}/`;
      if (sessionId)
        patchUrl = `events/${eventId}/coordinators/${id}/?session=${sessionId}`;
      return ax.patch(patchUrl, payload);
    }
    return null;
  };

  getSessionDetails = (eventId, sessionId) => {
    return ax.get(`/events/${eventId}/sessions/${sessionId}/`).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data,
      },
    }));
  };

  patchTestimonial = (id, payload, eventId) => {
    return ax.patch(`/events/${eventId}/testimonials/${id}/`, payload);
  };

  patchParticipants = (id, payload, eventId) => {
    return ax.patch(`/events/${eventId}/attendance/${id}/`, payload);
  };

  patchRegistrants = (id, payload, eventId) => {
    return ax.patch(`/events/${eventId}/registrations/${id}/`, payload);
  };

  addPhoto = (payload, eventId) => {
    return ax
      .post(`/events/${eventId}/attendance/update-photo/`, payload)
      .then((res) => ({
        data: res.data,
      }));
  };

  sessionBulkAttendance = (eventId, payload) => {
    return ax.post(`/events/${eventId}/attendance/bulk-upload/`, payload);
  };

  getEventLevelRegularParticipants = (payload, params) => {
    return ax
      .get(
        `/events/${payload?.lazyEvent?.eventId}/attendance/${params}&regular_participant=yes`
      )
      .then((res) => ({
        data: {
          count: res.data.count || 0,
          results: res.data.results,
        },
      }));
  };

  getSessionParticipants = (payload, params) => {
    const params1 = params.replace("name=", "search=");
    const isRegistration = false;
    return ax
      .get(
        `/events/${payload?.lazyEvent?.event}/attendance/${params1}&session=${payload?.lazyEvent?.session}&is_registration=${isRegistration}`
      )
      .then((res) => {
        return {
          data: {
            count: res.data.count || 0,
            results: res.data.results,
          },
        };
      });
  };

  getSessionRegistrations = (payload, params) => {
    const params1 = params.replace("name=", "search=");
    const isRegistration = true;
    return ax
      .get(
        `/events/${payload?.lazyEvent?.event}/attendance/${params1}&session=${payload?.lazyEvent?.session}&is_registration=${isRegistration}`
      )
      .then((res) => {
        return {
          data: {
            count: res.data.count || 0,
            results: res.data.results,
          },
        };
      });
  };

  postRegularParticipantsList = (payload, eventId) => {
    return ax.post(`/events/${eventId}/attendance/`, payload).then((res) => ({
      data: res.data,
    }));
  };

  getSKUList = ({ lazyEvent: { sessionId } }) => {
    return axOms.get(`sku-group/${sessionId}/`).then((res) => {
      return {
        data: { results: res.data.sku },
      };
    });
  };

  getPNR = (pnr) => {
    return ax.get(`/masked-pnrs/?pnr=${pnr}`).then((res) => {
      return {
        data: { results: res.data },
      };
    });
  };

  confirmPNR = (pnr) => {
    const payload = { status: "confirmed" };
    return ax.patch(`pnrs/${pnr}/`, payload).then((res) => {
      return {
        data: res.data,
      };
    });
  };

  getAllSKU = (sessionId) => {
    return axOms.get(`sku-group/${sessionId}/`).then((res) => {
      return {
        data: { results: res.data.sku },
      };
    });
  };

  getHeaderConfig = (sessionId) => {
    return axOms.get(`sku-group-config-get/${sessionId}/`).then((res) => {
      return {
        data: res.data,
      };
    });
  };

  postHeaderConfig = (payload, cb) => {
    return axOms.put(`sku-group-config/`, payload).then((res) => {
      if (res.data) {
        cb({
          status: true,
          message: i18n.t(
            "main.header_configuration_has_been_saved_successfully"
          ),
        });
      } else {
        cb({
          status: false,
          message: i18n.t("main.unable_to_add_header_configuration"),
        });
      }
      return {
        data: res.data,
      };
    });
  };

  postSKU = (payload, dormCallBack) => {
    return axOms.post(`sku-group/`, payload).then((res) => {
      if (res?.data?.sku[0]?.id) {
        dormCallBack({
          status: true,
          message: i18n.t("main.dorm_has_been_added_successfully"),
        });
      } else {
        dormCallBack({
          status: false,
          message: i18n.t("main.unable_to_add_dorm"),
        });
      }
      return {
        data: res.data,
      };
    });
  };

  updateSKU = (payload, dormCallBack) => {
    return axOms.put(`sku-group/`, payload).then((res) => {
      if (res?.data?.id) {
        dormCallBack({
          status: true,
          message: i18n.t("main.dorm_has_been_updated_successfully"),
        });
      } else {
        dormCallBack({
          status: false,
          message: i18n.t("main.unable_to_update_dorm"),
        });
      }
      return {
        data: res.data,
      };
    });
  };

  approveRequest = (payload) => {
    return ax.post("events/submit-for-approval/", payload).then((res) => ({
      data: res.data,
    }));
  };

  getEventConfigurations = (eventId, type, sessionId) => {
    let url = "";
    if (type)
      url = `/events/${eventId}/configurations/?session=${sessionId}&type=${type}&active=true`;
    else
      url = `/events/${eventId}/configurations/?session=${sessionId}&active=true`;
    return ax.get(`${url}&page=1&page_size=200`).then((res) => {
      return {
        data: {
          count: res.data.count || 0,
          results: res.data.results,
        },
      };
    });
  };

  postEventConfigurations = (eventId, payload) => {
    return ax
      .post(`/events/${eventId}/configurations/`, payload)
      .then((res) => {
        return {
          data: {
            results: res.data,
          },
        };
      });
  };

  putEventConfigurations = (eventId, payload, id) => {
    return ax
      .put(`/events/${eventId}/configurations/${id}/`, payload)
      .then((res) => {
        return {
          data: {
            results: res.data,
          },
        };
      });
  };

  getConfigurations = (payload) => {
    const sessionParam = payload?.lazyEvent?.session
      ? `&session=${payload?.lazyEvent?.session}`
      : ``;
    return ax
      .get(
        `/events/${payload?.lazyEvent?.event}/configurations/?type=${payload?.lazyEvent?.type}&active=true${sessionParam}&page=1&page_size=200`
      )
      .then((res) => {
        const list = [];
        if (res?.data?.results) {
          res?.data?.results.map((item) => {
            Object.assign(item, { ...item, value: JSON.parse(item.value) });
            Object.assign(item.value, { ...item.value, id: item.id });
            list.push(item.value);
            return true;
          });
        }
        return {
          data: {
            count: list.length || 0,
            results: list,
          },
        };
      });
  };

  patchEventConfigurationsById = (eventId, id, payload) => {
    return ax
      .patch(`/events/${eventId}/configurations/${id}/`, payload)
      .then((res) => {
        return {
          data: res.data,
        };
      });
  };

  getAllPaymentSKUList = ({ lazyEvent: { sessionId } }) => {
    return axOms.get(`sku-group/payment-${sessionId}/`).then((res) => {
      return {
        data: { results: res.data.sku },
      };
    });
  };

  getAllPaymentSKU = (sessionId) => {
    return axOms.get(`sku-group/payment-${sessionId}/`).then((res) => {
      return {
        data: { results: res.data.sku },
      };
    });
  };

  getPaymentHeaderConfig = (sessionId, type) => {
    const apiUrl =
      type === PAYMENT_INTL
        ? `payment-${sessionId}-${type}`
        : `payment-${sessionId}`;
    return axOms.get(`sku-group-config-get/${apiUrl}`).then((res) => {
      return {
        data: res.data,
      };
    });
  };

  postPaymentSKU = (payload, dormCallBack) => {
    return axOms.post(`sku-group/`, payload).then((res) => {
      if (res?.data?.sku[0]?.id) {
        dormCallBack({
          status: true,
          message: i18n.t("main.SKU_has_been_added_successfully"),
        });
      } else {
        dormCallBack({
          status: false,
          message: i18n.t("main.unable_to_add_SKU"),
        });
      }
      return {
        data: res.data,
      };
    });
  };

  updatePaymentSKU = (payload, dormCallBack) => {
    return axOms.put(`sku-group/`, payload).then((res) => {
      if (res?.data?.id) {
        dormCallBack({
          status: true,
          message: i18n.t("main.SKU_has_been_updated_successfully"),
        });
      } else {
        dormCallBack({
          status: false,
          message: i18n.t("main.unable_to_update_SKU"),
        });
      }
      return {
        data: res.data,
      };
    });
  };

  getregLimitConfigurations = ({
    lazyEvent: { event, session, type, page, rows },
  }) => {
    const payloadData = {
      page,
      page_size: rows,
    };
    return ax
      .get(
        `/events/${event}/configurations/?session=${session}&type=${type}&active=true`,
        {
          params: payloadData,
        }
      )
      .then((res) => {
        const list = [];
        if (res?.data?.results) {
          res?.data?.results.map((item) => {
            Object.assign(item, { ...item, value: JSON.parse(item.value) });

            const { condition, limit } = item.value;
            const { stay_preference, status } = condition;
            if (
              (stay_preference === FREE_ACCOMMODATION_IN_KANHA &&
                status !== WAITINGLIST_STATUS) ||
              stay_preference !== FREE_ACCOMMODATION_IN_KANHA
            ) {
              const obj = {
                stay_preference,
                status,
                limit,
                batch: item?.value?.multiBatch || "-",
                id: item.id,
              };
              delete obj.condition;
              list.push(obj);
            }
            return true;
          });
        }
        return {
          data: {
            count: res.data.count || 0,
            results: list,
          },
        };
      });
  };

  getActiveSKUList = ({ lazyEvent: { sessionId, batch } }) => {
    return axOms.get(`sku-group/${sessionId}/`).then((res) => {
      let list;
      if (res?.data?.sku) {
        const batchValue = batch ? batch.value : null;
        if (batchValue) {
          list = res.data.sku?.filter(
            (sku) =>
              sku.type === "SIMPLE_SKU" &&
              sku.enabled === true &&
              sku.code.startsWith(batchValue.replace("-", "_"))
          );
        } else {
          list = res.data.sku?.filter(
            (sku) => sku.type === "SIMPLE_SKU" && sku.enabled === true
          );
        }
      }
      return {
        data: { results: list },
      };
    });
  };

  getOrderByPnr = (pnr) => {
    return axOms.get(`/order-by-pnr/${pnr}/`).then((res) => {
      return {
        data: res?.data,
      };
    });
  };

  postOrderIdForApproval = (id) => {
    const payload = { orderId: Number(id) };
    return axOms
      .post(`/admin-confirmation-email-notification/`, payload)
      .then((res) => {
        return {
          data: res,
        };
      });
  };

  postOrderIdForRejection = (payload) => {
    return axOms.post(`/admin-order-override-cancel/`, payload).then((res) => {
      return {
        data: res,
      };
    });
  };

  getPnrList = ({ lazyEvent: { eventId, page, rows } }, params) => {
    const payloadData = {
      page,
      page_size: rows,
    };
    return ax
      .get(`events/${eventId}/pnrs/${params}`, {
        params: payloadData,
      })
      .then((res) => {
        const list = (res.data.results || [])?.map((pnr) => {
          if (pnr?.registrations?.length > 0 && pnr?.registrations) {
            const somePending = pnr?.registrations?.some(
              (participant) => participant.status === "pending"
            );
            if (somePending) return { ...pnr, registration: "pending" };
          }
          return { ...pnr, registration: "processed" };
        });
        return {
          data: {
            count: res.data.count || 0,
            results: list,
          },
        };
      });
  };

  RejectListingEvent = (payload, eventId) => {
    return axAppSRCM
      .post(`/uep/events/${eventId}/modify-listing/`, payload)
      .then((res) => ({
        data: res.data,
      }));
  };

  getEventDetails = (eventId) => {
    return axAppSRCM.get(`/uep/events/${eventId}/`).then((res) => ({
      data: res.data,
    }));
  };

  fetchMeditationCenters = (payload) => {
    return axAppLoc
      .get(
        `centers?latitude=${payload?.latitude}&longitude=${payload?.longitude}&distance=25&limit=200&page=0&distanceUnit=KM`
      )
      .then((res) => ({
        data: res.data,
      }));
  };

  fetchUserMeditationCenters = () => {
    return axAppSRCM
      .get(`/api/v2/meditation-centers/my/?page=1&page_size=20&state=approved`)
      .then((res) => ({
        data: res.data,
      }));
  };

  getCityDetails = (payload, cityId) => {
    return axAppSRCM.get(`/api/v2/cities/${cityId}/`).then((res) => ({
      data: res.data,
    }));
  };

  drillDownDetails = (url) => {
    return axAppSRCM.get(url).then((res) => ({
      data: res.data,
    }));
  };

  downloadParticipant = (url) => {
    return axAppSRCM.get(url).then((res) => ({
      data: res.data,
    }));
  };

  clearCacheEvent = () => {
    return ax.post("/public-events/clear_cache/");
  };

  clearCacheSession = () => {
    return ax.post("/public-sessions/clear_cache/");
  };
}

export default EventService;
