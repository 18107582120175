import React from "react";
import moment from "moment";
import HomeButton from "components/errorBoundary/HomeButton";
import config from "assets/config";
import PropTypes from "prop-types";

class HFNErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    const date = moment().format("%20-%20MMM%20DD%20YYYY");
    const basicMailURL =
      "mailto:vishal.prakash@volunteer.heartfulness.org?subject=Unified%20Events%20Portal%20-%20Bug%20Identified";
    this.state = {
      error: null,
      errorInfo: null,
      mailURL: date ? basicMailURL + date : basicMailURL,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  clearError = () => {
    this.setState({ error: null, errorInfo: null });
  };

  render() {
    const { errorInfo, mailURL, error } = this.state;
    const { children } = this.props;
    if (errorInfo) {
      return (
        <div className="error-boundary-section p-mt-4">
          <div className="p-p-4 p-p-4">
            <div className="p-mb-4 p-text-center">
              <img src="/assets/hfn-logo.svg" alt="heartfulness" />
            </div>
            <div className="p-text-center p-text-normal">
              {" "}
              Oops! Something went wrong{" "}
            </div>
            <div className="p-field p-text-center p-mx-6 p-mb-6 p-mt-5">
              <HomeButton clearError={this.clearError} />
              <a
                className="p-button p-component report-issue-button p-mt-2 p-mr-3"
                href={mailURL}
              >
                {" "}
                Report Issue{" "}
              </a>
            </div>
            {!config.NODE_ENV || config.NODE_ENV === "development" ? (
              <details style={{ whiteSpace: "pre-wrap" }}>
                {error && error.toString()}
                <br />
                {errorInfo?.componentStack}
              </details>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
    return children;
  }
}

HFNErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HFNErrorBoundary;
