import { PhoneNumberUtil } from "google-libphonenumber";

export const abhyasiid_regex = "^([a-zA-Z]{6}[0-9]{3}|[HABhab]{1}[0-9]{8})$";
export const emailRegex =
  '(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))';

export function validateRE(re1, txt) {
  const re = RegExp(re1);
  return re.test(String(txt));
}

export const validateAbhyasiId = (t) => validateRE(abhyasiid_regex, t);

export const validateEmailId = (t) => validateRE(emailRegex, t);

export function validatePhoneNumber(phoneNumber) {
  let valid = false;
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    valid =
      phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber)) &&
      phoneNumber.length <= 13;
  } catch (e) {
    valid = false;
  }
  return valid;
}

export const validateWordLimit = (value) => {
  return value.replace(/\s+/g, " ").trim().split(" ").length <= 100;
};
