import React, { useState, useEffect } from "react";
import { SessionFormUser, SessionSearchUser } from "utils/constants";

const UserCheckboxes = ({ callBackFun, isReset = false, loc = "" }) => {
  const [checkedItems, setCheckedItems] = useState({});
  let wrapperWidth = "";
  let CoordinatorUsers = {};

  if (loc === "search") {
    wrapperWidth = "p-md-12 p-lg-12";
    CoordinatorUsers = SessionSearchUser;
  } else {
    wrapperWidth = "p-md-6 p-lg-4";
    CoordinatorUsers = SessionFormUser;
  }
  let wrapWd = `${wrapperWidth} coUserCheckbox`;
  const onUserChecked = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    if (isReset) setCheckedItems({});
  }, [isReset]);

  useEffect(() => {
    let values = [];
    Object.keys(checkedItems).map((k) => {
      checkedItems[k] ? values.push(k) : "";
    });

    callBackFun(values);
  }, [checkedItems]);

  //
  return (
    <>
      {CoordinatorUsers &&
        CoordinatorUsers.map((res) => {
          return (
            <div key={`${loc}-${res.id}`} className={wrapWd}>
              <input
                type="checkbox"
                id={`${loc}-${res.id}`}
                value={res.value}
                name={res.value}
                className="userType"
                checked={checkedItems[res.value] || ""}
                onChange={onUserChecked}
              />
              <label htmlFor={`${loc}-${res.id}`} className="p-checkbox-label">
                {res.label}
              </label>
            </div>
          );
        })}
    </>
  );
};
export default UserCheckboxes;
