import React, { useRef, useCallback, useMemo, useState } from "react";
import { Button } from "primereact/button";
import HFNLoader from "sharedComponents/lazyLoading/Loading";
import HFNDynamicForm from "sharedComponents/hfnForm";
import Service from "services/selfRegistration/selfReg.service";
import { selfRegFormFields } from "assets/data/selfRegFormFields";
import { formatPhonenumber } from "utils/common";

function SelfAttendance({
  event,
  session,
  initValues = {},
  btnProps = {},
  callbackFun,
}) {
  if (!initValues?.regCity?.id) delete initValues.regCity;
  const formRef = useRef(null);
  const service = useMemo(() => new Service(), []);
  const [loader, setLoader] = useState(false);
  const [initVal, setInitVal] = useState(initValues);
  const formKey = useRef(false);

  const formButtonGroup = useCallback(
    () => (
      <div className="p-text-right">
        <Button
          type="submit"
          label={btnProps?.text ? btnProps.text : "Mark Attendance"}
          className="p-button p-button-primary p-mr-2"
        />
      </div>
    ),
    []
  );

  const formOnsubmit = async (data) => {
    try {
      setLoader(true);
      const regPhone = data?.regPhone === "+91" ? "" : data?.regPhone;
      const params = {
        session,
        name: data?.regName,
        email: data?.regEmail || null,
        phone: regPhone ? formatPhonenumber(regPhone) : null,
        age: data?.regAge ? parseInt(data.regAge, 10) : null,
        city: data?.regCity?.id,
        gender: data?.regGender?.value,
      };
      const res = await service
        .postSelfRegistration(params, event)
        .catch((e) => {
          let errorMsg = "";
          if (e.response && e.response.status === 400) {
            errorMsg = e?.response?.data?.detail;
          }
          callbackFun({
            status: false,
            msg: Array.isArray(errorMsg) ? errorMsg[0] : errorMsg || "",
          });
          setLoader(false);
        });

      if (res?.data) {
        setLoader(false);
        formKey.current = !formKey.current;
        setInitVal({ regPhone: "+91", regCity: initValues?.regCity });
        callbackFun({ status: true });
      }
    } catch (e) {
      setLoader(false);
    }
    return true;
  };

  return (
    <>
      {loader === true ? <HFNLoader /> : null}
      <div className="regForm">
        <HFNDynamicForm
          key={formKey.current}
          initialValues={initVal}
          ref={formRef}
          sections={selfRegFormFields}
          submitButtonGroup={formButtonGroup}
          onFormSubmit={formOnsubmit}
        />
      </div>
    </>
  );
}

export default SelfAttendance;
