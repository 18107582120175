import validations from "utils/validations";
import { validatePhoneNumber } from "utils/abhyasiValidations";
import { formatPhonenumber } from "utils/common";

export const selfRegFormFields = {
  regularParticipantsFormList: [
    {
      section: "",
      sectionDesc: "",
      sectionFields: [
        {
          regName: {
            properties: {
              fieldWrapperClassNames:
                "p-md-6 p-py-0 p-mt-3 p-mt-md-3 p-mb-1 p-lg-12",
              type: "InputText",
              label: "Name",
              primeFieldProps: {},
              validations: {
                required: validations.required,
                pattern: validations.alphabet,
              },
            },
          },
        },
        // {
        //   regPhoto: {
        //     properties: {
        //       fieldWrapperClassNames:
        //         "p-md-6 p-py-0 p-mt-3 p-mt-md-3 p-mb-1 p-lg-12",
        //       type: "ImageUpload",
        //       label: "Photo",
        //       primeFieldProps: {},
        //       validations: {
        //         required: validations.required,
        //       },
        //     },
        //   },
        // },
        {
          regPhone: {
            properties: {
              fieldWrapperClassNames:
                "p-md-6 p-py-0 p-mt-3 p-mt-md-3 p-mb-1 p-lg-12",
              type: "PhoneInput",
              label: "Mobile",
              primeFieldProps: {},
              validations: {
                validate: (value) => {
                  if (value && value !== "+91") {
                    const formattedValue = formatPhonenumber(value);
                    return validatePhoneNumber(formattedValue)
                      ? true
                      : "Invalid phone number";
                  } else return true;
                },
              },
            },
          },
        },
        {
          regEmail: {
            properties: {
              fieldWrapperClassNames:
                "p-md-6 p-py-0 p-mt-3 p-mt-md-3 p-mb-1 p-lg-12",
              type: "InputText",
              label: "Email",
              primeFieldProps: {},
              validations: {
                pattern: validations.email,
              },
            },
          },
        },
        {
          regCity: {
            properties: {
              fieldWrapperClassNames:
                "p-md-6 p-py-0 p-mt-3 p-mt-md-3 p-mb-1 p-lg-12",
              type: "CityAutoComplete",
              label: "City Of Residence",
              primeFieldProps: {},
              validations: {
                required: validations.required,
              },
            },
          },
        },
        {
          regGender: {
            properties: {
              fieldWrapperClassNames:
                "p-md-6 p-py-0 p-mt-3 p-mt-md-3 p-mb-1 p-lg-12",
              type: "Select",
              label: "Gender",
              primeFieldProps: {},
              dropdownOptions: [
                { label: "Male", value: "M" },
                { label: "Female", value: "F" },
              ],
              validations: {},
            },
          },
        },
        {
          regAge: {
            properties: {
              fieldWrapperClassNames:
                "p-md-6 p-py-0 p-mt-3 p-mt-md-3 p-mb-1 p-lg-12",
              type: "InputText",
              label: "Age",
              primeFieldProps: {},
              validations: {
                pattern: validations.numberText,
              },
            },
          },
        },
        // {
        //   regDate: {
        //     properties: {
        //       fieldWrapperClassNames:
        //         "p-md-6 p-py-0 p-mt-3 p-mt-md-3 p-mb-1 p-lg-12",
        //       type: "Calendar",
        //       label: "Date Of First Heartfulness Meditation",
        //       primeFieldProps: { showIcon: true },
        //       validations: {
        //         required: validations.required,
        //       },
        //     },
        //   },
        // },
      ],
    },
  ],
};
