// store
import store from "store";

import { PROP, MERGE, CLEAR, REF } from "store/actionTypes/toaster";

const generateMessages = (payload, header, severity) => {
  return Array.isArray(payload)
    ? payload.map((msg) => ({
        summary: header,
        ...(typeof msg === "string" ? { detail: msg } : msg),
        severity,
        life: 5000,
      }))
    : {
        summary: header,
        ...(typeof payload === "string" ? { detail: payload } : payload),
        severity,
        life: 5000,
      };
};

const toaster = {
  ref: (payload) => store.dispatch({ type: REF, payload }),

  props: (payload) => store.dispatch({ type: PROP, payload }),

  merge: (payload) => store.dispatch({ type: MERGE, payload }),

  clear: () => store.dispatch({ type: CLEAR }),

  success: (payload) => {
    const { ref } = store.getState().toast;
    if (ref && ref.current)
      ref.current.show(generateMessages(payload, "Success", "success"));
  },

  info: (payload) => {
    const { ref } = store.getState().toast;
    if (ref && ref.current)
      ref.current.show(generateMessages(payload, "Information", "info"));
  },

  warn: (payload) => {
    const { ref } = store.getState().toast;
    if (ref && ref.current)
      ref.current.show(generateMessages(payload, "Warning", "warn"));
  },

  error: (payload) => {
    const { ref } = store.getState().toast;
    if (ref && ref.current)
      ref.current.show(generateMessages(payload, "Error", "error"));
  },

  custom: (payload) => {
    const { ref } = store.getState().toast;
    if (ref && ref.current) ref.current.show(payload);
  },

  close: () => {
    const { ref } = store.getState().toast;
    if (ref && ref.current) ref.current.clear();
  },
};

export default toaster;
