// services
import DropdownService from "services/dropdown/dropdown.service";

export const getCitySuggestions = async (
  inputValue,
  callback,
  country = ""
) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  let suggestions = [];
  if (filterValue) {
    const dropdownService = new DropdownService();
    let apiResponse;

    let countryName;
    let countryFilterValue;
    if (country && country !== null) {
      countryName = country.name.toLowerCase();
      countryFilterValue = `${countryName}/${filterValue[0]}`;
    } else {
      countryFilterValue = filterValue[0];
    }

    try {
      apiResponse = await dropdownService.getCachedCitySuggestions(
        countryFilterValue
      );
      const { results } = apiResponse.data;
      for (const item of results) {
        if (item.active && item.name.toLowerCase().startsWith(filterValue)) {
          suggestions.push({ ...item, value: item.id, label: item.name });
          if (suggestions.length === 20) break;
        }
      }
    } catch {
      try {
        apiResponse = await dropdownService.getGPlaceSuggestions(filterValue);
        suggestions = apiResponse.data;
      } catch {
        return null;
      }
    }
  }

  callback(suggestions || []);
};
export const getCenterSuggestions = async (inputValue, callback) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  let suggestions = [];
  if (filterValue?.length) {
    const dropdownService = new DropdownService();
    let apiResponse;
    apiResponse = await dropdownService.getCachedCenterSuggestions(filterValue);
    const { results } = apiResponse.data;
    for (const item of results) {
      if (item.active && item.name.toLowerCase().includes(filterValue)) {
        suggestions.push({ ...item, value: item.id, label: item.name });
        if (suggestions.length === 20) break;
      }
    }
  }
  callback(suggestions || []);
};
export const getVillageSuggestions = async (
  inputValue,
  stateValue = null,
  callback
) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  let suggestions = [];
  if (filterValue && filterValue.length > 2) {
    const dropdownService = new DropdownService();
    let apiResponse;

    try {
      const payload = {
        type: 30,
        search: filterValue,
      };
      if (stateValue) payload.state = stateValue;
      apiResponse = await dropdownService.getCachedVillageSuggestions(payload);
      const { results } = apiResponse.data;
      for (const item of results) {
        suggestions.push({
          value: item.id,
          label: item.name,
          subdistrict: item.parent,
          state: item.state,
          city: item.city,
        });
        if (suggestions.length === 20) break;
      }
    } catch {
      return null;
    }
  }
  callback(suggestions || []);
};
export const getStateSuggestions = async (inputValue, callback) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  let suggestions = [];

  if (filterValue) {
    const dropdownService = new DropdownService();
    let apiResponse;

    try {
      apiResponse = await dropdownService.getCachedStateSuggestions(
        filterValue[0]
      );
      const { results } = apiResponse.data;
      for (const item of results) {
        if (item.active && item.name.toLowerCase().startsWith(filterValue)) {
          suggestions.push({ ...item, value: item.id, label: item.name });
          if (suggestions.length === 20) break;
        }
      }

      if (suggestions.length === 0) {
        try {
          apiResponse = await dropdownService.getGPlaceSuggestions(filterValue);
          suggestions = apiResponse.data.map((item) => ({
            ...item,
            value: item.state_id,
            label: item.state,
          }));
        } catch {
          return null;
        }
      }
    } catch {
      try {
        apiResponse = await dropdownService.getGPlaceSuggestions(filterValue);
        suggestions = apiResponse.data.map((item) => ({
          ...item,
          value: item.state_id,
          label: item.state,
        }));
      } catch {
        return null;
      }
    }
  }

  callback(suggestions || []);
};

export const getCountrySuggestions = async (inputValue, callback) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  let suggestions = [];

  if (filterValue) {
    const dropdownService = new DropdownService();
    let apiResponse;

    try {
      apiResponse = await dropdownService.getCachedCountrySuggestions(
        filterValue[0]
      );
      const { results } = apiResponse.data;
      for (const item of results) {
        if (item.active && item.name.toLowerCase().startsWith(filterValue)) {
          suggestions.push({ ...item, value: item.id, label: item.name });
          if (suggestions.length === 20) break;
        }
      }

      if (suggestions.length === 0) {
        try {
          apiResponse = await dropdownService.getGPlaceSuggestions(filterValue);
          suggestions = apiResponse.data.map((item) => ({
            ...item,
            value: item.country_id,
            label: item.country,
          }));
        } catch {
          return null;
        }
      }
    } catch {
      try {
        apiResponse = await dropdownService.getGPlaceSuggestions(filterValue);
        suggestions = apiResponse.data.map((item) => ({
          ...item,
          value: item.country_id,
          label: item.country,
        }));
      } catch {
        return null;
      }
    }
  }

  callback(suggestions || []);
};

export const getAshramSuggestions = async (inputValue, callback) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  let suggestions = [];

  if (filterValue) {
    const dropdownService = new DropdownService();
    let apiResponse;

    try {
      apiResponse = await dropdownService.getAshramIdSuggestions(filterValue);
      const { results } = apiResponse.data;
      for (const item of results) {
        if (item.active && item.name.toLowerCase()) {
          suggestions.push({ ...item, value: item.id, label: item.name });
          if (suggestions.length === 20) break;
        }
      }
    } catch {}
  }
  callback(suggestions || []);
};


export const getZoneSuggestions = async (inputValue, callback) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  let suggestions = [];

  if (filterValue) {
    const dropdownService = new DropdownService();
    let apiResponse;

    try {
      apiResponse = await dropdownService.getZoneSuggestions(filterValue);
      const { results } = apiResponse.data;
      for (const item of results) {
        if (item.active && item.name.toLowerCase()) {
          suggestions.push({ ...item, value: item.id, label: item.name });
          if (suggestions.length === 20) break;
        }
      }
    } catch {}
  }
  callback(suggestions || []);
};