import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { isLoginAuth, getLoginRole } from "utils/login";
import IdCard from "sharedComponents/idCard";
import { IF_FOUND_TEXT, SAHAJ_MARG, KANHA_ADDRESS } from "utils/constants";

function PrintIDCard() {
  const [disablePrint, setDisablePrint] = useState(false);
  const { cardNumber } = useParams();
  const loginRole = getLoginRole() || [];
  let arrCardNumbers = [];
  if (cardNumber.includes(",")) {
    arrCardNumbers = cardNumber.split(",");
  } else {
    arrCardNumbers.push(cardNumber);
  }
  let isPrefect = false;
  if (loginRole.includes("prefect")) isPrefect = true;

  const staticData = {
    ifFoundText: IF_FOUND_TEXT,
    sahajMargText: SAHAJ_MARG,
    kanhaAddress: KANHA_ADDRESS,
  };

  const printDisable = (value) => {
    setDisablePrint(value);
  };

  return (
    <div className="app-wrapper open">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {isPrefect && (
          <>
            <div className="layout">
              {isLoginAuth() &&
                arrCardNumbers.map((cardNum) => (
                  <IdCard
                    key={cardNum}
                    cardNumber={cardNum}
                    staticData={staticData}
                    printDisable={printDisable}
                  />
                ))}
            </div>
            <div className="rightAlignedButton print-button hidden-print">
              <Button
                type="button"
                label="Print"
                className="p-button p-button-secondary p-ml-3 p-mb-3 action-button"
                onClick={() => window.print()}
                disabled={disablePrint}
              />
            </div>
          </>
        )}
        {!isPrefect && (
          <div className="unauthorized-page-access">
            You do not have permission to perform this action.
          </div>
        )}
      </div>
    </div>
  );
}

export default PrintIDCard;
