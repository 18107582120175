import React, {
  memo,
  useState,
  useEffect,
  useCallback,
  useMemo,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";

import { useSelector } from "react-redux";
import Select from "react-select";
import Async from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import PhoneInput from "react-phone-input-2";
import {
  compact,
  isArray,
  isEmpty,
  isEqual,
  lowerCase,
  noop,
  omit,
  upperFirst,
} from "lodash";
import AbhyasiSearch from "./AbhyasiSearch";
import moment from "moment";
import toaster from "utils/toaster";
// form
import { useForm, Controller } from "react-hook-form";

//prime components
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { Slider } from "primereact/slider";
import { Calendar } from "primereact/calendar";
import { Chips } from "primereact/chips";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Divider } from "primereact/divider";
import { InputNumber } from "primereact/inputnumber";
import { Tooltip } from "primereact/tooltip";

// utils
import modalPopup from "utils/modalPopup";
import HFNLoader from "sharedComponents/lazyLoading/Loading";
import { addAbhyasi } from "services/partners/partners.service";

import {
  getCitySuggestions,
  getStateSuggestions,
  getCountrySuggestions,
  getVillageSuggestions,
  getAshramSuggestions,
  getZoneSuggestions,
} from "utils/autoComplete";
import { cityACTemplate, villageACTemplate } from "utils/badgeTemplate";
import useDebounce from "utils/useDebounce";

import { ax } from "services/base";
import CarouselCardMultiField from "sharedComponents/carousel/CarouselCardMultiField";
import CoordinatorCard from "sharedComponents/carousel/CoordinatorCard";
import UserCheckboxes from "./UserCheckboxes";
import InputTimeField from "./InputTimeField";
import ImageUpload from "./ImageUpload";

const NORMAL = "normal";
const DEFAULT_TIME = "00:00";

const dateFunction = (
  givenDate,
  properties,
  key,
  watch,
  setValue,
  watchTrack,
  getValues,
  setSelectOptions,
  isMinDate = false
) => {
  const cond = isMinDate ? "minDate" : "maxDate";
  if (moment(new Date(givenDate)).isValid()) {
    properties[cond] = new Date(givenDate);
  } else if (typeof givenDate === "string") {
    const depVal = watch(givenDate);
    if (depVal && watchTrack.current[key]?.[cond] !== depVal) {
      watchTrack.current[key] = {
        ...(watchTrack.current[key] || {}),
        ...(isMinDate ? { minDate: depVal } : { maxDate: depVal }),
      };

      const currentVal = getValues(key);

      if (isMinDate && currentVal && currentVal < depVal) {
        setValue(key, "");
      }

      if (!isMinDate && currentVal && currentVal > depVal) {
        setValue(key, "");
      }

      setSelectOptions((prev) => ({
        ...prev,
        [key]: {
          ...(prev[key] || {}),
          ...(isMinDate ? { minDate: depVal } : { maxDate: depVal }),
        },
      }));
    }
  }
};

const HFNDynamicForm = forwardRef(
  (
    {
      initialValues = {},
      sections = {},
      onFormSubmit,
      submitButtonGroup,
      formConfig,
      visibilityCheck,
      disabilityCheck,
      useFormInit = {},
      onTemplateSelect,
      mode = NORMAL,
      createForm = false,
      popupModal = false,
    },
    ref
  ) => {
    const [templates, setTemplates] = useState([]);
    const [visibles, setVisibles] = useState(() => ({}));
    const [disables, setDisables] = useState(() => ({}));
    const [selectOptions, setSelectOptions] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [apiTrigger, setApiTrigger] = useState({});
    const [fields, setFields] = useState({});

    const HfnCo = useRef([]);
    const resetCheckBox = useRef(false);
    const mfRef = useRef([]);
    const watchTrack = useRef({});

    const style = {
      control: (base) => ({
        ...base,
        // This line disable the blue border
        boxShadow: "none",
      }),
    };

    useDebounce(
      () => {
        if (
          apiTrigger?.apiPathUrl &&
          apiTrigger?.filterValue &&
          apiTrigger?.callback &&
          apiTrigger?.labelField &&
          apiTrigger?.valueField
        ) {
          let acOptions = [];
          const { apiPathUrl, filterValue, callback, labelField, valueField } =
            apiTrigger;
          ax.get(apiPathUrl + filterValue).then((res) => {
            let results = [];

            if (res && res.data) {
              if (Array.isArray(res.data)) results = res.data;
              else if (Array.isArray(res.data.results))
                results = res.data.results;

              acOptions = results.map((acOption) => ({
                ...acOption,
                label: acOption[labelField],
                value: acOption[valueField],
              }));
            }
            callback(acOptions);
          });
        }
      },
      900,
      [apiTrigger]
    );

    useEffect(() => {
      reset({});
      let templatesF = [];
      Object.keys(sections).map((key) => {
        templatesF = [...templatesF, ...sections[key]];
      });
      if (templatesF && templatesF.length > 0) {
        setTemplates(templatesF);
      }
    }, [sections]);

    useEffect(() => {
      let finalFields = {};
      templates.forEach((sec) => {
        sec.sectionFields &&
          sec.sectionFields.forEach((field) => {
            let tempFields = {};
            const keyD = Object.keys(field)[0];
            if (
              keyD &&
              field[keyD] &&
              field[keyD]?.properties?.type === "multifield"
            ) {
              tempFields = {
                ...field[keyD]?.properties?.fields.reduce(
                  (accumulator, obj) => ({ ...accumulator, ...obj }),
                  {}
                ),
              };
            }
            finalFields = { ...finalFields, ...field, ...tempFields };
          });
      });
      if (finalFields && Object.keys(finalFields).length > 0) {
        setFields(finalFields);
      }
    }, [templates]);

    useEffect(() => {
      if (fields && Object.keys(fields)?.length > 0) {
        const visibleData = {
          ...Object.fromEntries(Object.keys(fields).map((key) => [key, true])),
          ...(typeof visibilityCheck === "function"
            ? visibilityCheck(initialValues)
            : null),
        };

        const disablesData = {
          ...Object.fromEntries(Object.keys(fields).map((key) => [key, false])),
          ...(typeof disabilityCheck === "function"
            ? disabilityCheck(initialValues)
            : null),
        };

        const selectOptionsData = Object.fromEntries(
          Object.entries(fields)
            .filter(([, field]) =>
              field?.properties?.primeFieldProps?.options ? true : false
            )
            .map(([key, field]) => {
              [key, field.properties.primeFieldProps.options];
            })
        );
        setVisibles(visibleData);
        setDisables(disablesData);
        setSelectOptions((prev) => ({ ...prev, ...selectOptionsData }));
      }
    }, [fields]);

    const performOperation = useCallback((type, a, b) => {
      switch (type) {
        case ">":
          return a > b;
        case "<":
          return a < b;
        case ">=":
          return a >= b;
        case "<=":
          return a <= b;
        case "===":
          return a === b;
        default:
          return false;
      }
    }, []);

    const addAbhyasiUser = async (data, identifier) => {
      try {
        const abhyasisParams = {
          name: data?.[`${identifier}-name`],
          email: data?.[`${identifier}-email`],
          mobile: data?.[`${identifier}-mobile`],
          entry_channel: "web",
          receive_updates: false,
          srcmCenter: "",
          srcm_id: "",
        };
        if (!data?.[`${identifier}-email`]) delete abhyasisParams.email;

        let res = await addAbhyasi({
          seekers: [abhyasisParams],
          strict_identifiers: true,
        }).catch((res) => {
          console.log(res);
        });
        let result = { id: null, error: "", message: "" };
        if (res?.data?.seekers && res.data.seekers.length > 0) {
          if (res?.data?.seekers[0]?.error) {
            result["error"] = true;
            result["message"] = res.data.seekers[0].error;
          } else {
            result["id"] = res.data.seekers[0].partner_id;
            result["error"] = false;
          }
        } else {
          result["error"] = true;
          result["message"] = `unable to add user`;
        }

        return result;
      } catch (e) {
        console.log("Something went wrong." + e);
      }
    };

    const validateAddCo = async (keysV, key, chkBoxF, HfnCo) => {
      let data = { id: null, isErr: false, msg: "", reset: false };

      if (chkBoxF) {
        if (HfnCo.length > 0) {
          data["hfnCo"] = HfnCo;
          data["reset"] = true;
        } else {
          data["msg"] = "Select atleast one role";
          data["isErr"] = true;
          return data;
        }
      }

      let { id, error, message } = await addAbhyasiUser(
        keysV,
        key.split(/[-]+/).pop()
      );
      data["isErr"] = error;
      if (error === false) {
        data["msg"] = `User added successfully`;
        data["id"] = id;
        data["reset"] = true;
      } else data["msg"] = message;

      return data;
    };

    const dd = useSelector((state) => state.dropdownDetails);

    const {
      watch,
      handleSubmit,
      formState: { errors, isSubmitted },
      control,
      register,
      setValue,
      getValues,
      resetField,
      trigger,
      setError,
      clearErrors,
      reset,
    } = useForm({
      mode: "onChange",
      ...useFormInit,
    });

    useEffect(() => {
      const disables = Object.entries(selectOptions)
        .filter(([, v]) => v?.disable)
        .map(([k]) => k);
      if (disables.length > 0) {
        clearErrors(disables);
        disables.map((item) => {
          setValue(item, "");
          return null;
        });
      }
    }, [selectOptions, clearErrors]);

    useEffect(() => {
      let subscription = null;
      if (
        (typeof visibilityCheck === "function" ||
          typeof disabilityCheck === "function") &&
        subscription === null
      )
        subscription = watch((value, { name, type }) => {
          if (typeof visibilityCheck === "function") {
            const visibilityChecks = visibilityCheck(value, name, type);
            if (visibilityChecks) {
              const newVisibles = { ...visibles, ...visibilityChecks };
              if (!isEqual(visibles, newVisibles)) setVisibles(newVisibles);
            }
          }
          if (typeof disabilityCheck === "function") {
            const disabilityChecks = disabilityCheck(value, name, type);
            if (disabilityChecks) {
              const newDisables = { ...disables, ...disabilityChecks };
              if (!isEqual(disables, newDisables)) setDisables(newDisables);
            }
          }
        });
      return () => subscription?.unsubscribe?.();
    }, [watch, visibles, disables]);

    const closeModel = useMemo(
      () => (
        <div className="form-button-group">
          <Button
            type="button"
            className="p-button p-button-secondary p-mr-2"
            label="Cancel"
            onClick={() => {
              modalPopup.toggle(false);
            }}
          />
          <Button
            type="submit"
            label="Submit"
            className="p-button p-button-primary"
          />
        </div>
      ),
      []
    );

    const validatedependency = useCallback(
      (valX, key) => {
        const iVal = valX;
        let disabled = false;
        if (Array.isArray(iVal)) {
          const depKeys = iVal.map((depItem) => Object.keys(depItem)[0]);
          const depWatch = watch(depKeys);
          depKeys.map((depItem, idx) => {
            if (depWatch[idx]) {
              const condVal = depWatch[idx];
              const valOptionArr = iVal.filter((itemD) => {
                return itemD[depItem];
              });
              const valOptions =
                valOptionArr.length > 0 ? valOptionArr[0]?.[depItem] : [];
              if (typeof valOptions === "string" && condVal) {
                disabled = disabled
                  ? disabled
                  : valOptions === (condVal?.value || condVal);
              } else if (typeof valOptions === "object" && condVal) {
                if (Array.isArray(valOptions)) {
                  disabled = disabled
                    ? disabled
                    : valOptions?.includes(condVal?.value);
                } else if (valOptions?.compare) {
                  let presentValue =
                    getValues(key)?.value || getValues(key) || "";
                  if (valOptions?.value) {
                    presentValue = new Date(valOptions?.value);
                  } else if (valOptions?.type === "current-date") {
                    presentValue = new Date();
                  }
                  disabled = disabled
                    ? disabled
                    : performOperation(
                        valOptions?.compare,
                        condVal?.value || condVal,
                        presentValue
                      );
                } else if (valOptions?.condition) {
                  const checkCondition =
                    condVal?.value || condVal
                      ? performOperation(
                          valOptions?.condition,
                          condVal?.value || condVal,
                          valOptions?.value
                        )
                      : valOptions?.hideEmpty;
                  disabled = disabled || checkCondition;
                }
              } else if (typeof valOptions === "boolean") {
                disabled = disabled ? disabled : valOptions;
              }
            }
          });
        }
        return disabled;
      },
      [performOperation, watch, getValues]
    );

    const setOptions = useCallback(
      (options, disabilityChecks) => {
        if (!isEmpty(options))
          setSelectOptions({ ...selectOptions, ...options });
        if (disabilityChecks) {
          const newDisables = { ...disables, ...disabilityChecks };
          if (!isEqual(disables, newDisables)) setDisables(newDisables);
        }
      },
      [selectOptions, disables]
    );

    useImperativeHandle(
      ref,
      () => ({
        setOptions: setOptions,
      }),
      [setOptions]
    );

    const getDropDown = useCallback(
      (option_api, api_key, key, doUpdate = false, condVal = {}) => {
        if (option_api && api_key) {
          ax.get(option_api).then((res) => {
            let dropDownoptionsList = [];
            res.data &&
              res.data.results.map((val) => {
                dropDownoptionsList.push({
                  label: val[api_key[0].label],
                  value: val[api_key[0].value],
                });
              });
            if (doUpdate) {
              watchTrack.current[key] = dropDownoptionsList;
            } else {
              watchTrack.current[key] = condVal;
            }
            setSelectOptions((prev) => {
              if (prev[key]?.options !== dropDownoptionsList) {
                return {
                  ...prev,
                  [key]: {
                    ...(prev?.[key] || {}),
                    options: dropDownoptionsList,
                  },
                };
              }
              return prev;
            });
          });
        }
        return [];
      },
      []
    );

    const deleteEntry = useCallback((dFields, key, idx) => {
      if (dFields && dFields?.length > 0) {
        const newVal = dFields.map((item, index) =>
          index === idx ? null : item
        );
        setValue(key, compact(newVal));
      }
    }, []);

    const editEntry = useCallback((fields, pos) => {
      if (
        fields &&
        typeof fields === "object" &&
        Object.keys(fields).length > 0
      ) {
        Object.entries(fields).map(([k, v]) => setValue(k, v));
        setValue("editPos", pos);
      }
    }, []);

    // const addMultifield = useCallback((key, subFieldKeys, sectionFieldsX) => {
    const addMultifield = async (
      key,
      subFieldKeys,
      sectionFieldsX,
      chkBoxF,
      isMultiCo
    ) => {
      setIsLoading(true);
      let subFieldValidate = {},
        containsError = false;

      const subFieldV = getValues(subFieldKeys);
      const keysV = subFieldKeys.reduce(
        (a, o, i) => ({
          ...a,
          [o]: subFieldV[i],
        }),
        {}
      );

      sectionFieldsX.map((subFields) => {
        if (
          subFields &&
          typeof subFields === "object" &&
          Object.keys(subFields)?.length > 0
        ) {
          const subFieldProp = Object.values(subFields)[0]?.properties;
          const keyX = Object.keys(subFields)[0];

          if (subFieldProp.validations) {
            if (typeof subFieldProp.validations.validate === "function") {
              const subFieldValidatefn = (value) =>
                subFieldProp.validations.validate(value, getValues);
              subFieldValidate = {
                ...subFieldValidate,
                [keyX]: {
                  ...subFieldProp.validations,
                  subFieldValidatefn,
                },
              };
            } else
              subFieldValidate = {
                ...subFieldValidate,
                [keyX]: subFieldProp.validations,
              };
          }
        }
      });

      const parentKeyData = getValues(key);
      const editPos = getValues("editPos");

      subFieldKeys.map((subKey) => {
        if (subKey in subFieldValidate) {
          if (
            subFieldValidate[subKey]?.required?.value &&
            !(keysV[subKey]?.value || keysV[subKey])
          ) {
            setError(subKey, {
              type: "custom",
              message:
                subFieldValidate[subKey]?.required?.message ||
                "This field is required.",
            });
            containsError = true;
          } else if (
            subFieldValidate[subKey]?.pattern?.value &&
            (keysV[subKey]?.value || keysV[subKey]) &&
            !subFieldValidate[subKey]?.pattern?.value?.test(
              keysV[subKey]?.value || keysV[subKey]
            ) &&
            !containsError
          ) {
            setError(subKey, {
              type: "custom",
              message:
                subFieldValidate[subKey]?.pattern?.message ||
                "Pattern mismatch.",
            });
            containsError = true;
          } else if (
            subFieldValidate[subKey]?.subFieldValidatefn &&
            typeof subFieldValidate[subKey]?.subFieldValidatefn ===
              "function" &&
            !containsError
          ) {
            const errMsg = subFieldValidate[subKey]?.subFieldValidatefn(
              keysV[subKey]?.value || keysV[subKey]
            );
            if (errMsg != true) {
              setError(subKey, {
                type: "custom",
                message: errMsg || "Pattern mismatch.",
              });
              containsError = true;
            } else containsError = false;
          }
        }
      });

      if (isMultiCo && !containsError) {
        let { id, isErr, msg, hfnCo, reset } = await validateAddCo(
          keysV,
          key,
          chkBoxF,
          HfnCo.current
        );
        if (isErr) toaster.error(msg);
        else {
          keysV["id"] = id;
          keysV["hfnCo"] = hfnCo;
          reset ? (resetCheckBox.current = true) : "";
          // toaster.success(msg);
        }
        containsError = isErr;
      }

      if (!containsError) {
        HfnCo.current = [];
        if (editPos > -1) {
          parentKeyData[editPos] = keysV;
          setValue(key, parentKeyData);
          setValue("editPos", -1);
        } else if (parentKeyData && isArray(parentKeyData)) {
          setValue(key, [...parentKeyData, keysV]);
        } else {
          setValue(key, [keysV]);
        }
        subFieldKeys.map((i) => {
          i.includes("mobile") ? setValue(i, "+91") : setValue(i, "");
        });
      }
      setIsLoading(false);
    };

    const eventCoCB = (key, keysV) => {
      const role = key.split("-")[0];
      const parentKeyData = getValues(key);
      if (parentKeyData && isArray(parentKeyData)) {
        let isExist = false;
        let errorMsg = "";
        parentKeyData.map((val) => {
          if (val?.hfnCo) {
            const roleExist = val?.hfnCo.filter((value) =>
              keysV?.hfnCo.includes(value)
            );
            if (val.id === keysV.id && roleExist.length > 0) {
              isExist = true;
              errorMsg = `User has been already added with same role`;
            }
          } else if (val.id === keysV.id) {
            isExist = true;
            errorMsg = `${upperFirst(role).replaceAll(
              "_",
              " "
            )} has been already added`;
          }
        });
        if (!isExist) {
          // toaster.success(`User added successfully`);
          setValue(key, [...parentKeyData, keysV]);
        } else {
          toaster.error(errorMsg);
        }
      } else {
        // toaster.success(`User added successfully`);
        setValue(key, [keysV]);
      }
    };

    const onSubmitForm = useCallback(
      (data, e) => {
        onFormSubmit(data, setError, e, visibles, reset, setValue);
      },
      [visibles]
    );

    const renderFieldsTypes = useCallback(
      (properties, validations = {}, key, primeFieldProps) => {
        switch (properties.type) {
          case "InputText":
            return (
              <div>
                <Controller
                  control={control}
                  rules={validations}
                  name={key}
                  defaultValue={initialValues[key] || ""}
                  render={({ field: { onChange, onBlur, value, name } }) => {
                    return (
                      <InputText
                        {...primeFieldProps}
                        className={classNames({
                          "p-invalid": errors[key],
                        })}
                        name={name}
                        value={value}
                        inputMode={
                          validations?.patternString === "numberText"
                            ? "numeric"
                            : ""
                        }
                        disabled={
                          selectOptions?.[key]?.disable ||
                          primeFieldProps.disabled ||
                          disables[key]
                        }
                        readOnly={
                          properties.readOnly || selectOptions?.[key]?.readOnly
                        }
                        onChange={(e) => {
                          onChange(e.target.value);
                          if (
                            primeFieldProps &&
                            primeFieldProps.onChange &&
                            typeof primeFieldProps.onChange === "function"
                          )
                            primeFieldProps.onChange(
                              e?.target?.value,
                              setValue,
                              isSubmitted,
                              getValues,
                              errors,
                              resetField,
                              trigger,
                              setError
                            );
                        }}
                        onBlur={(e) => {
                          onBlur(e);
                          primeFieldProps?.onBlur?.(
                            e?.target?.value,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError
                          );
                        }}
                      />
                    );
                  }}
                />
              </div>
            );
          case "InputNumber":
            return (
              <div>
                <Controller
                  control={control}
                  rules={validations}
                  name={key}
                  defaultValue={initialValues[key] || ""}
                  render={({ field: { onChange, onBlur, value, name } }) => {
                    return (
                      <InputNumber
                        {...primeFieldProps}
                        className={classNames({
                          "p-invalid": errors[key],
                        })}
                        name={name}
                        value={value}
                        readOnly={
                          properties.readOnly || selectOptions?.[key]?.readOnly
                        }
                        disabled={
                          selectOptions?.[key]?.disable ||
                          primeFieldProps.disabled ||
                          disables[key]
                        }
                        onChange={(e) => {
                          onChange(e?.target?.value);
                          if (
                            primeFieldProps &&
                            primeFieldProps.onChange &&
                            typeof primeFieldProps.onChange === "function"
                          )
                            primeFieldProps.onChange(
                              e?.target?.value,
                              setValue,
                              isSubmitted,
                              getValues,
                              errors,
                              resetField,
                              trigger,
                              setError
                            );
                        }}
                        onBlur={(e) => {
                          onBlur(e);
                          primeFieldProps?.onBlur?.(
                            e?.target?.value,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError
                          );
                        }}
                      />
                    );
                  }}
                />
              </div>
            );
          case "Password":
            return (
              <Controller
                control={control}
                rules={validations}
                name={key}
                defaultValue={initialValues[key] || ""}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <Password
                      {...primeFieldProps}
                      className={classNames({
                        "p-invalid": errors[key],
                      })}
                      name={name}
                      value={value}
                      readOnly={
                        properties.readOnly ||
                        disables[key] ||
                        selectOptions?.[key]?.readOnly
                      }
                      onChange={(value) => {
                        onChange(value);
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        )
                          primeFieldProps.onChange(
                            value,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError
                          );
                      }}
                    />
                  );
                }}
              />
            );

          case "InputTextarea":
            return (
              <Controller
                control={control}
                rules={validations}
                name={key}
                defaultValue={initialValues[key] || ""}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <InputTextarea
                      {...primeFieldProps}
                      className={classNames({
                        "p-invalid": errors[key],
                      })}
                      name={name}
                      value={value}
                      disabled={
                        selectOptions?.[key]?.disable ||
                        primeFieldProps.disabled ||
                        disables[key]
                      }
                      readOnly={
                        properties.readOnly || selectOptions?.[key]?.readOnly
                      }
                      onChange={(e) => {
                        onChange(e.target.value);
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        )
                          primeFieldProps.onChange(
                            e.target.value,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError
                          );
                      }}
                    />
                  );
                }}
              />
            );
          case "Dropdown":
            return (
              <Controller
                control={control}
                rules={validations}
                name={key}
                defaultValue={initialValues[key]}
                render={(props) => {
                  return (
                    <Dropdown
                      className={classNames({
                        "p-invalid": errors[key],
                      })}
                      {...primeFieldProps}
                      name={props.field.name}
                      value={props.field.value}
                      filter={primeFieldProps.filter === true ? true : false}
                      onChange={(e) => {
                        props.field.onChange(e.value);
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        ) {
                          primeFieldProps.onChange(e, setValue);
                        }
                      }}
                      optionLabel="label"
                      optionValue="value"
                      options={
                        !isEmpty(properties.dropdownOptions) &&
                        Array.isArray(dd[properties.dropdownOptions])
                          ? dd[properties.dropdownOptions]
                          : primeFieldProps.options
                      }
                      inputRef={props.field.ref}
                    />
                  );
                }}
              />
            );

          case "Select": {
            let dropDownoptionsList = [];
            if (
              !isEmpty(properties.dropdownOptions) &&
              Array.isArray(properties.dropdownOptions)
            )
              dropDownoptionsList = properties.dropdownOptions;
            else if (
              selectOptions &&
              selectOptions[key] &&
              selectOptions[key]?.options
            )
              dropDownoptionsList = selectOptions[key]?.options;

            return (
              <Controller
                control={control}
                rules={validations}
                name={key}
                defaultValue={initialValues[key] || null}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <Select
                      className={classNames({
                        "p-invalid": errors[key],
                      })}
                      noOptionsMessage={() => "No data found"}
                      {...primeFieldProps}
                      isSearchable={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      openMenuOnClick={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      isDisabled={
                        selectOptions?.[key]?.disable ||
                        primeFieldProps.isDisabled ||
                        disables[key]
                      }
                      name={name}
                      value={value}
                      isLoading={
                        watchTrack.current[key] === "loading" ? true : false
                      }
                      onChange={(value, action) => {
                        if (
                          properties?.dependent_value &&
                          properties?.dependent_value?.dependant_field
                        )
                          resetField(
                            properties?.dependent_value?.dependant_field,
                            { defaultValue: false }
                          );
                        onChange(value);
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        )
                          primeFieldProps.onChange(
                            value,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError,
                            action
                          );
                      }}
                      options={dropDownoptionsList ? dropDownoptionsList : []}
                      inputRef={ref}
                      styles={style}
                      isClearable={primeFieldProps.isClearable || false}
                    />
                  );
                }}
              />
            );
          }

          case "MultiSelect":
            return (
              <Controller
                control={control}
                rules={validations}
                name={key}
                defaultValue={initialValues[key] || []}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <Select
                      className={classNames({
                        "p-invalid": errors[key],
                      })}
                      closeMenuOnSelect={false}
                      noOptionsMessage={() => "No data found"}
                      {...primeFieldProps}
                      isDisabled={
                        selectOptions?.[key]?.disable ||
                        primeFieldProps.isDisabled ||
                        disables[key]
                      }
                      name={name}
                      value={value}
                      isMulti={true}
                      isSearchable={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      openMenuOnClick={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      onChange={(value, action) => {
                        onChange(value);
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        )
                          primeFieldProps.onChange(
                            value,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError,
                            action
                          );
                      }}
                      options={
                        !isEmpty(properties.dropdownOptions) &&
                        Array.isArray(properties.dropdownOptions)
                          ? properties.dropdownOptions
                          : selectOptions[key]?.options || []
                      }
                      inputRef={ref}
                      styles={style}
                    />
                  );
                }}
              />
            );

          case "AutoComplete":
            return (
              <Controller
                control={control}
                rules={validations}
                name={key}
                defaultValue={initialValues[key] || null}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <Async
                      className={classNames({
                        "p-invalid": errors[key],
                      })}
                      noOptionsMessage={(a) => {
                        const labelKey = lowerCase(properties?.label || "data");
                        if (a.inputValue?.trim().length > 0) {
                          return `No ${labelKey} found for the searched key.`;
                        } else {
                          return `Search for ${labelKey}`;
                        }
                      }}
                      {...primeFieldProps}
                      name={name}
                      value={value}
                      autoComplete="off"
                      isSearchable={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      openMenuOnClick={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      isDisabled={
                        selectOptions?.[key]?.disable ||
                        primeFieldProps.isDisabled ||
                        disables[key]
                      }
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      loadOptions={(inputValue, callback) => {
                        const apiPathUrl =
                          properties?.acUrl || properties.method;
                        if (inputValue?.trim().length > 0 && apiPathUrl) {
                          const filterValue = inputValue
                            ? inputValue.trim().toLowerCase()
                            : null;
                          setApiTrigger({
                            apiPathUrl,
                            filterValue,
                            callback,
                            labelField: primeFieldProps.optionLabel || "name",
                            valueField: primeFieldProps.optionValue || "id",
                          });
                        } else {
                          callback([]);
                        }
                      }}
                      onChange={(value) => {
                        onChange(value);
                        typeof onTemplateSelect === "function"
                          ? onTemplateSelect(value)
                          : "";
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        )
                          primeFieldProps.onChange(
                            value,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError
                          );
                      }}
                      inputRef={ref}
                      styles={style}
                    />
                  );
                }}
              />
            );
          case "CreatableAutoComplete":
            return (
              <Controller
                control={control}
                rules={validations}
                name={key}
                defaultValue={initialValues[key] || null}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <AsyncCreatableSelect
                      className={classNames({
                        "p-invalid": errors[key],
                      })}
                      noOptionsMessage={(a) => {
                        const labelKey = lowerCase(properties?.label || "data");
                        if (a.inputValue?.trim().length > 0) {
                          return `No ${labelKey} found for the searched key.`;
                        } else {
                          return `Search for ${labelKey}`;
                        }
                      }}
                      {...primeFieldProps}
                      name={name}
                      value={value}
                      autoComplete="off"
                      isSearchable={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      openMenuOnClick={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      isDisabled={
                        selectOptions?.[key]?.disable ||
                        primeFieldProps.isDisabled ||
                        disables[key]
                      }
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      loadOptions={(inputValue, callback) => {
                        const apiPathUrl =
                          properties?.acUrl || properties.method;
                        if (inputValue?.trim().length > 0 && apiPathUrl) {
                          const filterValue = inputValue
                            ? inputValue.trim().toLowerCase()
                            : null;
                          setApiTrigger({
                            apiPathUrl,
                            filterValue,
                            callback,
                            labelField: primeFieldProps.optionLabel || "name",
                            valueField: primeFieldProps.optionValue || "id",
                          });
                        } else {
                          callback([]);
                        }
                      }}
                      onChange={(value) => {
                        onChange(value);
                        typeof onTemplateSelect === "function"
                          ? onTemplateSelect(value)
                          : "";
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        )
                          primeFieldProps.onChange(
                            value,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError
                          );
                      }}
                      inputRef={ref}
                      styles={style}
                    />
                  );
                }}
              />
            );
          case "CityAutoComplete":
            return (
              <Controller
                control={control}
                rules={validations}
                name={key}
                defaultValue={initialValues[key] || null}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <Async
                      className={classNames({
                        "p-invalid": errors[key],
                      })}
                      noOptionsMessage={(value) =>
                        value.inputValue ? "No data found" : "City Search"
                      }
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      optionLabel="name"
                      optionValue="id"
                      formatOptionLabel={cityACTemplate}
                      {...primeFieldProps}
                      name={name}
                      value={value}
                      autoComplete="off"
                      isSearchable={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      openMenuOnClick={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      isDisabled={
                        selectOptions?.[key]?.disable ||
                        primeFieldProps.isDisabled ||
                        disables[key]
                      }
                      // loadOptions={getCitySuggestions}
                      loadOptions={(inputValue, callback) => {
                        getCitySuggestions(
                          inputValue,
                          callback,
                          getValues("country")
                        );
                      }}
                      onChange={(value) => {
                        onChange(value);
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        )
                          primeFieldProps.onChange(
                            value,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError
                          );
                      }}
                      inputRef={ref}
                      styles={style}
                    />
                  );
                }}
              />
            );
          case "AshramAutoComplete":
            return (
              <Controller
                control={control}
                rules={validations}
                name={key}
                defaultValue={initialValues[key] || null}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <Async
                      className={classNames({
                        "p-invalid": errors[key],
                      })}
                      noOptionsMessage={(value) =>
                        value.inputValue ? "No data found" : "Ashram Search"
                      }
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      optionLabel="name"
                      optionValue="id"
                      // formatOptionLabel={}
                      {...primeFieldProps}
                      name={name}
                      value={value}
                      autoComplete="off"
                      isSearchable={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      openMenuOnClick={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      isDisabled={
                        selectOptions?.[key]?.disable ||
                        primeFieldProps.isDisabled ||
                        disables[key]
                      }
                      // loadOptions={getCitySuggestions}
                      loadOptions={(inputValue, callback) => {
                        getAshramSuggestions(
                          inputValue,
                          callback
                          // getValues("country")
                        );
                      }}
                      onChange={(value) => {
                        onChange(value);
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        )
                          primeFieldProps.onChange(
                            value,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError
                          );
                      }}
                      inputRef={ref}
                      styles={style}
                    />
                  );
                }}
              />
            );
            case "ZoneAutoComplete":
              return (
                <Controller
                  control={control}
                  rules={validations}
                  name={key}
                  defaultValue={initialValues[key] || null}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <Async
                        className={classNames({
                          "p-invalid": errors[key],
                        })}
                        noOptionsMessage={(value) =>
                          value.inputValue ? "No data found" : "Zone Search"
                        }
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        optionLabel="name"
                        optionValue="id"
                        {...primeFieldProps}
                        name={name}
                        value={value}
                        autoComplete="off"
                        isSearchable={
                          properties.readOnly
                            ? !properties.readOnly
                            : !selectOptions?.[key]?.readOnly
                        }
                        openMenuOnClick={
                          properties.readOnly
                            ? !properties.readOnly
                            : !selectOptions?.[key]?.readOnly
                        }
                        isDisabled={
                          selectOptions?.[key]?.disable ||
                          primeFieldProps.isDisabled ||
                          disables[key]
                        }
                        loadOptions={(inputValue, callback) => {
                          getZoneSuggestions(
                            inputValue,
                            callback
                          );
                        }}
                        onChange={(value) => {
                          onChange(value);
                          if (
                            primeFieldProps &&
                            primeFieldProps.onChange &&
                            typeof primeFieldProps.onChange === "function"
                          )
                            primeFieldProps.onChange(
                              value,
                              setValue,
                              isSubmitted,
                              getValues,
                              errors,
                              resetField,
                              trigger,
                              setError
                            );
                        }}
                        inputRef={ref}
                        styles={style}
                      />
                    );
                  }}
                />
              );
          case "VillageAutoComplete":
            return (
              <Controller
                control={control}
                rules={validations}
                name={key}
                defaultValue={initialValues[key] || null}
                render={({ field: { onChange, value, name, ref } }) => {
                  let dependantField = properties?.dependent_value
                    ? properties?.dependent_value?.dependant_field
                    : "";
                  let searchAvailable = true;
                  let dependantValue = {};
                  const dependantMessage = "Select state to search village";
                  if (dependantField)
                    dependantValue = watch(dependantField) || null;
                  if (dependantField && !dependantValue?.value)
                    searchAvailable = false;

                  return (
                    <Async
                      className={classNames({
                        "p-invalid": errors[key],
                      })}
                      noOptionsMessage={(value) => {
                        if (!searchAvailable) return dependantMessage;
                        else
                          return value.inputValue
                            ? "No data found"
                            : "Village Search";
                      }}
                      loadingMessage={() => {
                        if (!searchAvailable) return dependantMessage;
                      }}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      optionLabel="name"
                      optionValue="id"
                      formatOptionLabel={villageACTemplate}
                      {...primeFieldProps}
                      name={name}
                      value={value}
                      autoComplete="off"
                      isSearchable={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      openMenuOnClick={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      isDisabled={
                        selectOptions?.[key]?.disable ||
                        primeFieldProps.isDisabled ||
                        disables[key]
                      }
                      loadOptions={(inputValue, callback) => {
                        return searchAvailable
                          ? getVillageSuggestions(
                              inputValue,
                              dependantValue?.value,
                              callback
                            )
                          : null;
                      }}
                      onChange={(value) => {
                        onChange(value);
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        )
                          primeFieldProps.onChange(
                            value,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError
                          );
                      }}
                      inputRef={ref}
                      styles={style}
                    />
                  );
                }}
              />
            );
          case "StateAutoComplete":
            return (
              <Controller
                control={control}
                rules={validations}
                name={key}
                defaultValue={initialValues[key] || null}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <Async
                      className={classNames({
                        "p-invalid": errors[key],
                      })}
                      noOptionsMessage={() => "No data found"}
                      optionLabel="name"
                      optionValue="id"
                      {...primeFieldProps}
                      name={name}
                      value={value}
                      isSearchable={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      openMenuOnClick={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      autoComplete="off"
                      isDisabled={
                        selectOptions?.[key]?.disable ||
                        primeFieldProps.isDisabled ||
                        disables[key]
                      }
                      loadOptions={getStateSuggestions}
                      onChange={(value) => {
                        onChange(value);
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        )
                          primeFieldProps.onChange(
                            value,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError
                          );
                      }}
                      inputRef={ref}
                      styles={style}
                    />
                  );
                }}
              />
            );

          case "CountryAutoComplete":
            return (
              <Controller
                control={control}
                rules={validations}
                name={key}
                defaultValue={initialValues[key] || null}
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <Async
                      className={classNames({
                        "p-invalid": errors[key],
                      })}
                      noOptionsMessage={() => "No data found"}
                      optionLabel="name"
                      optionValue="id"
                      {...primeFieldProps}
                      name={name}
                      value={value}
                      autoComplete="off"
                      isDisabled={
                        selectOptions?.[key]?.disable ||
                        primeFieldProps.isDisabled ||
                        disables[key]
                      }
                      isSearchable={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      openMenuOnClick={
                        properties.readOnly
                          ? !properties.readOnly
                          : !selectOptions?.[key]?.readOnly
                      }
                      loadOptions={getCountrySuggestions}
                      onChange={(value) => {
                        onChange(value);
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        )
                          primeFieldProps.onChange(
                            value,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError
                          );
                      }}
                      inputRef={ref}
                      styles={style}
                    />
                  );
                }}
              />
            );

          case "Checkbox":
            const optionsList = properties.toggleOptions?.options;
            const Okey = properties.toggleOptions?.key;
            const initOkey = initialValues[Okey];
            let value = null;
            if (Okey) {
              value = getValues(Okey);
            }
            let dv = false;
            if (
              !watchTrack.current?.[key]?.val &&
              initOkey &&
              initOkey === value &&
              !createForm
            ) {
              noop;
            } else if (
              value &&
              (watchTrack.current?.[key]?.val || value !== dv) &&
              optionsList &&
              Array.isArray(optionsList) &&
              optionsList.length > 0
            ) {
              if (value === watchTrack.current?.[key]?.dependentValue) {
                dv = getValues(key) || false;
              }
              let isChecked =
                optionsList?.includes(
                  value?.value || value || initOkey?.value || initOkey
                ) || dv;
              setValue(key, typeof isChecked === "boolean" ? isChecked : false);
              if (value !== watchTrack.current?.[key]?.dependentValue) {
                watchTrack.current = {
                  ...(watchTrack.current || {}),
                  [key]: {
                    ...(watchTrack.current[key] || {}),
                    dependentValue: value,
                  },
                };
              }
            }
            return (
              <div>
                <Controller
                  name={key}
                  control={control}
                  rules={validations}
                  defaultValue={initialValues[key] || false}
                  render={(props) => (
                    <Checkbox
                      className={classNames({
                        "p-invalid": errors[key],
                      })}
                      {...primeFieldProps}
                      inputId={key}
                      disabled={
                        selectOptions?.[key]?.disable ||
                        primeFieldProps.disabled ||
                        disables[key]
                      }
                      readOnly={
                        properties.readOnly || selectOptions?.[key]?.readOnly
                      }
                      onChange={({ checked }) => {
                        let isChecked = checked;
                        if (
                          initialValues[key] !== null &&
                          !watchTrack.current?.[key]
                        ) {
                          watchTrack.current = {
                            ...(watchTrack.current || {}),
                            [key]: {
                              ...(watchTrack.current[key] || {}),
                              val: initialValues[key],
                            },
                          };
                        } else if (
                          watchTrack.current?.[key] &&
                          optionsList &&
                          Array.isArray(optionsList) &&
                          optionsList.length > 0
                        ) {
                          isChecked =
                            optionsList?.includes(
                              value?.value || value || initialValues[Okey]
                            ) || checked;
                        }

                        props.field.onChange(isChecked);
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        )
                          primeFieldProps.onChange(
                            isChecked,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError
                          );
                      }}
                      checked={props.field.value}
                    />
                  )}
                />

                <label
                  htmlFor={key}
                  className={`p-pl-2 p-checkbox-label ${
                    properties.fieldLabelClassNames
                      ? properties.fieldLabelClassNames
                      : ""
                  }`}
                  style={{ verticalAlign: "middle" }}
                >
                  {validations &&
                  validations.required &&
                  validations.required.value ? (
                    <em>*&nbsp;</em>
                  ) : (
                    <></>
                  )}
                  {properties.label}
                  {properties.tooltip ? (
                    <span className="tooltip-icon" title={properties.tooltip}>
                      {" "}
                      <i className="uil uil-info-circle"></i>{" "}
                    </span>
                  ) : (
                    <></>
                  )}
                </label>
              </div>
            );

          case "multifield":
            const sectionFieldsX = fields?.[key]?.properties?.fields || [];
            const subFieldKeys = sectionFieldsX.map((fd) => Object.keys(fd)[0]);
            let fieldSection = fields?.[key]?.properties?.section || "";

            const otherData = fields?.[key]?.properties?.others || [];
            let extraData = [];
            otherData.length > 0
              ? otherData.map((data) => {
                  data.extra ? extraData.push(data.extra) : "";
                })
              : "";

            let abhyasiSearch = extraData[0]?.abhyasiSearch || false;
            let isMultiCoordinator = extraData[0]?.isMultiCoordinator || false;
            let checkBoxFields = extraData[0]?.checkBoxFields || false;

            const displayFields =
              mfRef.current.indexOf(key) < 0
                ? [...(initialValues[key] || []), ...(watch(key) || [])]
                : watch(key);

            const editPos = getValues("editPos");

            if (mfRef.current.indexOf(key) < 0 && initialValues[key]) {
              setValue(key, initialValues[key]);
              mfRef.current.push(key);
            }

            return (
              <div>
                {abhyasiSearch && (
                  <AbhyasiSearch
                    inputKey={key}
                    identifier={fieldSection}
                    cb={eventCoCB}
                    fieldSet={subFieldKeys}
                    isChkBoxAvail={checkBoxFields}
                  />
                )}
                {isMultiCoordinator && (
                  <CoordinatorCard
                    data={displayFields}
                    onRemove={(a, b) => deleteEntry(a, key, b)}
                    mode={mode}
                    onEdit={(a, b) => editEntry(a, b)}
                    isSession={checkBoxFields}
                  />
                )}
                {!isMultiCoordinator && (
                  <CarouselCardMultiField
                    data={displayFields}
                    onRemove={(a, b) => deleteEntry(a, key, b)}
                    mode={mode}
                    onEdit={(a, b) => editEntry(a, b)}
                    isPopupModal={popupModal}
                  />
                )}
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {sectionFieldsX.map((subFields) => {
                    if (
                      subFields &&
                      typeof subFields === "object" &&
                      Object.keys(subFields)?.length > 0
                    ) {
                      let subFieldValidate = "";
                      const keyX = Object.keys(subFields)[0];
                      const subFieldProp =
                        Object.values(subFields)[0]?.properties;

                      const pfp = subFieldProp?.primeFieldProps || {};

                      if (subFieldProp.validations) {
                        if (
                          typeof subFieldProp.validations.validate ===
                          "function"
                        ) {
                          const validate = (value) =>
                            subFieldProp.validations.validate(value, getValues);
                          subFieldValidate = {
                            ...subFieldProp.validations,
                            validate,
                          };
                        } else subFieldValidate = subFieldProp.validations;
                      } else subFieldValidate = {};
                      return (
                        <div
                          key={keyX}
                          className={subFieldProp?.fieldWrapperClassNames || ""}
                        >
                          <label
                            className={`p-field-label ${
                              subFieldProp.fieldLabelClassNames
                                ? subFieldProp.fieldLabelClassNames
                                : ""
                            }`}
                          >
                            {subFieldProp.type !== "Checkbox"
                              ? subFieldProp.label
                              : ""}
                            {subFieldValidate &&
                            subFieldValidate.required &&
                            subFieldValidate.required.value ? (
                              <em>*&nbsp;</em>
                            ) : (
                              <></>
                            )}
                            {subFieldProp.tooltip ? (
                              <span
                                className="tooltip-icon"
                                title={subFieldProp.tooltip}
                              >
                                {" "}
                                <i className="uil uil-info-circle"></i>{" "}
                              </span>
                            ) : (
                              <></>
                            )}
                          </label>
                          {renderFieldsTypes(subFieldProp, {}, keyX, pfp)}
                          <div
                            className={`p-error-section ${
                              subFieldProp.fieldErrorClassNames
                                ? subFieldProp.fieldErrorClassNames
                                : ""
                            }`}
                          >
                            {errors[keyX] && (
                              <span role="alert" className="error">
                                {" "}
                                {errors[keyX].message}{" "}
                              </span>
                            )}
                          </div>

                          {subFieldProp.hint ? (
                            <div
                              className={`p-hint-section ${
                                subFieldProp.fieldHintClassNames
                                  ? subFieldProp.fieldHintClassNames
                                  : ""
                              }`}
                            >
                              {subFieldProp.hint}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    }
                  })}
                  {checkBoxFields && (
                    <UserCheckboxes
                      callBackFun={(res) => (HfnCo.current = res)}
                      isReset={resetCheckBox.current}
                      loc="multiform"
                    />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    type="button"
                    className="p-button p-button-secondary p-mr-2"
                    label={editPos > -1 ? "Save" : "Add"}
                    onClick={() =>
                      addMultifield(
                        key,
                        subFieldKeys,
                        sectionFieldsX,
                        checkBoxFields,
                        isMultiCoordinator
                      )
                    }
                  />
                </div>
              </div>
            );

          case "RadioButton":
            if (properties.items) {
              return properties.items.map((item) => {
                return (
                  <div key={item.key} className="p-field-radiobutton">
                    <Controller
                      className={classNames({
                        "p-invalid": errors[key],
                      })}
                      name={key}
                      control={control}
                      rules={validations}
                      defaultValue={initialValues[key]}
                      render={(props) => {
                        return (
                          <input
                            type="radio"
                            id={item.id ? item.id : item.key}
                            value={item.key}
                            name={key}
                            {...primeFieldProps}
                            readOnly={
                              properties.readOnly ||
                              primeFieldProps.readOnly ||
                              disables[key] ||
                              selectOptions?.[key]?.readOnly
                            }
                            onClick={(e) => {
                              props.field.onChange(e.target.value);
                              if (
                                primeFieldProps &&
                                primeFieldProps.onChange &&
                                typeof primeFieldProps.onChange === "function"
                              )
                                primeFieldProps.onChange(
                                  e.target.value,
                                  setValue,
                                  isSubmitted,
                                  getValues,
                                  errors,
                                  resetField,
                                  trigger,
                                  setError
                                );
                            }}
                          />
                        );
                      }}
                    />
                    <label htmlFor={item.id ? item.id : item.key}>
                      {item.name}
                    </label>
                  </div>
                );
              });
            }
            break;

          case "InputSwitch":
            return (
              <div>
                <Controller
                  name={key}
                  control={control}
                  rules={validations}
                  defaultValue={initialValues[key] || false}
                  render={(props) => (
                    <InputSwitch
                      {...primeFieldProps}
                      inputId={key}
                      disabled={
                        selectOptions?.[key]?.disable ||
                        primeFieldProps.disabled ||
                        disables[key]
                      }
                      onChange={({ value }) => {
                        props.field.onChange(value);
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        ) {
                          primeFieldProps.onChange(
                            value,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError
                          );
                        }
                      }}
                      checked={props.field.value}
                    />
                  )}
                />
              </div>
            );

          case "FileUpload":
            return (
              <InputText
                className={classNames({
                  "p-invalid": errors[key],
                })}
                type="file"
                {...primeFieldProps}
                name={key}
                readOnly={properties.readOnly || selectOptions?.[key]?.readOnly}
                {...register(key, validations)}
              />
            );

          case "ImageUpload":
            //  you can use the uploaded image by accessing the key and cropped image by suffix _cropped with the key
            return (
              <ImageUpload
                keyVal={key}
                setData={setValue}
                register={register}
                validations={validations}
                error={errors?.[key]}
              />
            );

          case "Slider":
            return (
              <Controller
                control={control}
                rules={validations}
                name={key}
                defaultValue={initialValues[key] || null}
                render={(props) => {
                  return (
                    <Slider
                      className={classNames({
                        "p-invalid": errors[key],
                      })}
                      {...primeFieldProps}
                      name={props.field.name}
                      value={props.field.value}
                      disabled={
                        selectOptions?.[key]?.disable ||
                        primeFieldProps.disabled ||
                        disables[key]
                      }
                      onSlideEnd={({ value }) => {
                        props.field.onChange(value);
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        )
                          primeFieldProps.onChange(
                            value,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError
                          );
                      }}
                      inputRef={props.field.ref}
                    />
                  );
                }}
              />
            );

          case "Calendar":
            return (
              <Controller
                control={control}
                rules={validations}
                name={key}
                defaultValue={initialValues[key] || null}
                render={(props) => {
                  primeFieldProps = {
                    ...primeFieldProps,
                    ...{
                      minDate: selectOptions[key]?.minDate
                        ? selectOptions[key]?.minDate
                        : properties?.minDate || "",
                      maxDate: selectOptions[key]?.maxDate
                        ? selectOptions[key]?.maxDate
                        : properties?.maxDate || "",
                    },
                  };
                  return (
                    <Calendar
                      className={classNames({
                        "p-invalid": errors[key],
                      })}
                      {...primeFieldProps}
                      name={props.field.name}
                      value={props.field.value}
                      disabled={
                        selectOptions?.[key]?.disable ||
                        primeFieldProps.disabled ||
                        disables[key]
                      }
                      readOnlyInput={
                        properties.readOnly || selectOptions?.[key]?.readOnly
                      }
                      onChange={({ value }) => {
                        props.field.onChange(value);
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        )
                          primeFieldProps.onChange(
                            value,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError
                          );
                      }}
                      inputRef={props.field.ref}
                    />
                  );
                }}
              />
            );
          case "CalendarWithTime":
            const initalVal = initialValues?.[key];

            let actualTime = DEFAULT_TIME;
            if (initalVal) {
              const hrs =
                new Date(initalVal).getHours() > 0
                  ? new Date(initalVal).getHours()
                  : 0;
              const min =
                new Date(initalVal).getMinutes() > 0
                  ? new Date(initalVal).getMinutes()
                  : 0;
              actualTime = `${
                hrs < 1 ? "00" : hrs.toString().padStart(2, "0")
              }:${min < 1 ? "00" : min.toString().padStart(2, "0")}`;
            }

            return (
              <div style={{ display: "grid", gridTemplateColumns: "2fr 1fr" }}>
                <Controller
                  control={control}
                  rules={validations}
                  name={key}
                  defaultValue={initialValues[key] || null}
                  render={(props) => {
                    primeFieldProps = {
                      ...primeFieldProps,
                      ...{
                        minDate: selectOptions[key]?.minDate
                          ? selectOptions[key]?.minDate
                          : properties?.minDate || "",
                        maxDate: selectOptions[key]?.maxDate
                          ? selectOptions[key]?.maxDate
                          : properties?.maxDate || "",
                      },
                    };
                    return (
                      <Calendar
                        className={classNames({
                          "p-invalid": errors[key],
                        })}
                        {...primeFieldProps}
                        name={props.field.name}
                        value={props.field.value}
                        disabled={
                          selectOptions?.[key]?.disable ||
                          primeFieldProps.disabled ||
                          disables[key]
                        }
                        readOnlyInput={
                          properties.readOnly || selectOptions?.[key]?.readOnly
                        }
                        onChange={({ value }) => {
                          props.field.onChange(value);
                          if (
                            primeFieldProps &&
                            primeFieldProps.onChange &&
                            typeof primeFieldProps.onChange === "function"
                          )
                            primeFieldProps.onChange(
                              value,
                              setValue,
                              isSubmitted,
                              getValues,
                              errors,
                              resetField,
                              trigger,
                              setError
                            );
                        }}
                        inputRef={props.field.ref}
                      />
                    );
                  }}
                />

                <InputTimeField
                  keyVal={key}
                  setError={setError}
                  error={errors?.[key]}
                  initalVal={actualTime}
                  getValue={getValues(key)}
                  setValue={setValue}
                  clearError={clearErrors}
                  idDisabled={
                    selectOptions?.[key]?.disable ||
                    primeFieldProps.disabled ||
                    disables[key]
                  }
                />
              </div>
            );

          case "Time":
            let defaultTime = "00:00:00";
            if (initialValues?.[key]) defaultTime = initialValues?.[key];
            return (
              <InputTimeField
                keyVal={key}
                setError={setError}
                error={errors?.[key]}
                initalVal={defaultTime}
                getValue={getValues(key)}
                setValue={setValue}
                clearError={clearErrors}
                idDisabled={
                  selectOptions?.[key]?.disable ||
                  primeFieldProps.disabled ||
                  disables[key]
                }
                isTimeOnly
              />
            );
          case "Chips":
            return (
              <div>
                <Controller
                  name={key}
                  control={control}
                  rules={validations}
                  defaultValue={initialValues[key]}
                  render={(props) => (
                    <Chips
                      className={classNames({
                        "p-invalid": errors[key],
                      })}
                      disabled={
                        selectOptions?.[key]?.disable ||
                        primeFieldProps.disabled ||
                        disables[key]
                      }
                      readOnly={
                        properties.readOnly || selectOptions?.[key]?.readOnly
                      }
                      onChange={({ value }) => {
                        props.field.onChange(value);
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        )
                          primeFieldProps.onChange(
                            value,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError
                          );
                      }}
                    />
                  )}
                />
                <label htmlFor={key} className="p-checkbox-label">
                  {properties.label}
                </label>
              </div>
            );

          case "PhoneInput":
            return (
              <Controller
                control={control}
                rules={validations}
                name={key}
                defaultValue={initialValues[key] || ""}
                render={(props) => {
                  return (
                    <PhoneInput
                      className={classNames({
                        "p-invalid": errors[key],
                        "p-disabled":
                          selectOptions?.[key]?.disable ||
                          primeFieldProps.disabled ||
                          disables[key],
                      })}
                      country={"in"}
                      placeholder={"Enter Phone no with country code"}
                      value={props.field.value}
                      name={props.field.name}
                      {...primeFieldProps}
                      disabled={
                        selectOptions?.[key]?.disable ||
                        primeFieldProps.disabled ||
                        disables[key]
                      }
                      onChange={(value, country, e, formattedValue) => {
                        props.field.onChange(formattedValue);
                        if (
                          primeFieldProps &&
                          primeFieldProps.onChange &&
                          typeof primeFieldProps.onChange === "function"
                        )
                          primeFieldProps.onChange(
                            formattedValue,
                            setValue,
                            isSubmitted,
                            getValues,
                            errors,
                            resetField,
                            trigger,
                            setError,
                            country,
                            e,
                            value
                          );
                      }}
                      inputRef={props.field.ref}
                    />
                  );
                }}
              />
            );

          case "sectionDivider":
            return <Divider />;

          case "blankField":
            return <div></div>;

          default:
            return <>Form field not available</>;
        }
      },
      [
        control,
        errors,
        fields,
        setValue,
        isSubmitted,
        getValues,
        errors,
        resetField,
        trigger,
        setError,
        mode,
        selectOptions,
      ]
    );

    const onFormError = useCallback((a, e) => {
      if (watchTrack.current?.disabledList) {
        const abc = Object.entries(watchTrack.current?.disabledList)
          .filter(([, v]) => v)
          .map(([k]) => k);
        if (abc && Array.isArray(abc) && abc.length) {
          clearErrors(abc);
        }
        const errVal = omit(a, abc);
        if (errVal && Object.keys(errVal).length === 0) {
          const cv = omit(getValues(), abc);
          onFormSubmit(cv, setError, e, visibles, reset, setValue);
        }
      }
    }, []);

    return (
      <form
        onSubmit={handleSubmit(onSubmitForm, onFormError)}
        className={`form-wrapper ${formConfig ? formConfig.formClassName : ""}`}
        autoComplete={`${formConfig ? formConfig.autoComplete : "on"}`}
      >
        {isLoading === true ? <HFNLoader /> : null}
        <div
          className={`p-fluid form-section p-d-flex p-flex-wrap ${
            formConfig ? formConfig.formSectionClassName : ""
          }`}
        >
          {templates.map((template, index) => {
            let sectionFields = {};

            template.sectionFields &&
              template.sectionFields.forEach((field) => {
                sectionFields = { ...sectionFields, ...field };
              });

            return (
              <React.Fragment key={`${template.section}-${index}`}>
                <Tooltip target=".customClassName" />
                {template.section ? (
                  <div className="p-md-12 p-py-0 p-mt-1 p-mt-md-0 p-mb-1 p-lg-12 p-mb-3 page-title">
                    {" "}
                    <h4>{template.section}</h4>{" "}
                  </div>
                ) : (
                  ""
                )}

                {template.sectionDesc ? (
                  <div className="p-md-12 p-py-0 p-mt-1 p-mt-md-0 p-mb-1 p-lg-12 p-mb-3">
                    {" "}
                    <p>{template.sectionDesc}</p>{" "}
                  </div>
                ) : (
                  ""
                )}

                {index === 0 && template.section ? <Divider /> : ""}

                {Object.keys(sectionFields).map((key, index) => {
                  const { properties } = sectionFields[key];

                  let primeFieldProps = {},
                    validations,
                    hide = false,
                    readOnly = false,
                    disabled = false,
                    dependentValue;

                  if (properties.primeFieldProps)
                    primeFieldProps = properties.primeFieldProps;
                  if (properties.validations) {
                    if (typeof properties.validations.validate === "function") {
                      const validate = (value) => {
                        const validationProps = properties?.validations;
                        const isRequired =
                          validationProps && validationProps?.required
                            ? validationProps?.required?.value
                            : false;
                        return properties.validations.validate(
                          value,
                          isRequired
                        );
                      };
                      validations = { ...properties.validations, validate };
                    } else validations = properties.validations;
                  } else validations = {};

                  if (properties.option_api && properties.option_keys) {
                    if (!watchTrack.current[key]) {
                      watchTrack.current[key] = "loading";
                      getDropDown(
                        properties.option_api,
                        properties.option_keys,
                        key,
                        true
                      );
                    }
                  }

                  dependentValue = properties?.dependent_value;

                  if (
                    dependentValue &&
                    typeof dependentValue === "object" &&
                    !Array.isArray(dependentValue)
                  ) {
                    if (dependentValue?.hide) {
                      hide = validatedependency(dependentValue?.hide, key);
                    }

                    if (dependentValue?.disable) {
                      disabled = validatedependency(
                        dependentValue?.disable,
                        key
                      );
                    }
                    if (dependentValue?.readOnly) {
                      readOnly = validatedependency(
                        dependentValue?.readOnly,
                        key
                      );
                    }

                    if (dependentValue?.options) {
                      const optionsVal = dependentValue.options;

                      if (Array.isArray(optionsVal) && optionsVal?.length) {
                        const depKeys = optionsVal.map(
                          (depItem) =>
                            `${properties.section}-${depItem?.identifier}`
                        );
                        const depWatch = watch(depKeys);

                        depKeys.map(async (depItem, idx) => {
                          if (depWatch[idx]) {
                            const condVal = depWatch[idx];
                            const optionValArr = optionsVal.filter((itemD) => {
                              return (
                                depItem ===
                                `${properties.section}-${itemD?.identifier}`
                              );
                            });

                            let {
                              option_api,
                              option_keys,
                              search_key,
                              options,
                            } = optionValArr[0];

                            if (option_api && condVal) {
                              let optionUrl = option_api + condVal.value;

                              if (
                                properties?.type === "AutoComplete" ||
                                properties?.type === "CreatableAutoComplete"
                              ) {
                                let searchParam = search_key
                                  ? search_key
                                  : "&search=";
                                properties.acUrl = optionUrl + searchParam;
                              }
                              if (
                                watchTrack.current[key] !== condVal.value &&
                                watchTrack.current[key] !== "loading"
                              ) {
                                if (
                                  initialValues[key] &&
                                  !watchTrack.current[key]
                                ) {
                                  setValue(key, initialValues[key]);
                                } else {
                                  resetField(key, { defaultValue: false });
                                }
                                if (properties?.type === "AutoComplete") {
                                  watchTrack.current[key] = condVal.value;
                                } else if (optionUrl && option_keys) {
                                  watchTrack.current[key] = "loading";
                                  getDropDown(
                                    optionUrl,
                                    option_keys,
                                    key,
                                    false,
                                    condVal?.value
                                  );
                                }
                              }
                            } else if (
                              options &&
                              options.includes(condVal?.value || condVal)
                            ) {
                              properties.toggleOptions = {
                                options,
                                key: depItem,
                              };
                            }
                          }
                        });
                      }
                    }
                    if (dependentValue?.minDate) {
                      const minDate = dependentValue?.minDate;
                      dateFunction(
                        minDate,
                        properties,
                        key,
                        watch,
                        setValue,
                        watchTrack,
                        getValues,
                        setSelectOptions,
                        true
                      );
                    }
                    if (dependentValue?.maxDate) {
                      const maxDate = dependentValue?.maxDate;
                      dateFunction(
                        maxDate,
                        properties,
                        key,
                        watch,
                        setValue,
                        watchTrack,
                        getValues,
                        setSelectOptions
                      );
                    }
                  }

                  if (hide) {
                    return "";
                  }

                  if (watchTrack.current?.readOnly?.[key] !== readOnly) {
                    watchTrack.current.readOnly = {
                      ...(watchTrack.current?.readOnly || {}),
                      [key]: readOnly,
                    };
                    validations = {};
                    setSelectOptions((prev) => ({
                      ...prev,
                      [key]: {
                        ...(prev?.[key] || {}),
                        readOnly: readOnly,
                      },
                    }));
                  }
                  if (watchTrack.current?.readOnly?.[key]) {
                    validations = {};
                  }
                  if (watchTrack.current?.disabledList?.[key] !== disabled) {
                    watchTrack.current.disabledList = {
                      ...(watchTrack.current?.disabledList || {}),
                      [key]: disabled,
                    };
                    validations = {};
                    setSelectOptions((prev) => ({
                      ...prev,
                      [key]: {
                        ...(prev?.[key] || {}),
                        disable: disabled,
                      },
                    }));
                  }
                  if (watchTrack.current?.disabledList?.[key]) {
                    validations = {};
                  }

                  return properties.visibility === false ||
                    visibles[key] === false ? (
                    <React.Fragment key={key}></React.Fragment>
                  ) : (
                    <React.Fragment key={key}>
                      <div
                        className={`p-field-wrapper p-col-12 ${
                          properties.fieldWrapperClassNames
                            ? properties.fieldWrapperClassNames
                            : ""
                        }`}
                        key={key + index}
                      >
                        {["Checkbox"].indexOf(properties.type) === -1 ? (
                          <label
                            htmlFor="lastname1"
                            className={`p-field-label ${key} ${
                              properties.fieldLabelClassNames
                                ? properties.fieldLabelClassNames
                                : ""
                            }`}
                          >
                            {properties.label}
                            {validations &&
                            validations.required &&
                            validations.required.value ? (
                              <em>*&nbsp;</em>
                            ) : (
                              <></>
                            )}
                            {properties.help_text ? (
                              <span
                                className="customClassName tooltip-icon"
                                data-pr-tooltip={properties.help_text}
                                data-pr-position="top"
                              >
                                {" "}
                                <i className="uil uil-info-circle"></i>{" "}
                              </span>
                            ) : (
                              <></>
                            )}
                          </label>
                        ) : null}

                        <div
                          className={`p-field-section ${
                            properties.fieldSectionClassNames
                              ? properties.fieldSectionClassNames
                              : ""
                          }`}
                        >
                          {renderFieldsTypes(
                            properties,
                            validations,
                            key,
                            primeFieldProps
                          )}
                        </div>

                        <div
                          className={`p-error-section ${
                            properties.fieldErrorClassNames
                              ? properties.fieldErrorClassNames
                              : ""
                          }`}
                        >
                          {errors[key] && (
                            <span role="alert" className="error">
                              {" "}
                              {errors[key].message}{" "}
                            </span>
                          )}
                        </div>

                        {properties.hint ? (
                          <div
                            className={`p-hint-section ${
                              properties.fieldHintClassNames
                                ? properties.fieldHintClassNames
                                : ""
                            }`}
                          >
                            {properties.hint}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </React.Fragment>
                  );
                })}

                {index < templates.length - 1 ? <Divider /> : ""}
              </React.Fragment>
            );
          })}
        </div>

        {submitButtonGroup && typeof submitButtonGroup === "function"
          ? submitButtonGroup(handleSubmit)
          : closeModel}
      </form>
    );
  }
);

HFNDynamicForm.displayName = "HFNForm";

export default memo(HFNDynamicForm);
